import React, { PropsWithChildren } from 'react';
import { Dropdown } from 'react-bootstrap';
import ActionToggle from './ActionToggle';

type ActionsMenuProps = PropsWithChildren<{
  title: string;
}>;

function ActionsMenu({ children, title }: ActionsMenuProps) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={ActionToggle} title={title} />
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
}

export default ActionsMenu;
