import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import Copyright from '../components/Copyright/Copyright';

type FooterRouteType = {
  name: string;
  path: string;
};

const footerRoutes: FooterRouteType[] = [
  {
    path: 'https://www.adikteev.com/legal-mentions',
    name: 'Legal Mentions',
  },
  {
    path: 'https://www.adikteev.com/your-personal-data',
    name: 'Your Personal Data',
  },
  {
    path: 'https://assets.website-files.com/5e6601d0415f1e50e04d660c/5f310b5bdcebe9d2ace7dcfe_ADIKTEEV%20GENERAL%20TERMS%20AND%20CONDITIONS%20-%20August%202020.pdf',
    name: 'Terms and Conditions',
  },
];

function Footer() {
  return (
    <footer className="text-dark">
      <Row className="align-items-center justify-content-xl-between p-4 footer">
        <Col xl="6">
          <Copyright />
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            {footerRoutes.map((route) => {
              return (
                <Nav.Item key={route.name}>
                  <Nav.Link
                    href={route.path}
                    rel="noopener noreferrer"
                    target="_blank"
                    bsPrefix="nav-link text-dark fs-5"
                  >
                    {route.name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
