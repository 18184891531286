import React, { useMemo } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import axios, { AxiosError } from 'axios';
import { useAccountById } from './accounts';
import accountUtils from '../../utils/account';
import type {
  APICreateTestingDeviceData,
  APIEditTestingDeviceData,
  APIKeySourceAppResponse,
  APIPromotedAppResponse,
  APISourceAppResponse,
  APISourceAppSelection,
  APITestingDevice,
  DataStreamSourceApp,
  DisplayPromotedApp,
  DisplaySourceApp,
  FormattedXDKApp,
  InputPromotedApp,
  InputSourceApp,
  InputSourceAppSettings,
  InputSourceAppsSelection,
  InputSourceAppWithName,
} from '../../models/crosspromotion';
import { TrackingProviders } from '../../models/crosspromotion';
import type { CreateQuery, ReadAllQuery, UpdateQuery } from '../queryhooks';
import Notif from '../../utils/notification';
import { APIResponseError, OS } from '../../utils/types';
import { combineQueriesStates } from '../../utils/react-query';
import handleAPIErrors from '../../utils/handleAPIErrors';
import type { APICollection, APIError } from '../../models/api';
import getItem from '../../utils/getItem';
import { getAllAppsAndroid } from '../../api/app-from-store/appAndroid.getAll';
import { getAllAppsIOS } from '../../api/app-from-store/appIOS.getAll';
import { getAllPromotedApps } from '../../api/promoted-app/promotedApp.getAll';
import { getAllSourceApps } from '../../api/source-app/sourceApp.getAll';
import { getAllPromotedBy } from '../../api/promoted-by/promotedBy.getAll';
import { editPromotedApp } from '../../api/promoted-app/promotedApp.edit';
import { editXpromoSettings } from '../../api/xpromo-settings/xpromoSettings.edit';
import { getAllDataStreamStatus } from '../../api/data-stream/dataStreamStatus.getAll';
import { getAllTestingDevices } from '../../api/testing-device/testingDevice.getAll';
import { createTestingDevice } from '../../api/testing-device/testingDevice.create';
import { editTestingDevice } from '../../api/testing-device/testingDevice.edit';
import { deleteTestingDevice } from '../../api/testing-device/testingDevice.delete';
import { editPromotedBy } from '../../api/promoted-by/promotedBy.edit';
import { createSourceApp } from '../../api/source-app/sourceApp.create';
import { createAppInit } from '../../api/app-init/appInit.create';

/**
 * useStoresApps: Return a list of Apps from both Android and iOS Stores
 * This query is only used internally by other queries
 *
 * @param accountId
 * @returns A query object
 * data is an array of AppStoreQuery
 */
export const useStoresApps = (accountId: string) => {
  // Fetch Account Data
  const accountQuery = useAccountById({
    accountId,
  });
  const developerName = accountUtils.getDevelopersName(
    accountQuery.data?.developers
  );

  // Fetch Android Apps
  const androidAppsQuery = useQuery(
    [accountId, 'androidApps', developerName.android],
    () => getAllAppsAndroid(developerName.android || ''),
    { enabled: accountQuery.isSuccess }
  );
  // Fetch Ios Apps
  const iosAppsQuery = useQuery(
    [accountId, 'iosApps', developerName.ios],
    () => getAllAppsIOS(developerName.ios || ''),
    { enabled: accountQuery.isSuccess }
  );

  // List of apps (ios + android) from App Store
  const storeApps = React.useMemo(() => {
    if (androidAppsQuery.data && iosAppsQuery.data) {
      return [...androidAppsQuery.data, ...iosAppsQuery.data];
    }
    return null;
  }, [androidAppsQuery.data, iosAppsQuery.data]);

  return {
    data: storeApps,
    ...combineQueriesStates([accountQuery, androidAppsQuery, iosAppsQuery]),
  };
};

/**
 * usePromotedApps: Return a list of promoted application,
 * data object is a constructed object of apps from the stores,
 * datastream status for each app and the promotion status.
 *
 * @param accountId
 * @returns a query object gathering data and its status.
 * data is an array of DisplayPromotedApp
 */
export const usePromotedApps = (accountId: string) => {
  const { data: storeApps, ...storeAppsQuery } = useStoresApps(accountId);
  const dataStreamAppsQuery = useQuery(
    [accountId, 'dataStreamApps'],
    () => {
      const input: DataStreamSourceApp[] = storeApps!.map(
        ({ bundleId, os }) => ({
          bundle_id: bundleId,
          os: os === OS.IOS ? 'ios' : 'android',
        })
      );
      return getAllDataStreamStatus({ data_stream_activation_apps: input });
    },
    { enabled: !!storeApps }
  );

  const promotedAppsQuery = useQuery(
    [accountId, 'promotedApps'],
    () => getAllPromotedApps(accountId),
    { enabled: !!storeApps }
  );

  const promotedApps = React.useMemo<DisplayPromotedApp[]>(() => {
    if (
      !storeApps ||
      !dataStreamAppsQuery.isSuccess ||
      !promotedAppsQuery.isSuccess
    ) {
      return [];
    }
    return storeApps.map((app) => {
      const dataStream =
        dataStreamAppsQuery.data?.find(
          ({ bundle_id, os }) => app.bundleId === bundle_id && app.os === os
        )?.activation ?? false;

      const promotedApp = promotedAppsQuery.data?.find(
        ({ bundle_id, os }) => app.bundleId === bundle_id && app.os === os
      );

      return {
        ...app,
        appId: promotedApp?.id ?? '',
        dataStream,
        promotionStatus: promotedApp?.promotion_status ?? false,
        impressionTrackingLink:
          promotedApp?.impression_template_tracking_link ?? null,
        clickTrackingLink: promotedApp?.click_template_tracking_link ?? null,
        trackingProvider:
          promotedApp?.tracking_provider ?? TrackingProviders.other,
        trackingProviderToken: promotedApp?.tracking_provider_token ?? null,
        nativeBannerActivated: promotedApp?.native_banner_activated ?? true,
        titleId: promotedApp?.title_id ?? '',
      };
    });
  }, [
    storeApps,
    dataStreamAppsQuery.isSuccess,
    dataStreamAppsQuery.data,
    promotedAppsQuery.isSuccess,
    promotedAppsQuery.data,
  ]);

  return {
    data: promotedApps,
    ...combineQueriesStates([
      storeAppsQuery,
      dataStreamAppsQuery,
      promotedAppsQuery,
    ]),
  };
};

/**
 * useSourceApps: Return a list of source application,
 * data object is a constructed object of apps from the stores,
 * datastream status for each app and the source app settings if it exists.
 *
 * @todo update any type to a valid type
 * @param accountId
 * @returns a query object gathering data and its status.
 * data is an array of source application
 */
export const useSourceApps = (accountId: string) => {
  const { data: storeApps, ...storeAppsQuery } = useStoresApps(accountId);

  // Fetch DataStream Apps
  const dataStreamAppsQuery = useQuery(
    [accountId, 'dataStreamApps'],
    () => {
      const input: DataStreamSourceApp[] = storeApps!.map(
        ({ bundleId, os }) => ({
          bundle_id: bundleId,
          os: os === OS.IOS ? 'ios' : 'android',
        })
      );
      return getAllDataStreamStatus({ data_stream_activation_apps: input });
    },
    { enabled: !!storeApps }
  );

  // Fetch Source Apps
  const sourceAppsQuery = useQuery([accountId, 'sourceApps'], () =>
    getAllSourceApps(accountId)
  );

  // Build SourceApps result
  const sourceApps = React.useMemo<DisplaySourceApp[]>(() => {
    if (
      !storeApps ||
      !dataStreamAppsQuery.isSuccess ||
      !sourceAppsQuery.isSuccess
    ) {
      return [];
    }
    return storeApps.map((storeApp) => {
      const dataStream = dataStreamAppsQuery.data?.find(
        ({ bundle_id, os }) =>
          storeApp.bundleId === bundle_id && storeApp.os === os
      )?.activation;
      const sourceApp = sourceAppsQuery.data?.find(
        ({ appStoreId }) => storeApp.appStoreId === appStoreId
      );
      return {
        ...storeApp,
        dataStream,
        ...sourceApp,
      };
    });
  }, [
    storeApps,
    dataStreamAppsQuery.isSuccess,
    dataStreamAppsQuery.data,
    sourceAppsQuery.isSuccess,
    sourceAppsQuery.data,
  ]);

  return {
    data: sourceApps,
    ...combineQueriesStates([
      storeAppsQuery,
      dataStreamAppsQuery,
      sourceAppsQuery,
    ]),
  };
};

/**
 * useEditSourceAppSettings: Edit app source settings
 *
 * @param accountId
 * @param appId
 * @returns An Edited source app with its settings
 */

export const useEditSourceAppSettings = (
  accountId: string,
  appId: string
): UpdateQuery<APISourceAppResponse, InputSourceAppSettings> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: InputSourceAppSettings) =>
      editXpromoSettings(accountId, appId, params),
    {
      onError: (err) => {
        if (axios.isAxiosError(err)) {
          const { response } = err as AxiosError<APIResponseError>;
          const message = handleAPIErrors(response?.data || {});
          Notif('Update Ad Delivery Settings', message, 'danger');
          return;
        }
        Notif('Update Ad Delivery Settings', 'something went wrong', 'danger');
      },
      onSuccess: () => {
        Notif(
          `Update Ad Delivery Settings`,
          'Source app has been updated',
          'success'
        );
        return queryClient.invalidateQueries([accountId, 'sourceApps']);
      },
    }
  );
};

/**
 * useCreateApiKeySourceAppSettings: Create an api key
 * But also create basic settings for xpromo setup and create the app source
 * @param accountId
 * @param callBackOnError a function that will be triggered when error is catched
 * @returns an object containing a token, the app source and the settings for this app.
 */
export const useCreateApiKeySourceAppSettings = (
  accountId: string,
  callBackOnError: Function
): CreateQuery<APIKeySourceAppResponse, InputSourceApp> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: InputSourceApp) => createAppInit(accountId, params),
    {
      onError: (err) => {
        if (axios.isAxiosError(err)) {
          callBackOnError();
          Notif(
            'Generate Api key',
            'Something went wrong! Api key cannot be generated',
            'danger'
          );
        }
      },
      onSuccess: () => {
        Notif(`Generate Api key`, 'success', 'success');
        return queryClient.invalidateQueries([accountId, 'sourceApps']);
      },
    }
  );
};

/**
 * useCreateSourceApp: Create a setting for a source app
 * Mutation will need an InputSourceAppWithName
 * @param accountId
 * @returns setting of the source app
 * @todo no error managed ?
 */
export const useCreateSourceApp = (
  accountId: string
): CreateQuery<APISourceAppResponse, InputSourceAppWithName> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: InputSourceAppWithName) => createSourceApp(accountId, params),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([accountId, 'sourceApps']);
      },
    }
  );
};

/**
 * useDisplaySelectedSourceApps: Return a list of source app
 * eligible to be targeted to appId.
 *
 * Hook used to display the source app list
 * tied to page: PromotedAppSettingsPage
 * @param accountId
 * @param appId
 * @param appOS
 * @returns
 * @todo give clear return type
 */
export const useDisplaySelectedSourceApps = (
  accountId: string,
  appId: string,
  appOS: OS
) => {
  const sourceAppsQuery = useSourceApps(accountId);

  const promotedByQuery = useQuery(
    [accountId, appId, 'promotedByApps'],
    () => getAllPromotedBy(accountId, appId),
    { enabled: sourceAppsQuery.data.length > 0 }
  );

  const displaySelectedSourceApps = React.useMemo(() => {
    if (!promotedByQuery.isSuccess || sourceAppsQuery.data.length === 0) {
      return [];
    }

    const selectedApps = sourceAppsQuery.data.map((appSource) => ({
      ...appSource,
      selected: !!promotedByQuery.data?.find(
        (promotedByApp) => promotedByApp.bundle_id === appSource.bundleId
      ),
      grayed: !appSource.apiKeyToken || !appSource.dataStream,
    }));

    // Filter the app on which we make the call and the apps of the selected OS
    return selectedApps.filter((a) => {
      return a.appId !== appId && a.os === appOS;
    });
  }, [
    promotedByQuery.isSuccess,
    promotedByQuery.data,
    sourceAppsQuery.data,
    appOS,
    appId,
  ]);

  return {
    data: displaySelectedSourceApps,
    ...combineQueriesStates([sourceAppsQuery, promotedByQuery]),
  };
};

export const usePromotedFromApps = (
  accountId: string,
  appId: string
): ReadAllQuery<FormattedXDKApp> =>
  useQuery([accountId, appId, 'promotedByApps'], () =>
    getAllPromotedBy(accountId, appId)
  );

/**
 * useEditSourceAppSelection: edit the list of application
 * containing all the application promoted by appId
 *
 * Hook depends on page PromotedAppSettingsPage
 * @param accountId
 * @param appId
 * @returns the new list
 */
export const useEditSourceAppSelection = (
  accountId: string,
  appId: string
): UpdateQuery<APISourceAppSelection, InputSourceAppsSelection> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: InputSourceAppsSelection) =>
      editPromotedBy(accountId, appId, params),
    {
      onError: (err) => {
        if (axios.isAxiosError(err)) {
          const { response } = err as AxiosError<APIResponseError>;
          const message = handleAPIErrors(response?.data || {});
          Notif('Edit cross promotion', message, 'danger');
          return;
        }
        Notif('Edit cross promotion', 'Something went wrong', 'danger');
      },
      onSuccess: () => {
        Notif(
          `Edit cross promotion`,
          'Your changes have been saved',
          'success'
        );
        return queryClient.invalidateQueries([
          accountId,
          appId,
          'promotedByApps',
        ]);
      },
    }
  );
};

/**
 * useLaunchAndStopPromotedApp: launch or stop a promoted app
 * InputPromotedApp contain a field promoted_status, it is used to
 * describe the current status of promotion of the app.
 * true : launched, false : paused
 * @param accountId
 * @param appId
 * @returns The app updated
 */
export const useLaunchOrStopPromotedApp = (
  accountId: string,
  appId: string
): UpdateQuery<APIPromotedAppResponse, InputPromotedApp> => {
  const queryClient = useQueryClient();
  return useMutation(
    (input: InputPromotedApp) => editPromotedApp({ accountId, appId, input }),
    {
      onError: (err, input) => {
        const kind = input.promotion_status ? 'Launch' : 'Stop';
        if (axios.isAxiosError(err)) {
          const { response } = err as AxiosError<APIResponseError>;
          const message = handleAPIErrors(response?.data || {});
          Notif(`${kind} cross promotion`, message, 'danger');
          return;
        }
        Notif(`${kind} cross promotion`, 'something went wrong', 'danger');
      },
      onSuccess: (data) => {
        const kind = data.app.promotion_status ? 'Launch' : 'Stop';
        const msgkind = data.app.promotion_status ? 'launched' : 'stopped';
        Notif(
          `${kind} cross promotion`,
          `The cross promotion is ${msgkind}`,
          'success'
        );
        return queryClient.invalidateQueries([accountId, 'promotedApps']);
      },
    }
  );
};

/**
 * useEditPromotedAppTrackingLinks: edit tracking links of a promoted app
 * InputPromotedApp contain a click_template_tracking_link and a impression_template_tracking_link
 * API accepts either valid URLs for both fields or both fields at null, empty strings aren't accepted
 * @param accountId
 * @param appId
 * @returns The app updated
 */
export const useEditPromotedAppTrackingLinks = (
  accountId: string,
  appId: string
): UpdateQuery<APIPromotedAppResponse, InputPromotedApp> => {
  const queryClient = useQueryClient();
  return useMutation(
    (input: InputPromotedApp) => editPromotedApp({ accountId, appId, input }),
    {
      onError: () => {
        Notif('Tracking links update', 'Something went wrong', 'danger');
      },
      onSuccess: () => {
        Notif(
          'All good!',
          'Your attribution measurement URLs are saved',
          'success'
        );
        return queryClient.invalidateQueries([accountId, 'promotedApps']);
      },
    }
  );
};
const testingDevicesCacheKey = 'testingDevices';

export const useGetAllTestingDevices = <SelectedData>(
  params: { accountId: string },
  options?: UseQueryOptions<
    APICollection<APITestingDevice>,
    APIError,
    SelectedData
  >
) => {
  const { accountId } = params;

  return useQuery({
    queryKey: [accountId, 'testingDevices'],
    queryFn: () => getAllTestingDevices(accountId).then((response) => response),
    ...options,
  });
};
export const useCreateTestingDevice = (accountId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: APICreateTestingDeviceData) =>
      createTestingDevice(accountId, data).then(getItem),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([accountId, testingDevicesCacheKey]),
    }
  );

  return useMemo(() => mutation, [mutation]);
};

export const useEditTestingDevice = (accountId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: APIEditTestingDeviceData) =>
      editTestingDevice(accountId, data).then(getItem),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([accountId, testingDevicesCacheKey]),
    }
  );

  return useMemo(() => mutation, [mutation]);
};

export const useDeleteTestingDevice = (accountId: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (testingDeviceId: string) =>
      deleteTestingDevice(accountId, testingDeviceId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([accountId, testingDevicesCacheKey]),
    }
  );

  return useMemo(() => mutation, [mutation]);
};

export default {
  usePromotedApps,
  useSourceApps,
  useEditSourceAppSettings,
  useCreateApiKeySourceAppSettings,
  useCreateSourceApp,
  useDisplaySelectedSourceApps,
  useLaunchOrStopPromotedApp,
  useEditPromotedAppTrackingLinks,
  usePromotedFromApps,
  useGetAllTestingDevices,
  useCreateTestingDevice,
  useEditTestingDevice,
  useDeleteTestingDevice,
};
