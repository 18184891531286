import {
  DisplayPromotedApp,
  InputPromotedApp,
} from '../../models/crosspromotion';
import { getPlatform } from '../../utils/getPlatform';

export const getInputPromotedAppFromDisplayPromotedApp = (
  displayPromotedApp: DisplayPromotedApp
): InputPromotedApp => ({
  app_store_id: displayPromotedApp.appStoreId,
  bundle_id: displayPromotedApp.bundleId,
  click_template_tracking_link: displayPromotedApp.clickTrackingLink,
  impression_template_tracking_link: displayPromotedApp.impressionTrackingLink,
  name: displayPromotedApp.name,
  os: getPlatform(displayPromotedApp.os),
  promotion_status: displayPromotedApp.promotionStatus,
  tracking_provider: displayPromotedApp.trackingProvider,
  tracking_provider_token: displayPromotedApp.trackingProviderToken,
  native_banner_activated: displayPromotedApp.nativeBannerActivated,
  title_id: displayPromotedApp.titleId,
});

export default getInputPromotedAppFromDisplayPromotedApp;
