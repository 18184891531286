import { crossDKApiInstance } from '../index';

export const deleteTestingDevice = (
  accountId: string,
  testingDeviceId: string
): Promise<void> =>
  crossDKApiInstance.delete(
    `/accounts/${accountId}/testing_devices/${testingDeviceId}`
  );

export default deleteTestingDevice;
