import axios, { AxiosRequestConfig } from 'axios';
// import * as Sentry from '@sentry/react';
import keycloak from '../keycloak';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
export const REPORTING_URL =
  process.env.REACT_APP_REPORTING_API_URL || 'http://localhost:8081';

export const PROXY_SENTRY_URL: string | null =
  process.env.REACT_APP_PROXY_SENTRY_URL || null;

export const predikteevApiInstance = axios.create({
  baseURL: `${API_URL}/`,
});

export const predikteevReportingInstance = axios.create({
  baseURL: `${REPORTING_URL}/`,
});

export const crossDKApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_CROSSDK_ADMIN_API_URL}/`,
});

export const churnApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_CHURN_API_URL}/`,
});

async function authInterceptorOnFulfilled(config: AxiosRequestConfig) {
  const { timeSkew = 0 } = keycloak;

  // Update token if almost expired
  return keycloak.updateToken(timeSkew).then(() => {
    const { token } = keycloak;

    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  });
}

// skip refresh token
if (process.env.NODE_ENV !== 'test') {
  predikteevApiInstance.interceptors.request.use(
    authInterceptorOnFulfilled,
    (error) => {
      Promise.reject(error);
    }
  );

  crossDKApiInstance.interceptors.request.use(
    authInterceptorOnFulfilled,
    (error) => {
      Promise.reject(error);
    }
  );

  predikteevReportingInstance.interceptors.request.use(
    authInterceptorOnFulfilled,
    (error) => {
      Promise.reject(error);
    }
  );

  churnApiInstance.interceptors.request.use(
    authInterceptorOnFulfilled,
    (error) => {
      Promise.reject(error);
    }
  );
}
