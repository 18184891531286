import type { APICreativeSet } from '../../models/creativeSets';
import { crossDKApiInstance } from '../index';

export const getAllCreativeSets = async (
  accountId: string,
  appId?: string
): Promise<APICreativeSet[]> => {
  const { data } = await crossDKApiInstance.get(
    `/accounts/${accountId}/creatives_sets/`
  );
  return (
    data?.creatives_sets.filter((creativeSet: APICreativeSet) =>
      appId ? creativeSet.app_id === appId : true
    ) || []
  );
};

export default getAllCreativeSets;
