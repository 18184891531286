import React, { Dispatch, SetStateAction, memo, CSSProperties } from 'react';
import { Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';

import { CountryModel } from '../../../../models/countries';
import FlagIcon from '../../FlagIcon';

interface CountryCellProps {
  currentCountry: CountryModel;
  cssWindow: CSSProperties;
  selectedCountries: CountryModel[];
  setSelectedCountries: Dispatch<SetStateAction<CountryModel[]>>;
}

export default memo(
  ({
    currentCountry,
    selectedCountries,
    setSelectedCountries,
    cssWindow,
  }: CountryCellProps) => {
    const { iso2: isocode, name } = currentCountry;

    const isSelected =
      selectedCountries.find(({ iso2 }) => iso2 === isocode) !== undefined;
    return (
      <Col style={cssWindow}>
        <Form.Check
          type="checkbox"
          id={`check${isocode}`}
          name={`countries.${isocode}`}
          className="d-inline mx-2"
          onClick={() => {
            if (!isSelected) {
              setSelectedCountries([...selectedCountries, currentCountry]);
              return;
            }

            const nextState = selectedCountries.filter(
              (country) => country !== currentCountry
            );
            setSelectedCountries([...nextState]);
          }}
          defaultChecked={isSelected}
        />
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id={`tooltip-${isocode}`}>{name}</Tooltip>}
        >
          <Form.Label htmlFor={`check${isocode}`}>
            <FlagIcon
              code={isocode.toLowerCase()}
              className="bordered-flag me-n1"
            />
            <span className="ms-2">{isocode}</span>
          </Form.Label>
        </OverlayTrigger>
      </Col>
    );
  }
);
