import React from 'react';
import { capitalize } from 'lodash';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useAccountById } from '../../hooks/api/accounts';
import { OS } from '../../utils/types';
import OsBadge from '../General/Badge/OsBadge';

type DeveloperInformationProps = {
  os: OS;
  name: string;
};

function DeveloperInformation({ os, name }: DeveloperInformationProps) {
  return (
    <div className="devs-info">
      <OsBadge os={os} />
      <div className="ms-3">{name}</div>
    </div>
  );
}

type AccountInformationProps = {
  accountId: string;
};

function AccountInformation({ accountId }: AccountInformationProps) {
  const { data: account, isLoading } = useAccountById({ accountId });

  if (isLoading || !account) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Spinner
          className="d-flex align-items-center justify-content-center"
          animation="border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="account-information pt-2 mt-4">
      <Row>
        {!!account.logo_url && (
          <Col sm={12} md={3}>
            <div className="account-logo-card bg-white rounded p-3 d-flex align-items-center justify-content-center">
              <img
                className="img-fluid"
                src={account.logo_url}
                alt={account.name}
                title={account.name}
              />
            </div>
          </Col>
        )}
        <Col className="d-flex flex-column justify-content-center">
          <Row>
            <Col>
              <h1 className="text-capitalize mb-2">{account.name}</h1>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={5} data-testid="developers">
              <div className="mb-2">
                <h4>Developer(s) Name:</h4>
              </div>

              {account.developers.map((dev) => {
                return (
                  <DeveloperInformation
                    key={dev.os}
                    os={dev.os}
                    name={dev.name}
                  />
                );
              })}
            </Col>
            <Col sm={12} md={4}>
              <div className="mb-2">
                <h4>Contact email:</h4>
              </div>
              <div className="mb-2">{account.email}</div>
            </Col>
            <Col sm={12} md={3}>
              <div className="mb-2">
                <h4>Plan:</h4>
              </div>
              <div>{capitalize(account.plan.name)}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AccountInformation;
