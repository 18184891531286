import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dropdown, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import { FormattedCreativeSets } from '../../models/creativeSets';

import Badge from '../General/Badge/Badge';
import StatusBadge, { StatusBadgeVariant } from '../General/Badge/StatusBadge';
import Table from '../General/Table';
import ErrorAccount from './ErrorAccount';
import ActionsMenu from '../General/Menu/ActionsMenu';
import { APICreative } from '../../models/creative';

const initialState = {
  sorting: [
    {
      id: 'status',
      desc: true,
    },
    {
      id: 'name',
      desc: false,
    },
  ],
};

const columnHelper = createColumnHelper<FormattedCreativeSets>();

const getStatusPopoverBody = (
  isArchived: boolean,
  hasNoActive: boolean,
  creatives: APICreative[]
) => {
  if (isArchived) {
    return 'This Creative Set is archived';
  }
  if (hasNoActive) {
    return 'There are no active creatives in this Creative Set';
  }
  return creatives.map((creative) => (
    <div key={creative.id}>{creative.name}</div>
  ));
};

const getStatusBadgeVariant = (isArchived: boolean, hasNoActive: boolean) => {
  if (isArchived) return StatusBadgeVariant.NEUTRAL;
  if (hasNoActive) return StatusBadgeVariant.DANGER;
  return StatusBadgeVariant.SUCCESS;
};

type Props = {
  accountId: string;
  data: FormattedCreativeSets[];
  isLoading: boolean;
  isError: boolean;
};

function CreativeSetsTable({ accountId, data, isLoading, isError }: Props) {
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Set Name',
        cell: (info) => (
          <div className="text-start">
            <Link
              className={classNames('text-underline-hover fw-bold', {
                'disabled-element': info.row.original.deletedAt,
              })}
              to={`/account/${accountId}/creatives/edit/${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          </div>
        ),
      }),
      columnHelper.accessor('language.id', {
        header: 'Language',
        cell: (info) => <div className="text-uppercase">{info.getValue()}</div>,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last Updated',
        cell: (info) => (
          <div className="text-uppercase">
            {DateTime.fromISO(info.getValue()).toFormat('yyyy LL dd')}
          </div>
        ),
        sortingFn: 'datetime',
      }),
      columnHelper.accessor(
        (row) => {
          let countActiveCreativeSets: number | undefined;

          if (!row.deletedAt) {
            countActiveCreativeSets = row.creatives.reduce(
              (sum, creative) => (creative.status === 'live' ? sum + 1 : sum),
              0
            );
          }
          return row.deletedAt
            ? 'Archived'
            : `${countActiveCreativeSets} active`;
        },
        {
          id: 'status',
          header: 'Status',
          cell: (info) => {
            const isArchived = info.getValue() === 'Archived';
            const hasNoActive = info.getValue()[0] === '0';

            return (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="left"
                overlay={
                  <Popover>
                    <Popover.Header>
                      {isArchived ? 'Archived' : `${info.getValue()} creatives`}
                    </Popover.Header>
                    <Popover.Body className="fw-light">
                      {getStatusPopoverBody(
                        isArchived,
                        hasNoActive,
                        info.row.original.creatives
                      )}
                    </Popover.Body>
                  </Popover>
                }
              >
                <Badge>
                  <StatusBadge
                    text={info.getValue()}
                    variant={getStatusBadgeVariant(isArchived, hasNoActive)}
                  />
                </Badge>
              </OverlayTrigger>
            );
          },
          sortingFn: (rowA, rowB) => {
            const rowAValue = rowA.getValue('status') as string;
            const rowBValue = rowB.getValue('status') as string;

            if (rowAValue === 'Archived') return -1;

            return rowAValue.localeCompare(rowBValue, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        }
      ),
      columnHelper.display({
        id: 'actions',
        cell: (info) =>
          info.row.original.deletedAt && (
            <div className="text-end">
              <ActionsMenu title="actions">
                <Dropdown.Item className="ps-4">
                  Unarchive Creative Set
                </Dropdown.Item>
              </ActionsMenu>
            </div>
          ),
      }),
    ],
    [accountId]
  );

  const table = useReactTable({
    data,
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  if (isError) {
    return <ErrorAccount dataEntity="creative" />;
  }

  return (
    <Table
      table={table}
      emptyDataText="There are no creative sets yet."
      className="table-generic"
      rowTestPrefix="creative-set"
      rowTestKey="id"
    />
  );
}

export default CreativeSetsTable;
