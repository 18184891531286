import React, { useCallback, useState } from 'react';
import { Button, Card, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useGetAllCreativeSets } from '../../../hooks/api/creative-set/useCreativeSet.getAll';
import { DisplayPromotedApp } from '../../../models/crosspromotion';
import CreativeSetsTable from '../CreativeSetsTable';
import { useEditPromotedApp } from '../../../hooks/api/promoted-app/promotedApp.edit';
import { getInputPromotedAppFromDisplayPromotedApp } from '../../../api/promoted-app/getInputPromotedAppFromDisplayPromotedApp';

function CreativeSetsCard({ app }: { app: DisplayPromotedApp }) {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { appId, nativeBannerActivated } = app;

  const { data, isLoading, isError } = useGetAllCreativeSets(accountId, appId);

  const [isPopoverDisplayed, setIsPopoverDisplayed] = useState(false);

  const { mutateAsync: updatePromotedApp } = useEditPromotedApp();

  const displayPopover = () => {
    setIsPopoverDisplayed(true);
  };

  const hidePopover = () => {
    setIsPopoverDisplayed(false);
  };

  const changeNativeBannerStatus = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      updatePromotedApp({
        accountId,
        appId,
        input: {
          ...getInputPromotedAppFromDisplayPromotedApp(app),
          native_banner_activated: event.target.checked,
        },
      }),
    [accountId, app, appId, updatePromotedApp]
  );

  return (
    <Card className="creative-content">
      <Card.Header className="pb-3">
        <h3 className="mb-4">Creatives</h3>
        <div className="d-flex justify-content-between align-items-center">
          <OverlayTrigger
            show={isPopoverDisplayed}
            delay={300}
            placement="right"
            overlay={
              <Popover onMouseEnter={displayPopover} onMouseLeave={hidePopover}>
                <Popover.Header>Native banner</Popover.Header>
                <Popover.Body className="fw-light">
                  While active, native banner can be displayed when ad unit is
                  requested. More detailed information in the{' '}
                  <Link
                    to={`/account/${accountId}/creatives`}
                    className="fw-bold text-underline-hover"
                  >
                    Creatives page
                  </Link>
                  .
                </Popover.Body>
              </Popover>
            }
          >
            <h6
              className="mb-0"
              onMouseEnter={displayPopover}
              onMouseLeave={hidePopover}
            >
              Native Banner <FontAwesomeIcon icon={solid('circle-info')} />
            </h6>
          </OverlayTrigger>
          <div className="d-flex align-items-center gap-2">
            <span className="fw-bold text-primary fs-4">Active</span>
            <Form.Switch
              defaultChecked={nativeBannerActivated}
              onChange={changeNativeBannerStatus}
            />
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <h6>Creative Sets</h6>
          <Link to={`/account/${accountId}/creatives/add`}>
            <Button>Add a new creative set</Button>
          </Link>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <CreativeSetsTable
          accountId={accountId}
          data={data ?? []}
          isLoading={isLoading}
          isError={isError}
        />
      </Card.Body>
    </Card>
  );
}

export default CreativeSetsCard;
