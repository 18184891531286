import React from 'react';

function Globe({ size = 58 }: { size?: number }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      height={`${size}px`}
      width={`${size}px`}
      viewBox="0 0 58 58"
    >
      <g>
        <circle style={{ fill: '#285680' }} cx="29" cy="29" r="29" />
        <g>
          <path
            style={{ fill: '#24AE5F' }}
            d="M29,0c-4.03,0-7.866,0.823-11.353,2.308c-0.159,0.949-0.952,2.57-0.928,3.087
			c0.112,0.022,7.373-7.4,6.472-2.39C22.61,6.23,16.675,6.211,16.93,9.458c6.236,2.445,8.411-4.642,13.708-4.613
			c1.767,0.01,6.292-2.784,7.155-2.215c3.496,2.303-0.799,10.467-3.474,7.75c-2.572-2.572-6.242,0.901-6.077,4.912
			c1.337,0.06,1.908-3.796,3.11-2.51c1.324,1.417,0.846,4.27-0.986,4.925c-1.844,0.659-9.077-4.89-7.3-0.684
			c1.954,4.668-5.854,8.275,2.028,6.393c3.716-0.891,2.481,1.937,4.234,3.286c2.088,1.603,1.164-2.214,2.686-1.633
			c2.215,0.857-0.381,3.065,3.088,1.462c4.069-1.856,2.54,1.038,3.786,2.93c2.336,3.532,8.077,6.922,11.086,2.254
			c0.709-1.101,0.254-9.807,4.72-3.967c2.836,3.71,1.863-4.383,2.247-6.509C53.547,8.993,42.327,0,29,0z"
          />
        </g>
        <path
          style={{ fill: '#FAC176' }}
          d="M32.919,42.082l-5.348-4.008l-3.585,0.141l-3.719-1.17l-2.911-2.007l-6.603,1.526L8.03,37.881
		L5,41.963l1.072,4.784c0.306,0.395,0.623,0.782,0.949,1.161l5.608,2.618l3.785,0.511l2.252,4.622l0.068,0.465
		C21.926,57.333,25.385,58,29,58c2.494,0,4.914-0.315,7.224-0.908l0.761-4.544l3.459-2.807l1.437-4.481l-7.222-0.03L32.919,42.082z"
        />
        <path
          style={{ fill: '#88C057' }}
          d="M0.375,33.647c0.138-0.012,0.156-0.024,0.168-0.036c1.297-1.296,2.377-1.513,3.458-3.458
		c1.081-1.945-0.216-1.945,0-5.187c0.216-3.242-0.216-1.729,0-5.187c0.078-1.244-0.053-1.928-1.565-2.417C0.873,20.926,0,24.86,0,29
		C0,30.582,0.131,32.133,0.375,33.647z"
        />
        <path
          style={{ fill: '#FAC176' }}
          d="M30,53c-1.083-0.083-5.083-1.333-6-3c-0.917-1.667-2.917-4.5-3-4
		c-0.057,0.34-9.111-2.743-15.331-4.938L5,41.963l1.072,4.784c0.306,0.395,0.623,0.782,0.949,1.161l5.608,2.618l3.785,0.511
		l2.252,4.622l0.068,0.465C21.926,57.333,25.385,58,29,58c2.494,0,4.914-0.315,7.224-0.908l0.577-3.445
		C36.292,53.488,35.705,53.278,35,53C32.25,51.917,31.083,53.083,30,53z"
        />
        <path
          style={{ fill: '#88C057' }}
          d="M40.444,49.741l1.437-4.481l-7.222-0.03l-1.741-3.148l-5.348-4.008l-3.585,0.141l-3.719-1.17
		l-2.911-2.007l-6.603,1.526L8.03,37.881l-2.361,3.18C11.889,43.257,20.943,46.34,21,46c0.083-0.5,2.083,2.333,3,4
		c0.917,1.667,4.917,2.917,6,3c1.083,0.083,2.25-1.083,5,0c0.705,0.278,1.292,0.488,1.801,0.647l0.184-1.099L40.444,49.741z"
        />
        <path
          style={{ fill: '#88C057' }}
          d="M39.925,2.153c-0.194,3.66-0.337,6.305-0.337,6.305L34,14.046c0,0,5.238,7.508,5.588,8.381
		c0.349,0.873,4.714,1.921,5.588,1.397c0.873-0.524,4.191-3.318,5.588-1.397c0.972,1.337,0.847,2.504,1.445,3.325
		c0.412,0.006,0.927,0.283,1.569,0.93c0.908,0.272,1.943,0.539,2.865,0.762c0.306-1.79,0.079-4.983,0.299-6.205
		C54.532,12.549,48.172,5.516,39.925,2.153z"
        />
        <path
          style={{ fill: '#FAC176' }}
          d="M43.33,3.806C43.148,7.26,43,10,43,10l-4,4c0,0,3.75,5.375,4,6s3.375,1.375,4,1s3-2.375,4-1
		s0.375,2.5,2,3c1.373,0.423,3.267,0.842,3.825,0.962c0.008-1.112,0.011-2.141,0.116-2.723C54.876,13.787,49.903,7.555,43.33,3.806z
		"
        />
      </g>
    </svg>
  );
}

export default Globe;
