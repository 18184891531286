interface VideoDimensions {
  width: number;
  height: number;
}

export const getVideoDimensions = (file: File): Promise<VideoDimensions> => {
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.muted = true;
    const source = document.createElement('source');
    source.src = url;
    video.preload = 'metadata';
    video.appendChild(source);
    video.onloadedmetadata = () => {
      resolve({ height: video.videoHeight, width: video.videoWidth });
    };
  });
};

export const getSingleFileError = (files: File[]) => {
  if (files.length > 1) {
    return `Please upload only one file.`;
  }
  return undefined;
};

export const getFileTypeError = (file: File, fileTypes: string[]) => {
  if (fileTypes.length > 0 && !fileTypes.includes(file.type)) {
    const extensions = fileTypes
      .map((fileType) => `.${fileType.substring(fileType.indexOf('/') + 1)}`)
      .join(' and ');

    return `This format is not supported. The accepted ${
      fileTypes.length === 1 ? 'format is ' : 'formats are '
    } ${extensions}`;
  }
  return undefined;
};

/**
 * These values are those used by the service validation
 * do not change them unless the service changes
 */

const marginError = 0.02;
const landscapeRatio = 1.78;
const portraitRatio = 0.56;

export const getLandscapeError = ({ height, width }) => {
  const assetRatio = width / height;
  if (
    assetRatio < landscapeRatio - marginError ||
    assetRatio > landscapeRatio + marginError
  ) {
    return "Your file doesn't have a landscape orientation";
  }
  return undefined;
};

export const getPortraitError = ({ height, width }) => {
  const assetRatio = width / height;

  if (
    assetRatio < portraitRatio - marginError ||
    assetRatio > portraitRatio + marginError
  ) {
    return "Your file doesn't have a portrait orientation";
  }
  return undefined;
};

export const getSizeTooBigError = (file: File, maxSize: number) => {
  if (file.size > maxSize) {
    return `The size of your creative exceeds the maximum ${maxSize}MB`;
  }
  return undefined;
};
