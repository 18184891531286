import React, { memo, useCallback } from 'react';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'react-bootstrap';

import Loader from '../../General/Loader/Loader';
import { useDeleteTestingDevice } from '../../../hooks/api/crosspromotion';
import Notif from '../../../utils/notification';

import type { APITestingDevice } from '../../../models/crosspromotion';

const notifySuccess = () =>
  Notif(`Delete device`, 'You have deleted this test device !', 'success');

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

type DeleteTestingDeviceModalModalProps = {
  testingDeviceId: APITestingDevice['id'];
  accountId: string;
};

function DeleteTestingDeviceModal(props: DeleteTestingDeviceModalModalProps) {
  const { testingDeviceId, accountId } = props;

  const { isLoading, mutateAsync: deleteDeviceMutation } =
    useDeleteTestingDevice(accountId);

  const modal = useModal();

  const deleteDeviceAndNotify = useCallback(
    () =>
      deleteDeviceMutation(testingDeviceId)
        .then(() => {
          notifySuccess();
          modal.hide();
        })
        .catch(() => notifyError()),
    [deleteDeviceMutation, testingDeviceId, modal]
  );

  return (
    <Modal backdrop="static" {...bootstrapDialog(modal)} centered>
      {isLoading && <Loader />}
      <Modal.Header closeButton>
        <h1>Delete device</h1>
      </Modal.Header>
      <Modal.Body>
        <h5 className="text-center">
          Are you sure you want to delete this device ?
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" onClick={deleteDeviceAndNotify}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NiceModal.create(memo(DeleteTestingDeviceModal));
