/**
 * allows you to generate an initial selection report for your table.
 * If the item of the collection matches the condition isSelected it will be selected
 * @param data
 * @param isSelected
 */
export const getRowSelection = <Item>(
  data: Item[],
  isSelected: (item: Item) => boolean
) =>
  data
    .map((row, index) => ({ index, row }))
    .filter(({ row }) => isSelected(row))
    .reduce(
      (acc, current) => ({
        ...acc,
        [current.index]: true,
      }),
      {}
    );

export default getRowSelection;
