import {
  DisplaySourceApp,
  FormatedSourceApp,
} from '../../models/crosspromotion';

type SourceAppApiKeyStatus = 'generated' | 'inactive' | 'not generated';
export const getSourceAppApiKeyStatus = (
  sourceApp: FormatedSourceApp | DisplaySourceApp
): SourceAppApiKeyStatus => {
  if (!sourceApp.apiKeyToken) {
    return 'not generated';
  }
  if (sourceApp.allowApiAccess) {
    return 'generated';
  }
  return 'inactive';
};

export default getSourceAppApiKeyStatus;
