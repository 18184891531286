import { uniqueId } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type StepSelectorProps = {
  name: string;
  stepList: number[];
  labelFormat?: (value: string) => string;
  defaultValue: any;
};

function FormStepSelector({
  name,
  stepList,
  labelFormat,
  defaultValue,
}: StepSelectorProps) {
  const { control, setValue } = useFormContext();

  const uid = uniqueId('step-selector');
  const mmin = stepList[0];
  const mmax = stepList[stepList.length - 1];
  const steppingBy = (mmax - mmin) / (stepList.length - 1);

  const closest = (val: number, list: number[]) => {
    if (list.length === 0) return val;
    if (list.length === 1) return list[0];

    let nearest = list[0];
    for (let i = 1; i < list.length; i += 1) {
      const nextStep = list[i];
      if (Math.abs(nextStep - val) < Math.abs(nearest - val)) {
        nearest = nextStep;
      }
    }

    return nearest;
  };

  const captureStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = parseInt(e.target.value, 10);
    const value = closest(currentValue, stepList);
    setValue(name, value);
  };

  return (
    <Controller
      name={name}
      rules={{
        required: true,
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, ref } }) => {
        return (
          <>
            <input
              ref={ref}
              list={uid}
              type="range"
              className="step-selector-input w-100"
              min={mmin}
              max={mmax}
              value={value}
              step={steppingBy}
              onChange={captureStep}
            />
            <datalist id={uid} className="step-selector-input__list">
              {stepList.map((step) => (
                <option
                  key={step}
                  className="step-selector-input__opt"
                  value={step}
                  label={
                    labelFormat ? labelFormat(step.toString()) : step.toString()
                  }
                />
              ))}
            </datalist>
          </>
        );
      }}
    />
  );
}

export default FormStepSelector;
