import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';

import Loader from '../General/Loader/Loader';
import { useAccountById, useEditAccount } from '../../hooks/api/accounts';
import accountUtils from '../../utils/account';
import AccountFields from './AccountFields';
import { FormAccount } from '../../models/accounts';
import usePlans from '../../hooks/plan/usePlans';
import { GetAccountParams } from '../../api/account/account.get.duplicate';

const EditAccount = NiceModal.create<GetAccountParams>(({ accountId }) => {
  const modal = useModal();

  const mutation = useEditAccount();

  const methods = useForm<FormAccount>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { setValue, handleSubmit } = methods;

  const { data: plans, isLoading: isLoadingPlans } = usePlans();

  const {
    data: account,
    isLoading: isLoadingAccount,
    isFetched,
    isError,
  } = useAccountById({ accountId });

  const handleValidation = useCallback(
    (data: FormAccount) => {
      mutation
        .mutateAsync({
          accountId,
          data,
        })
        .then(modal.remove);
    },
    [accountId, modal.remove, mutation]
  );

  useEffect(() => {
    const developers = accountUtils.getDevelopersName(account?.developers);
    if (account) {
      setValue('name', account?.name);
      setValue('email', account?.email);
      setValue('androidDeveloper', developers.android ?? '');
      setValue('iosDeveloper', developers.ios ?? '');
      setValue('planId', account.plan.id);
    }
  }, [setValue, account]);

  return (
    <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
      {(mutation.isLoading || isLoadingAccount || isLoadingPlans) && <Loader />}
      <Modal.Header closeButton>
        <h1>Account information</h1>
      </Modal.Header>
      {isFetched && isError && (
        <Alert variant="danger">
          <FontAwesomeIcon
            icon={solid('exclamation-triangle')}
            className="text-white"
          />{' '}
          Error on loading account
        </Alert>
      )}
      {isFetched && !isError && (
        <Modal.Body>
          <FormProvider {...methods}>
            <Form
              onSubmit={handleSubmit(handleValidation)}
              id="form-account-edit"
            >
              <Row>
                <Col md={6} className="mb-5">
                  <AccountFields.AccountName />
                </Col>
                <Col md={6} className="mb-5">
                  <AccountFields.AccountLogo />
                </Col>
                <Col md={6} className="mb-4">
                  <AccountFields.AccountEmail />
                </Col>
                <Col md={6} className="mb-4">
                  <AccountFields.AccountAndroidDeveloper />
                </Col>
                <Col md={6} className="mb-3">
                  <AccountFields.AccountPlan plans={plans} />
                </Col>
                <Col md={6} className="mb-3">
                  <AccountFields.AccountIosDeveloper />
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button type="submit" form="form-account-edit">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default EditAccount;
