import { OS } from '../../utils/types';
import { getPlatform } from '../../utils/getPlatform';

export const getAllAppsFromStore = (os: OS, developerName: string) => {
  return fetch(
    `${process.env.REACT_APP_APP_STORE_URL}/search?os=${getPlatform(
      os
    )}&developer=${encodeURIComponent(developerName)}`
  ).then((response) => response.json() || []);
};

export default getAllAppsFromStore;
