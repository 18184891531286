import React from 'react';
import { Roles } from '../../utils/types';
import useRestricted from '../../hooks/permission/useRestricted';

export type RestrictedProps = {
  to: Roles[];
  fallback?: React.ReactNode | string;
  children: React.ReactNode;
};

function Restricted({ to, fallback, children }: RestrictedProps) {
  const isAllowed = useRestricted(to);

  switch (true) {
    case isAllowed:
      return <>{children}</>;
    case !!fallback:
      return <>{fallback}</>;
    default:
      return null;
  }
}

export default Restricted;
