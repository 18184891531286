import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Restricted from '../components/Permission/Restricted';
import { Roles, UserSessionWithAttributes } from '../utils/types';

type ToggleProps = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const MenuToggle = React.forwardRef<HTMLAnchorElement, ToggleProps>(
  ({ onClick }: ToggleProps, ref) => {
    const { keycloak } = useKeycloak();
    const { email } = keycloak.tokenParsed as UserSessionWithAttributes;

    return (
      <a
        href="#/"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="align-items-center d-flex py-2 text-decoration-none"
      >
        <span className="avatar avatar-sm rounded-circle bg-purple">
          {email?.substring(0, 2).toUpperCase()}
        </span>
        <FontAwesomeIcon
          icon={solid('angle-down')}
          className="ms-2 text-white"
        />
      </a>
    );
  }
);

function UserProfileMenu({
  hideUsersManagement,
}: {
  hideUsersManagement?: boolean;
}) {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { keycloak } = useKeycloak();
  if (!keycloak.authenticated || keycloak.tokenParsed === undefined) {
    // This can happen if the user isn't logged, on the 404 page for example
    return null;
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={MenuToggle} />

      <Dropdown.Menu className="p-0">
        <Dropdown.Header>Welcome!</Dropdown.Header>

        <Restricted to={[Roles.ADIKTEEV_ADMIN]}>
          <Dropdown.Item as={Link} to="/admin/accounts" className="py-2">
            <FontAwesomeIcon
              icon={solid('users-cog')}
              size="sm"
              className="me-2"
            />
            Accounts
          </Dropdown.Item>
          <Dropdown.Divider className="m-0" />
        </Restricted>

        <Restricted to={[Roles.ADIKTEEV_ADMIN]}>
          {!hideUsersManagement && (
            <>
              <Dropdown.Item
                as={Link}
                to={`/admin/account/${accountId}/users`}
                className="py-2"
              >
                <FontAwesomeIcon
                  icon={solid('users')}
                  size="sm"
                  className="me-2"
                />
                Users management
              </Dropdown.Item>
              <Dropdown.Divider className="m-0" />
            </>
          )}
        </Restricted>

        <Dropdown.Item as={Link} to="/auth/logout" className="py-2">
          <FontAwesomeIcon
            icon={solid('power-off')}
            size="sm"
            className="me-2"
          />
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserProfileMenu;
