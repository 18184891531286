import { Orientation, OS, Platform } from '../utils/types';

// There is numerous `App` in the project
// XDK App is an app under the crossdk project
export type APIXDKApp = {
  app_store_id: string;
  bundle_id: string;
  os: 'android' | 'ios';
};

export type FormattedXDKApp = Omit<APIXDKApp, 'os'> & {
  os: OS;
};

export type InputSourceApp = Required<APIXDKApp>;

export type InputSourceAppWithName = { name?: string } & InputSourceApp;

// Type from app store api
export type AppStoreQuery = {
  appStoreId: string;
  bundleId: string;
  name: string;
  logoUrl: string;
  os: OS;
};

export type PromotedAppQuery = AppStoreQuery & {
  promoted: string;
};

export enum TrackingProviders {
  other = 'other',
  adjust = 'adjust',
  appsflyer = 'appsflyer',
}

export type APIPromotedApp = {
  id: string;
  name: string;
  account_id: string;
  app_store_id: string;
  bundle_id: string;
  os: 'android' | 'ios';
  title_id: string;
  created_at: string;
  updated_at: string;
  eligible_for_promotion: boolean;
  promotion_status: boolean;
  impression_template_tracking_link: string | null;
  click_template_tracking_link: string | null;
  // the mmp token used by adjust or appsflyer
  tracking_provider_token: string | null;
  // the mmp used for this app
  tracking_provider: TrackingProviders;
  native_banner_activated: boolean;
};

export type FormattedAPIPromotedApp = Omit<APIPromotedApp, 'os'> & {
  os: OS;
};

export type DisplayPromotedApp = AppStoreQuery & {
  appId: string;
  dataStream: boolean;
  promotionStatus: boolean;
  impressionTrackingLink: string | null;
  clickTrackingLink: string | null;
  trackingProvider: TrackingProviders;
  trackingProviderToken: string | null;
  nativeBannerActivated: boolean;
  titleId?: string;
};

export type InputPromotedApp = Pick<
  APIPromotedApp,
  | 'app_store_id'
  | 'bundle_id'
  | 'os'
  | 'impression_template_tracking_link'
  | 'click_template_tracking_link'
  | 'tracking_provider'
  | 'tracking_provider_token'
> & {
  name?: string;
  promotion_status?: boolean;
  title_id?: string;
  native_banner_activated?: boolean;
};

export type APIPromotedAppResponse = { app: APIPromotedApp };

/// Settings

export type APISourceAppToken = {
  name: string;
  token: string;
  created_at: string;
};

export type APISourceApp = {
  app_id: string;
  app_store_id: string;
  os: 'android' | 'ios';
  targeted_user_base_percentage: number;
  control_group_percentage: number;
  tokens: APISourceAppToken[];
  allow_api_access: boolean;
};

export type APISourceAppListResponse = { xpromo_settings: APISourceApp[] };
export type APISourceAppResponse = { xpromo_settings: APISourceApp };

// Generate an api key
export type APIKeySourceAppResponse = {
  token: APISourceAppToken;
  app: APIXDKApp;
  xpromo_settings: APISourceApp;
};

export type FormatedSourceApp = {
  appStoreId: string;
  appId: string;
  churn: number;
  controlGroup: number;
  apiKeyToken: string;
  allowApiAccess: boolean;
};

export type DisplaySourceApp = AppStoreQuery &
  Partial<FormatedSourceApp> & {
    dataStream: boolean | undefined;
  };

export type DataStreamSourceApp = {
  bundle_id: string;
  os: 'android' | 'ios';
};

export type InputDataStream = {
  data_stream_activation_apps: DataStreamSourceApp[] | undefined;
};

export type APIDataStreamSourceApp = {
  bundle_id: string;
  os: 'android' | 'ios';
  activation: boolean;
};

export type FormattedAPIDataStreamSourceApp = Omit<
  APIDataStreamSourceApp,
  'os'
> & {
  os: OS;
};

export type DataStreamQuery = {
  data_stream_activation_apps: APIDataStreamSourceApp[];
};

export type InputSourceAppSettings = Pick<
  APISourceApp,
  'targeted_user_base_percentage' | 'control_group_percentage'
>;

export type SourceAppQuery = AppStoreQuery &
  Partial<DisplaySourceApp> & {
    activation?: boolean;
  };

export type DisplaySelectedSourceApp = SourceAppQuery & {
  selected: boolean;
  grayed: boolean;
};

// Video
export type APIPromotedAppVideo = {
  id: string;
  name: string;
  url: string;
  preview_url: string;
  orientation: Orientation;
  created_at: string;
  updated_at: string;
};

// For single video
export type InputPromotedAppVideo = {
  video: File;
  name: string;
  orientation: Orientation;
};

export type InputSourceAppSelection = {
  id: string;
  promotion_activated: boolean;
};

export type InputSourceAppsSelection = {
  source_apps: InputSourceAppSelection[];
};

export type APISourceAppSelection = InputSourceAppsSelection;

export type APITestingDevice = {
  id: string;
  account_id: string;
  device_identifier: string;
  updated_at: string;
  created_at: string;
  os: Platform;
  name?: string;
};

export type APICreateTestingDeviceData = Omit<
  APITestingDevice,
  'id' | 'updated_at' | 'created_at' | 'account_id'
>;

export type APIEditTestingDeviceData = Omit<
  APITestingDevice,
  'updated_at' | 'created_at'
>;
