import React, { memo, useCallback } from 'react';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Form, Modal } from 'react-bootstrap';

import { FormProvider, useForm } from 'react-hook-form';
import type { AxiosError } from 'axios';
import Loader from '../../General/Loader/Loader';
import { useEditTestingDevice } from '../../../hooks/api/crosspromotion';
import Notif from '../../../utils/notification';
import TestingDeviceForm, {
  TestingDeviceFormValues,
} from './Form/TestingDeviceForm';
import type { APITestingDevice } from '../../../models/crosspromotion';
import type { APIError } from '../../../models/api';
import defaultValues from './Form/defaultValues';

const notifySuccess = () =>
  Notif(`Success`, 'your changes are saved', 'success');

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

type EditTestingDeviceModalProps = {
  initialValues: APITestingDevice;
};

function EditTestingDeviceModal(props: EditTestingDeviceModalProps) {
  const { initialValues } = props;

  const { account_id: accountId } = initialValues;

  const { isLoading, mutateAsync } = useEditTestingDevice(accountId);

  const modal = useModal();

  const updateTestingDeviceMutation = useCallback(
    (newValues: TestingDeviceFormValues) =>
      mutateAsync({ ...initialValues, ...newValues }),
    [initialValues, mutateAsync]
  );

  const methods = useForm({
    defaultValues: { ...defaultValues, ...initialValues },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { setError, handleSubmit } = methods;

  const setErrorOnDeviceIdentifierForUnicity = useCallback(
    () =>
      setError('device_identifier', {
        message:
          'This device id already exists. Please enter another device id',
      }),
    [setError]
  );

  const onValid = useCallback(
    (values: TestingDeviceFormValues) =>
      updateTestingDeviceMutation(values)
        .then(() => {
          modal.hide();
          notifySuccess();
        })
        .catch((error: AxiosError<APIError>) => {
          if (error.response?.data.code === 'duplicate_record_err') {
            setErrorOnDeviceIdentifierForUnicity();
          }
          return notifyError();
        }),
    [updateTestingDeviceMutation, modal, setErrorOnDeviceIdentifierForUnicity]
  );

  return (
    <Modal backdrop="static" {...bootstrapDialog(modal)} centered>
      {isLoading && <Loader />}
      <Modal.Header closeButton>
        <h1>Edit test device</h1>
      </Modal.Header>
      <Modal.Body className="mb-3">
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onValid)} id="form-testing-device">
            <TestingDeviceForm />
          </Form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button form="form-testing-device" type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NiceModal.create(memo(EditTestingDeviceModal));
