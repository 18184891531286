import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';
import { FeatureName, Roles } from '../../utils/types';
import accountUtils from '../../utils/account';
import useAccountId from '../useAccountId';
import useAllowFeature from './useIsAllowed';

interface LocationState {
  to: {
    pathname: string;
  };
}

export default (): { isLoading: boolean; redirect?: LocationState } => {
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const accountId = useAccountId();
  const { isLoading, isAllowed } = useAllowFeature(
    accountId,
    FeatureName.CROSS_PROMOTION_ACTIVATION
  );
  const { isAllowed: hasChurnApi } = useAllowFeature(
    accountId,
    FeatureName.CHURN_API
  );
  if (!isLoading && keycloak?.authenticated) {
    switch (true) {
      case accountUtils.hasRoles(keycloak, [Roles.ADIKTEEV_ADMIN]): {
        return {
          isLoading,
          redirect: {
            to: { pathname: `/admin/accounts` },
          },
        };
      }
      case accountUtils.hasRoles(keycloak, [Roles.ACCOUNT_USER]) &&
        hasChurnApi: {
        return {
          isLoading,
          redirect: {
            to: { pathname: `/account/${accountId}/churn-api` },
          },
        };
      }
      case isAllowed: {
        return {
          isLoading,
          redirect: {
            to: { pathname: `/account/${accountId}/crosspromotion` },
          },
        };
      }
      // Users without the cross_promotion plan that are not Adikteev-admin don't have access to anything
      // so we redirect them to the 404 page to avoid infinite redirections
      case !accountUtils.hasRoles(keycloak, [Roles.ADIKTEEV_ADMIN]) &&
        !isAllowed &&
        !hasChurnApi: {
        return {
          isLoading,
          redirect: {
            to: { pathname: '/404' },
          },
        };
      }
      default:
        break;
    }
  }
  return {
    isLoading,
    redirect: location.state as LocationState,
  };
};
