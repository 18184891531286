import React from 'react';
import { createRoot } from 'react-dom/client';

// Google Analytics
import ReactGA from 'react-ga4';

// Google Tag Manager
import TagManager from 'react-gtm-module';

// IAM Keycloak
import keycloak from './keycloak';

// CSS
import './assets/scss/index.scss';

import Root from './pages/Root';

import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
    legacyDimensionMetric: false,
    // Page view event will be sent manually to avoid sending inconsistent data (no title, redirections...)
    gtagOptions: { send_page_view: false },
  });
}

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

// Start the mocking conditionally.
if (process.env.REACT_APP_MOCKS === 'true') {
  import('./mocks/lib/worker').then(({ default: mocks }) =>
    mocks.populate().setupWorker().start()
  );
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<Root keycloakInstance={keycloak} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
