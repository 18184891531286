import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Row, Form, Modal } from 'react-bootstrap';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';

import { useCreateAccount } from '../../hooks/api/accounts';
import Loader from '../General/Loader/Loader';
import AccountFields from './AccountFields';
import { FormAccount } from '../../models/accounts';
import usePlans from '../../hooks/plan/usePlans';

const CreateAccount = NiceModal.create(() => {
  const modal = useModal();

  const mutation = useCreateAccount();

  const methods = useForm<FormAccount>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleSubmit } = methods;

  const { data: plans, isLoading: isLoadingPlans } = usePlans();

  const handleValidation = (data: FormAccount) => {
    mutation
      .mutateAsync({
        data,
      })
      .then(modal.remove);
  };

  return (
    <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
      {(mutation.isLoading || isLoadingPlans) && <Loader />}
      <Modal.Header closeButton>
        <h1>Account information</h1>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(handleValidation)}
            id="form-account-create"
          >
            <Row>
              <Col md={6} className="mb-5">
                <AccountFields.AccountName />
              </Col>
              <Col md={6} className="mb-5">
                <AccountFields.AccountLogo />
              </Col>
              <Col md={6} className="mb-4">
                <AccountFields.AccountEmail />
              </Col>
              <Col md={6} className="mb-4">
                <AccountFields.AccountAndroidDeveloper />
              </Col>
              <Col md={6} className="mb-3">
                <AccountFields.AccountPlan plans={plans} />
              </Col>
              <Col md={6} className="mb-3">
                <AccountFields.AccountIosDeveloper />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="form-account-create">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default CreateAccount;
