import type { ApiUserModel, UserModelQuery } from '../../models/users';
import { predikteevApiInstance } from '../index';

export const getAllUsers = async (
  accountId: string
): Promise<UserModelQuery[]> => {
  const { data } = await predikteevApiInstance.get(
    `/accounts/${accountId}/users/`
  );

  return (
    data?.users?.map((user: ApiUserModel) => {
      return {
        id: user.id,
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
        role: user.role,
      };
    }) || []
  );
};

export default getAllUsers;
