import React, { memo } from 'react';

function AssetVideo(
  props: React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  >
) {
  // eslint-disable-next-line react/prop-types
  const { className, ...otherProps } = props;

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video {...otherProps} className={`${className} tag`} />;
}

export default memo(AssetVideo);
