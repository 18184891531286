import React, { Dispatch, forwardRef, SetStateAction } from 'react';
import CountryFlag from './flags';
import { CountryModel } from '../../../../models/countries';

interface FlagButtonProps {
  countries: CountryModel[];
  setIsModalDisplayed: Dispatch<SetStateAction<boolean>>;
  isVisible: boolean;
}

export default forwardRef(
  (
    { countries, setIsModalDisplayed, isVisible }: FlagButtonProps,
    ref: any
  ) => {
    if (isVisible) {
      return (
        <div
          className="button-country-custom"
          onClick={() => setIsModalDisplayed((state) => !state)}
          aria-hidden="true"
          role="button"
          ref={ref}
        >
          <CountryFlag countries={countries} />
        </div>
      );
    }
    return (
      <div className="model-button-country-custom" aria-hidden="true" ref={ref}>
        <CountryFlag countries={countries} />
      </div>
    );
  }
);
