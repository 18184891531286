import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ScrollToTop
 * This Component scrolls the page to the top on each route navigation
 * This is a normal behavior of react-router
 * Please check https://v5.reactrouter.com/web/guides/scroll-restoration
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
