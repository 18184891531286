import React, { CSSProperties } from 'react';
import { FieldValues, PathValue } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import { OptionProps } from 'react-select';

type SelectLanguageCustomOptionProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = OptionProps<PathValue<TFieldValues, TName>, false, never>;

function SelectLanguageCustomOption<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: SelectLanguageCustomOptionProps<TFieldValues, TName>) {
  const { innerRef, innerProps, label, getStyles } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props) as CSSProperties}
    >
      {label}
    </div>
  );
}

export default SelectLanguageCustomOption;
