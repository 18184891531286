import React from 'react';
import { Card } from 'react-bootstrap';
import { DisplayPromotedApp } from '../../../models/crosspromotion';
import OsBadge from '../../General/Badge/OsBadge';
import Logo from '../../General/Logo';
import CreativeSetsCard from './CreativeSetsCard';
import SourceAppsList from './SourceAppsList';
import TrackerUrlsCard from './TrackerUrls/TrackerUrlsCard';

function Header({ app }: { app: DisplayPromotedApp }) {
  return (
    <Card className="settings-header">
      <Card.Body>
        <Logo title={app.name} url={app.logoUrl} height={60} />
        <h1 className="mx-3">{app.name}</h1>
        <OsBadge os={app.os} />
      </Card.Body>
    </Card>
  );
}

interface PromotedAppSettingsProps {
  app: DisplayPromotedApp;
}

function PromotedAppSettings({ app }: PromotedAppSettingsProps) {
  return (
    <div className="promoted-app-settings">
      <Header app={app} />
      <TrackerUrlsCard app={app} />
      <SourceAppsList app={app} />
      <CreativeSetsCard app={app} />
    </div>
  );
}

export default PromotedAppSettings;
