import React, { useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dropdown, Spinner } from 'react-bootstrap';

import { UserModelQuery } from '../../models/users';
import { useGetUsers } from '../../hooks/api/users';
import useRestricted from '../../hooks/permission/useRestricted';
import useUserSession from '../../hooks/useUserSession';
import { Roles } from '../../utils/types';

import UserDeleteModal from './UserDeleteModal';
import UserResetPasswordModal from './UserResetPasswordModal';
import ActionsMenu from '../General/Menu/ActionsMenu';
import Table from '../General/Table';
import UserEditModal from './UserEditModal';

type Props = {
  accountId: string;
};

const columnHelper = createColumnHelper<UserModelQuery>();

function UsersTable({ accountId }: Props) {
  const { data, isLoading, isError } = useGetUsers(accountId);
  const isAdikteevAdmin = useRestricted([Roles.ADIKTEEV_ADMIN]);
  const sessionUser = useUserSession();

  const filteredData = isAdikteevAdmin
    ? data
    : data?.filter((user) => user.role !== Roles.ADIKTEEV_ADMIN);

  const getFormattedRole = (role: string) => {
    if (!role) {
      return '';
    }
    const formattedRole = role.replace('_', ' ');
    return formattedRole.charAt(0).toUpperCase() + formattedRole.slice(1);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => `${row.firstName || ''} ${row.lastName || ''}`,
        {
          id: 'name',
          header: 'Name',
        }
      ),
      columnHelper.accessor('email', {
        header: 'Email',
      }),
      columnHelper.accessor((row) => getFormattedRole(row.role), {
        header: 'Role',
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <div className="text-end">
            <ActionsMenu title="actions">
              {isAdikteevAdmin && (
                <>
                  <Dropdown.Item
                    onClick={() =>
                      NiceModal.show(UserEditModal, {
                        user: info.row.original,
                        accountId,
                      })
                    }
                    alt="edit user"
                  >
                    <FontAwesomeIcon
                      icon={solid('pencil-alt')}
                      size="sm"
                      className="me-2"
                    />
                    Edit User
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      NiceModal.show(UserResetPasswordModal, {
                        userId: info.row.original.id,
                        accountId,
                      })
                    }
                    alt="Reset user password"
                  >
                    <FontAwesomeIcon
                      icon={solid('user-lock')}
                      size="xs"
                      className="me-2"
                    />
                    Reset Password
                  </Dropdown.Item>
                  {info.row.original.id !== sessionUser.sub && (
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() =>
                        NiceModal.show(UserDeleteModal, {
                          userId: info.row.original.id,
                          accountId,
                        })
                      }
                      alt="delete user"
                    >
                      <FontAwesomeIcon
                        icon={solid('trash-alt')}
                        className="me-2"
                      />
                      Delete User
                    </Dropdown.Item>
                  )}
                </>
              )}
            </ActionsMenu>
          </div>
        ),
      }),
    ],
    [accountId, isAdikteevAdmin, sessionUser.sub]
  );

  const initialState = useMemo(
    () => ({
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    }),
    []
  );

  const table = useReactTable({
    data: filteredData ?? [],
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isError) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <p className="text-danger">Error on loading users</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <Table
      table={table}
      emptyDataText="There are no users yet"
      rowTestPrefix="user"
      rowTestKey="id"
      className="table-admin"
    />
  );
}

export default UsersTable;
