import { churnApiInstance } from '../index';
import { Platform } from '../../utils/types';

export type ChurnScoreInfos = {
  app_store_id: string;
  bundle_id: string;
  os: Platform;
  last_update: string;
};

type GetAllChurnScoresInfosReturn = { apps: ChurnScoreInfos[] };

export const getAllChurnScoresInfos = async (): Promise<ChurnScoreInfos[]> => {
  const { data } = await churnApiInstance.get<GetAllChurnScoresInfosReturn>(
    `churn_scores/info`
  );
  return data.apps;
};

export default getAllChurnScoresInfos;
