import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../layout/Page';
import AccountInformation from '../../components/Account/AccountInformation';
import PromotedAppsTable from '../../components/CrossPromotion/PromotedAppsTable';
import RequireFeature from '../../components/Permission/RequireFeature';
import { FeatureName } from '../../utils/types';
import UserNavigation from '../../layout/UserNavigation';

function PromotedAppsPage() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <Page
      title="Promoted Apps Overview"
      userNavigation={
        <UserNavigation
          title={<h2>PROMOTED APPS OVERVIEW</h2>}
          variant="long-header"
        >
          <AccountInformation accountId={accountId} />
        </UserNavigation>
      }
    >
      <PromotedAppsTable />
    </Page>
  );
}

export default RequireFeature(
  PromotedAppsPage,
  FeatureName.CROSS_PROMOTION_ACTIVATION
);
