import type { AxiosResponse } from 'axios';
import { crossDKApiInstance } from '../index';
import type { APILanguage } from '../../models/language';

type APIGetAllLanguagesResponse = { languages: APILanguage[] };
export const getAllLanguages = () =>
  crossDKApiInstance
    .get(`languages/`)
    .then(
      (response: AxiosResponse<APIGetAllLanguagesResponse>) =>
        response.data.languages
    );

export default getAllLanguages;
