import type { AxiosResponse } from 'axios';
import type { InputUser, UserModelQuery } from '../../models/users';
import { predikteevApiInstance } from '../index';

export const createUser = async (
  accountId: string,
  params: InputUser
): Promise<UserModelQuery> => {
  const response: AxiosResponse<{
    user: UserModelQuery;
  }> = await predikteevApiInstance.post(
    `/accounts/${accountId}/users/`,
    params
  );
  return response.data.user;
};

export default createUser;
