import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OS, Platform } from './types';

export const getPlatform = (os: OS) =>
  os === OS.IOS ? Platform.IOS : Platform.Android;

export const getPlatformWithFA = (os: OS) =>
  os === OS.IOS ? brands('apple') : brands('android');

export default { getPlatform, getPlatformWithFA };
