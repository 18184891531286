import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomForm from '../General/Forms';
import type { AdSettingsFormValues } from './AdSettingsModal';

export const adSettingsFormRules = {
  targeted_user_base_percentage: {},
  control_group_percentage: {},
};

export function ChurnThreshold() {
  return (
    <Form.Group controlId="formChurnThreshold">
      <Form.Label>Targeted userbase</Form.Label>
      <p>
        Select the proportion of daily active users (DAU) that will be exposed
        to cross promotion ads based on their churn rank
      </p>
      <p>
        By default, a minimum threshold of 30% daily active users is recommended
        to ensure optimal performance.
      </p>
      <div className="w-75 mx-auto">
        <CustomForm.Slider
          name="targeted_user_base_percentage"
          min={0.3}
          step={0.1}
          max={1}
          tooltip={{
            format: (v: number) => `${Math.round(v * 100)}% of DAU`,
            offset: -10,
          }}
          minLabel="High risk of churn"
          maxLabel="Low risk of churn"
        />
        <CustomForm.Error field="targeted_user_base_percentage" />
      </div>
    </Form.Group>
  );
}

export function ControlGroup() {
  const { register } = useFormContext<AdSettingsFormValues>();

  const hasControlGroup = useWatch({ name: 'hasControlGroup' });

  return (
    <Form.Group controlId="formControlGroup">
      <Form.Label>Control Group Activation</Form.Label>
      <p>
        If you activate this control group, only the percentage left of users
        will see an ad when the SDK calls a cross promotion ad
      </p>
      <Form.Check {...register('hasControlGroup')} type="switch" />
      {hasControlGroup && (
        <div className="w-75 mx-auto mt-3">
          <CustomForm.Slider
            name="control_group_percentage"
            min={0.2}
            step={0.1}
            max={0.8}
            tooltip={{
              format: (v: number) =>
                `Control group size ${Math.round(v * 100)}%`,
              offset: -50,
            }}
          />
          <CustomForm.Error field="control_group_percentage" />
        </div>
      )}
    </Form.Group>
  );
}

export default {
  ChurnThreshold,
  ControlGroup,
};
