import { useEffect } from 'react';
import { Updater, RowSelectionState } from '@tanstack/react-table';

interface Params<T> {
  data: T[];
  setRowSelection: (updater: Updater<RowSelectionState>) => void;
  selectKey: string;
}

const useRowSelection = <T>({
  data,
  setRowSelection,
  selectKey,
}: Params<T>) => {
  useEffect(() => {
    if (data.length > 0) {
      setRowSelection(
        data.reduce(
          (res, currentApp, index) => ({
            ...res,
            ...(currentApp[selectKey] && { [index]: currentApp[selectKey] }),
          }),
          {}
        )
      );
    }
  }, [data, selectKey, setRowSelection]);
};

export default useRowSelection;
