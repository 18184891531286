import { useMutation, useQueryClient } from 'react-query';

import {
  APICreateCreativeSetPayload,
  createCreativeSet,
} from '../../../api/creative-set/creativeSet.create';

export const useCreateCreativeSet = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: APICreateCreativeSetPayload) => createCreativeSet(payload),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['creatives-sets']);
      },
    }
  );
};

export default useCreateCreativeSet;
