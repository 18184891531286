import React, { useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import logo from '../../assets/img/logo-adikteev.png';
import useOutsideClick from '../../hooks/useOutsideClick';
import useSidebar from '../../hooks/sidebar/useSidebar';

function Sidebar({ children }: { children?: React.ReactNode }) {
  const { isOpen, setIsOpen } = useSidebar();

  const refContainer = useRef(null);

  useOutsideClick(refContainer, () => setIsOpen(false));

  return (
    <div
      ref={refContainer}
      className={classNames('sidebar', {
        'sidebar-open': isOpen,
      })}
    >
      <img className="w-100 mt-1 mb-4" src={logo} alt="Logo Adikteev" />
      {children}
    </div>
  );
}

export function ToggleSidebar() {
  const { setIsOpen } = useSidebar();

  return (
    <button
      type="button"
      className="sidebar-toggle"
      onClick={() => {
        setIsOpen((state) => !state);
      }}
    >
      <FontAwesomeIcon icon={solid('bars')} className="text-white" />
    </button>
  );
}

export default Sidebar;
