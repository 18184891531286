import { useKeycloak } from '@react-keycloak/web';
import { UserSessionWithAttributes } from '../utils/types';

export default (): string => {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated) {
    const { account_id: accountId } =
      keycloak.tokenParsed as UserSessionWithAttributes;

    return accountId;
  }
  return '';
};
