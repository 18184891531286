import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import Select, { GetOptionLabel } from 'react-select';
import { useGetAllLanguages } from '../../hooks/api/languages/useLanguages.getAll';
import SelectLanguageCustomOption from './SelectLanguageCustomOption';
import { getSelectDefaultStyles } from '../getSelectDefaultStyle';
import type { APILanguage } from '../../models/language';

const components = {
  Option: SelectLanguageCustomOption,
};

const getOptionLabel: GetOptionLabel<APILanguage> = (option) => option.name;

type SelectLanguageProps<FormValues extends FieldValues> =
  UseControllerProps<FormValues>;

export function SelectLanguage<FormValues extends FieldValues>(
  props: SelectLanguageProps<FormValues>
) {
  const { fieldState, field } = useController(props);

  const {
    data: languagesWithEnglishFirst = [],
    isLoading: isLanguagesLoading,
  } = useGetAllLanguages({
    select: (allLanguages) => {
      return [
        { id: 'en', name: 'English' },
        ...allLanguages.filter(({ id }) => id !== 'en'),
      ];
    },
  });

  return (
    <Select
      {...field}
      options={languagesWithEnglishFirst}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => option}
      components={components}
      isClearable
      placeholder={
        isLanguagesLoading
          ? 'Loading...'
          : 'Select the language of your Creative Set'
      }
      isSearchable
      styles={getSelectDefaultStyles(fieldState)}
    />
  );
}

export default SelectLanguage;
