import type { AxiosError } from 'axios';

const getErrorFieldName = (error: AxiosError) => {
  switch (error.name) {
    case 'click_template_tracking_link':
      return 'clickTemplateTrackingLink';
    case 'impression_template_tracking_link':
      return 'impressionTemplateTrackingLink';
    default:
      throw Error(`This field name is not handle ${error.name}`);
  }
};

export default getErrorFieldName;
