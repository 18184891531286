import React from 'react';
import { Button, Modal, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplaySourceApp } from '../../models/crosspromotion';
import Notif from '../../utils/notification';
import { useCreateApiKeySourceAppSettings } from '../../hooks/api/crosspromotion';
import { OS } from '../../utils/types';
import Badge from '../General/Badge/Badge';
import Logo from '../General/Logo';
import OsBadge from '../General/Badge/OsBadge';
import { getSourceAppApiKeyStatus } from './getSourceAppApiKeyStatus';

type AdSettingsApiKeyModalProps = {
  app: DisplaySourceApp;
  accountId: string;
};

const AdSettingsApiKeyModal = NiceModal.create<AdSettingsApiKeyModalProps>(
  ({ app, accountId }) => {
    const modal = useModal();
    const [token, setToken] = React.useState(app.apiKeyToken);
    const { name, appStoreId, logoUrl } = app;
    const hideModal = () => {
      NiceModal.hide(AdSettingsApiKeyModal);
    };
    const { mutateAsync } = useCreateApiKeySourceAppSettings(
      accountId,
      hideModal
    );

    const copy = async () => {
      await navigator.clipboard.writeText(token || '');
      Notif('Generate Api key', 'Api Key copied !', 'success');
    };
    const createAPIKey = React.useCallback(async () => {
      const appInitied = await mutateAsync({
        bundle_id: app.bundleId,
        app_store_id: app.appStoreId,
        os: app.os === OS.IOS ? 'ios' : 'android',
      });
      setToken(appInitied.token.token);
    }, [mutateAsync, app, setToken]);

    React.useEffect(() => {
      if (!token) {
        createAPIKey();
      }
    }, [createAPIKey, token, app]);

    return (
      <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
        <Modal.Header closeButton className="justify-content-start">
          <Logo height={60} url={logoUrl} title={name} />
          <Stack direction="vertical" className="justify-content-center">
            <Stack direction="horizontal">
              <OverlayTrigger delay={300} overlay={<Tooltip>{name}</Tooltip>}>
                <h1 className="text-truncate mx-3">{name}</h1>
              </OverlayTrigger>
              <OsBadge os={app.os} className="me-5" />
            </Stack>
            <p
              className="mx-3 mt-2 text-s mb-0 text-dark"
              style={{ fontSize: '12px' }}
            >
              <strong className="fw-bold">App ID : </strong>
              {appStoreId}
            </p>
          </Stack>
        </Modal.Header>
        <Modal.Body className="text-center gap-1 d-flex flex-column">
          <h4>API key</h4>
          <div>
            <Badge className="fw-light px-3 fs-3 d-inline-block w-auto">
              {token}
            </Badge>
          </div>
          {getSourceAppApiKeyStatus(app) === 'inactive' && (
            <p className="fw-light fs-4 text-danger">
              <FontAwesomeIcon icon={solid('warning')} /> This API key has been
              deactivated, please contact your admin
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={copy}>Copy API key</Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default AdSettingsApiKeyModal;
