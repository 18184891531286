import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type ErrorAccountProps = {
  hasNoApplication?: boolean;
  dataEntity: 'app' | 'creative';
};

function ErrorAccount({ hasNoApplication, dataEntity }: ErrorAccountProps) {
  return (
    <div className="m-4">
      <h4 className="text-dark mb-3">
        <FontAwesomeIcon icon={solid('exclamation-circle')} className="me-1" />
        {hasNoApplication
          ? 'Error retrieving app information'
          : 'Technical error'}
      </h4>
      {hasNoApplication ? (
        <>
          <p>
            No {dataEntity} can be retrieved via your developer name. To
            facilitate integration :
          </p>
          <ol className="ps-3">
            <li>check developer name with your Customer Success Manager</li>
            <li>
              activate your data stream if it&apos;s not already done, to ensure
              a good attribution system
            </li>
          </ol>
        </>
      ) : (
        <p>
          A technical error has occurred. No {dataEntity} can be displayed. We
          will try to resolve this issue soon.
          {dataEntity === 'app' && (
            <>
              <br />
              Meanwhile, to ensure a good event attribution system linked to
              your cross promotions, please activate your data stream if
              it&apos;s not done yet.
            </>
          )}
        </p>
      )}
    </div>
  );
}

export default ErrorAccount;
