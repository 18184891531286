import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { DaysInstall } from '../../../utils/types';

export const DAYS_INSTALL: DaysInstall[] = [
  DaysInstall.THREE,
  DaysInstall.SEVEN,
  DaysInstall.FOURTEEN,
  DaysInstall.THIRTY,
];

interface FormSelectDaysProps {
  defaultValue: DaysInstall;
  days: DaysInstall[];
}

export default function FormSelectDays({
  defaultValue,
  days,
}: FormSelectDaysProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name="daysInstall"
      rules={{
        required: true,
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, ref, onChange } }) => {
        const convertValueInNumberBeforeOnChange = (
          event: React.ChangeEvent<HTMLSelectElement>
        ) =>
          onChange({
            ...event,
            target: { ...event.target, value: +event.target.value },
          });

        return (
          <Form.Select
            className="segmentation-select-large"
            ref={ref}
            onChange={convertValueInNumberBeforeOnChange}
            value={value}
          >
            {days.map((d) => (
              <option key={`days-${d}`} value={d}>
                Last {d} days
              </option>
            ))}
          </Form.Select>
        );
      }}
    />
  );
}
