import FormSelectOs from './FormSelectOs';
import FormSelectDays from './FormSelectDays';
import FormStepSelector from './FormStepSelector';
import FormSelectCountries from './FormSelectCountries';
import FormSelectGranularity from './FormSelectGranularity';
import FormUpload from './FormUpload';
import FormSlider from './Slider';
import Error from './Error';

export default {
  SelectOs: FormSelectOs,
  SelectDays: FormSelectDays,
  StepSelector: FormStepSelector,
  SelectCountries: FormSelectCountries,
  SelectGranularity: FormSelectGranularity,
  Upload: FormUpload,
  Slider: FormSlider,
  Error,
};
