import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Footer from './Footer';
import SideNavigation from '../components/SideNavigation';

interface PageProps {
  leftContent?: ReactNode;
  userNavigation?: ReactNode;
  children?: ReactNode;
  variant?: string;
  title: string;
}

function Page({
  leftContent,
  userNavigation,
  children,
  variant,
  title,
}: PageProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = title || 'Predikteev';
    // We're sending the pageview event to Google Analytics here
    // so the title is set and we're sure to send exploitable data
    ReactGA.send('pageview');
  }, [title, pathname]);

  return (
    <div className="page-layout">
      {leftContent || <SideNavigation />}
      <div className="right-pane with-sidebar">
        <section className="content-body">
          {userNavigation}
          <div className={`container-fluid mt-n6 ${variant || ''}`}>
            {children}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Page;
