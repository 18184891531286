import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import useLanding from '../../hooks/permission/useLanding';

function LoginPage() {
  const { isLoading, redirect } = useLanding();

  const { keycloak } = useKeycloak();

  const login = useCallback(() => {
    return keycloak?.login();
  }, [keycloak]);

  if (isLoading) {
    return <div>loading ...</div>;
  }

  if (!isLoading && redirect && keycloak?.authenticated) {
    return <Redirect to={redirect?.to} />;
  }

  login();

  return <>Login...</>;
}

export default LoginPage;
