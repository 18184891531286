import containsAdjustProviderToken, {
  patternToGetThePartContainingAdjustToken,
} from './containsThePartContainingAdjustToken';

type AdjustTrackingTemplatesParams = {
  isIos: boolean;
  adjustToken: string;
};
const adjustTrackingTemplates = ({
  isIos,
  adjustToken,
}: AdjustTrackingTemplatesParams) => {
  const deviceIdentifierParam = isIos ? 'idfv' : 'gps_adid';
  const deviceIdentifierValue = isIos ? 'idfv' : 'gaid';

  const clickRoot = `https://s2s.adjust.com/${adjustToken}?`;
  const impressionRoot = `https://s2s.adjust.com/impression/${adjustToken}?`;

  const clickTemplate = `${clickRoot}campaign={source_app_name}&adgroup={creative_format}&creative={creative_name}&${deviceIdentifierParam}={${deviceIdentifierValue}}&ak_xpromo_engagement_id={attribution_id}&s2s=1`;
  const impressionTemplate = `${impressionRoot}campaign={source_app_name}&adgroup={creative_format}&creative={creative_name}&${deviceIdentifierParam}={${deviceIdentifierValue}}&ak_xpromo_engagement_id={attribution_id}&s2s=1`;

  const updateClickTemplate = (current: string) =>
    containsAdjustProviderToken(current)
      ? current.replace(patternToGetThePartContainingAdjustToken, clickRoot)
      : clickTemplate;

  const updateImpressionTemplate = (current: string) =>
    containsAdjustProviderToken(current)
      ? current.replace(
          patternToGetThePartContainingAdjustToken,
          impressionRoot
        )
      : impressionTemplate;

  return {
    clickTemplate,
    impressionTemplate,
    updateClickTemplate,
    updateImpressionTemplate,
  };
};

export default adjustTrackingTemplates;
