import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Badge from './Badge';
import { OS } from '../../../utils/types';
import { getPlatformWithFA } from '../../../utils/getPlatform';

interface OsBadgeProps {
  os: OS;
  bg?: string;
  className?: string;
}

function OsBadge({ os, bg, className }: OsBadgeProps) {
  return (
    <Badge className={classNames('os-badge', className)} bg={bg}>
      <FontAwesomeIcon icon={getPlatformWithFA(os)} className="os-icon" />
      <span className="os-title">{os === OS.IOS ? 'iOS' : 'Android'}</span>
    </Badge>
  );
}

export default OsBadge;
