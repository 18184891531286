import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { Form, OverlayTrigger } from 'react-bootstrap';
import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import type { APICreateTestingDeviceData } from '../../../../models/crosspromotion';
import CustomForm from '../../../General/Forms';
import { Platform } from '../../../../utils/types';
import PlatformBadge from '../../../General/Badge/PlatformBadge';
import IDFVPopover from './IDFVPopover';
import GAIDPopover from './GAIDPopover';

export type TestingDeviceFormValues = APICreateTestingDeviceData;

function TestingDeviceForm() {
  const { register, control } = useFormContext<TestingDeviceFormValues>();
  const { errors } = useFormState<TestingDeviceFormValues>();

  const osWatchedValue = useWatch({ name: 'os', control });
  const isIos = osWatchedValue === Platform.IOS;

  return (
    <>
      <Form.Group className="mb-3 row">
        <Form.Label className="is-required">Platform</Form.Label>
        <Controller
          control={control}
          name="os"
          render={({ field: { name, ref, value, onBlur, onChange } }) => (
            <div className="d-block mt-2">
              <Form.Check
                type="radio"
                inline
                label={<PlatformBadge platform={Platform.IOS} />}
                name={name}
                value={Platform.IOS}
                onChange={onChange}
                onBlur={onBlur}
                id={Platform.IOS}
                ref={ref}
                checked={value === Platform.IOS}
              />
              <Form.Check
                type="radio"
                inline
                label={<PlatformBadge platform={Platform.Android} />}
                name={name}
                value={Platform.Android}
                id={Platform.Android}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                checked={value === Platform.Android}
              />
            </div>
          )}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Device name</Form.Label>
        <Form.Text className="d-block mb-2" />
        <Form.Control
          placeholder="Give a name to your device"
          isInvalid={!!errors.name}
          {...register('name', {
            required: false,
            setValueAs: (value: string) => value.trim(),
          })}
        />
        <CustomForm.Error field="name" />
      </Form.Group>
      <Form.Group>
        <Form.Label className="is-required">
          {isIos && (
            <>
              IDFV
              <OverlayTrigger
                placement="right"
                delay={300}
                overlay={IDFVPopover}
              >
                <FontAwesomeIcon
                  className="ms-1 text-info"
                  icon={solid('circle-info')}
                />
              </OverlayTrigger>
            </>
          )}
          {!isIos && (
            <>
              GAID
              <OverlayTrigger
                placement="right"
                delay={300}
                overlay={GAIDPopover}
              >
                <FontAwesomeIcon
                  className="ms-1 text-info"
                  icon={solid('circle-info')}
                />
              </OverlayTrigger>
            </>
          )}
        </Form.Label>

        <Form.Text className="d-block mb-2" />
        <Form.Control
          placeholder="Add the device id"
          isInvalid={!!errors.device_identifier}
          {...register('device_identifier', {
            required: {
              value: true,
              message: 'This field is required',
            },
            pattern: {
              value: isIos
                ? /\b([A-F 0-9]{8})-([A-F 0-9]{4})-([A-F 0-9]{4})-([A-F 0-9]{4})-([A-F 0-9]{12})\b/g
                : /\b([a-f 0-9]{8})-([a-f 0-9]{4})-([a-f 0-9]{4})-([a-f 0-9]{4})-([a-f 0-9]{12})\b/g,
              message:
                'This device id does not have the right format. Please review accepted format and correct your device id',
            },
          })}
        />

        <Form.Control.Feedback className="text-wrap mb-0" as="p" type="invalid">
          {errors?.device_identifier?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export default memo(TestingDeviceForm);
