import React, { memo } from 'react';
import classNames from 'classnames';

import FlagIcon from '../../FlagIcon';
import { CountryModel } from '../../../../models/countries';
import SVG from '../../SVG';

type FlagProps = {
  countries: CountryModel[];
  isModalDisplayed?: boolean;
};

export default memo(({ countries, isModalDisplayed = false }: FlagProps) => {
  switch (countries.length) {
    case 0:
      return (
        <div className="d-flex flag-content">
          <span className="ps-1 text-center text-nowrap text-truncate">
            No country selected
          </span>
        </div>
      );
    case 1: {
      const country = countries[0];

      const { iso2: code } = country;

      return (
        <div className="d-flex flag-content">
          <FlagIcon
            code={code.toLowerCase()}
            className="bordered-flag ms-1 me-1"
          />
          <span className="ps-1 text-center text-nowrap text-truncate">
            {code.toUpperCase()}
          </span>
        </div>
      );
    }

    case 249:
      return (
        <div
          className={classNames(
            'flag-content',
            'd-flex',
            'align-items-center',
            {
              'text-center': !isModalDisplayed,
              'ps-3 pt-1': isModalDisplayed,
            }
          )}
        >
          <SVG.Globe size={20} />
          <span className="ps-1 text-center text-nowrap text-truncate">
            WorldWide
          </span>
        </div>
      );
    default:
      return (
        <div
          className={classNames(
            'flag-content',
            'd-flex',
            'align-items-center',
            {
              'text-center': !isModalDisplayed,
              'ps-3 pt-1': isModalDisplayed,
            }
          )}
        >
          <SVG.Globe size={20} />
          <span className="ps-2">{countries.length}</span>
        </div>
      );
  }
});
