import { OS } from '../../utils/types';
import type { NormalizedTitleModel } from '../../models/titles';
import placeholder from '../../assets/img/placeholder-image.png';
import {
  getApplicationById,
  NormalizedApplicationState,
} from '../store/reducers/titles/applications';
import { APITitleModel } from '../../models/titles';

const getTitleIcon = (title: APITitleModel): string => {
  const { icon_url: iosAppIcon = null } =
    title.applications.find((app) => app && app.os === OS.IOS) || {};

  // If we find an iOS app with an icon, we return this icon
  if (iosAppIcon) {
    return iosAppIcon;
  }

  // Otherwise, return the first android app icon or a placeholder if no icon is found
  const icon = title.applications.find((app) => app && app.os === OS.Android);

  return icon?.icon_url || placeholder;
};

const getImageByTitle = (
  title: NormalizedTitleModel,
  applicationsState: NormalizedApplicationState,
  os?: OS
): string => {
  const titleApps = title.applications.map((appId) =>
    getApplicationById(applicationsState, appId)
  );

  if (os) {
    const appByOs = titleApps.find((app) => app && app.os === os);
    return appByOs?.icon || placeholder;
  }

  const { icon: iosAppIcon = null } =
    titleApps.find((app) => app && app.os === OS.IOS) || {};

  // If we find an iOS app with an icon, we return this icon
  if (iosAppIcon) {
    return iosAppIcon;
  }

  // Otherwise, return the first android app icon or a placeholder if no icon is found
  const { icon } = titleApps.find((app) => app && app.os === OS.Android) || {};

  return icon || placeholder;
};

export { getImageByTitle, getTitleIcon };
