import React, { CSSProperties } from 'react';
import type { FieldValues, PathValue } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types';
import type { OptionProps } from 'react-select';
import Logo from '../General/Logo';
import OsBadge from '../General/Badge/OsBadge';

type SelectPromotedAppCustomOptionProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = OptionProps<PathValue<TFieldValues, TName>, false, never>;

function SelectPromotedAppCustomOption<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(props: SelectPromotedAppCustomOptionProps<TFieldValues, TName>) {
  const { innerRef, innerProps, label, getStyles, data } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props) as CSSProperties}
      className="d-flex gap-1 align-items-center"
    >
      <Logo title="logo" url={data.logoUrl} />
      <div className="d-flex flex-column">
        <h4 className="ms-1">{label}</h4>
        <div className="badge-container">
          <OsBadge os={data.os} bg="transparent" className="ps-0" />
        </div>
      </div>
    </div>
  );
}

export default SelectPromotedAppCustomOption;
