/* eslint-disable import/prefer-default-export */
import { UseQueryResult } from 'react-query';

/**
 * combineQueriesStates:
 * @param queries
 * @returns
 */
export function combineQueriesStates(
  queries: Pick<
    UseQueryResult,
    'isLoading' | 'isFetching' | 'isError' | 'isSuccess'
  >[]
) {
  return {
    isLoading: queries.some(({ isLoading }) => isLoading),
    isFetching: queries.some(({ isFetching }) => isFetching),
    isError: queries.some(({ isError }) => isError),
    isSuccess: queries.every(({ isSuccess }) => isSuccess),
  };
}
