import React, { useCallback, useRef } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import type { Row } from '@tanstack/react-table';
import Step1 from './Step1';
import Step2 from './Step2';
import { useCreateCreativeSet } from '../../../../hooks/api/creative-set/useCreativeSet.create';
import { useCreateAsset } from '../../../../hooks/api/asset/useAsset.create';
import { APIAssetFormatEnum, APIAssetTypeEnum } from '../../../../models/asset';
import { Orientation } from '../../../../utils/types';
import type { APILanguage } from '../../../../models/language';
import Notif from '../../../../utils/notification';

import {
  AllCreativesLive,
  useCreativesLivesSelection,
} from './useCreativesLivesSelection';
import type { DisplayPromotedApp } from '../../../../models/crosspromotion';
import Step3 from './step3/Step3';

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

const notifySuccess = () =>
  Notif(`All good !`, 'Your creative Set has been created', 'success');

export type CreateCreativeSetFormValues = {
  name: string;
  language: APILanguage;
  app: DisplayPromotedApp;
};

const defaultValues: Partial<CreateCreativeSetFormValues> = {
  name: '',
  language: undefined,
  app: undefined,
};

type Props = {
  accountId: string;
};

function CreateCreativeSetForm({ accountId }: Props) {
  const { push: historyPush } = useHistory();

  const goToCreativesPage = useCallback(
    () => historyPush(`/account/${accountId}/creatives`),
    [historyPush, accountId]
  );

  const methods = useForm<CreateCreativeSetFormValues>({ defaultValues });

  const { handleSubmit } = methods;

  const {
    isLoading: isCreatingCreativeSet,
    mutateAsync: createCreativeSetMutation,
  } = useCreateCreativeSet();

  const { isLoading: isCreatingAsset, mutateAsync: createAssetMutation } =
    useCreateAsset();

  const landscapeAssetRef = useRef<File[]>([]);
  const portraitAssetRef = useRef<File[]>([]);

  const {
    creativesLivesSelected,
    updateCreativesLivesSelectionState,
    creativesLivesSelectionState,
    setCreativesLivesSelectionState,
  } = useCreativesLivesSelection();

  const setLandscapeAssetAndSelectCreatives = useCallback(
    (landscapeAsset: File[]) => {
      landscapeAssetRef.current = landscapeAsset;
      updateCreativesLivesSelectionState({
        0: Boolean(landscapeAsset[0]),
        2: Boolean(landscapeAsset[0]),
        // landscape asset is also used for halfscreen portrait
        3: Boolean(landscapeAsset[0]),
      });
    },
    [updateCreativesLivesSelectionState]
  );

  const setPortraitAssetAndSelectCreatives = useCallback(
    (portraitAsset: File[]) => {
      portraitAssetRef.current = portraitAsset;
      updateCreativesLivesSelectionState({
        1: Boolean(portraitAsset[0]),
      });
    },
    [updateCreativesLivesSelectionState]
  );

  const createAsset = useCallback(
    ({
      appId,
      asset,
      orientation,
    }: {
      appId: DisplayPromotedApp['appId'];
      orientation: Orientation;
      asset: File;
    }) =>
      createAssetMutation({
        type: APIAssetTypeEnum.video,
        appId,
        orientation,
        accountId,
        input: {
          asset,
        },
      }),
    [accountId, createAssetMutation]
  );

  const enableVariationsSelectionAccordingToAsset = useCallback(
    (row: Row<AllCreativesLive>) => {
      const isPortraitLive = Boolean(portraitAssetRef.current[0]);
      const isLandscapeLive = Boolean(landscapeAssetRef.current[0]);

      const isLandscape = row.original.orientation === Orientation.landscape;
      const isHalfScreen =
        row.original.format === APIAssetFormatEnum.halfScreen;

      if (
        // landscape asset is also used for halfscreen portrait
        isLandscape ||
        isHalfScreen
      ) {
        return isLandscapeLive;
      }

      return isPortraitLive;
    },
    [portraitAssetRef]
  );

  const createAssetsAndCreativeSet = useCallback(
    async ({ language, name, app }: CreateCreativeSetFormValues) => {
      const { appId } = app;
      const [hasLandscapeAsset, hasPortraitAsset] = [
        Boolean(landscapeAssetRef.current.length),
        Boolean(portraitAssetRef.current.length),
      ];

      return Promise.all([
        hasLandscapeAsset
          ? createAsset({
              appId,
              asset: landscapeAssetRef.current[0],
              orientation: Orientation.landscape,
            })
          : undefined,
        hasPortraitAsset
          ? createAsset({
              appId,
              asset: portraitAssetRef.current[0],
              orientation: Orientation.portrait,
            })
          : undefined,
      ])
        .then(([landscapeAssetCreated, portraitAssetCreated]) => {
          createCreativeSetMutation({
            accountId,
            appId,
            input: {
              language,
              name,
              landscape_asset_id: landscapeAssetCreated?.id,
              portrait_asset_id: portraitAssetCreated?.id,
              lives: creativesLivesSelected,
            },
          }).then(() => {
            notifySuccess();
            goToCreativesPage();
          });
        })
        .catch(notifyError);
    },
    [
      accountId,
      createAsset,
      createCreativeSetMutation,
      creativesLivesSelected,
      goToCreativesPage,
    ]
  );

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={handleSubmit(createAssetsAndCreativeSet)}
        id="creative-management-form"
        className="creativeManagement"
      >
        <Step1 />
        <Step2
          onLandscapeAssetChange={setLandscapeAssetAndSelectCreatives}
          onPortraitAssetChange={setPortraitAssetAndSelectCreatives}
        />
        <Step3
          onRowSelectionChange={setCreativesLivesSelectionState}
          rowSelection={creativesLivesSelectionState}
          enableRowSelection={enableVariationsSelectionAccordingToAsset}
        />
        <Button
          disabled={
            isCreatingAsset ||
            isCreatingCreativeSet ||
            !creativesLivesSelected.length
          }
          type="submit"
          form="creative-management-form"
          className="me-3"
        >
          {isCreatingAsset && (
            <>
              <Spinner
                className="me-2"
                animation="border"
                as="span"
                size="sm"
                role="status"
                aria-hidden="true"
              />{' '}
              Uploading Creative Asset(s)
            </>
          )}
          {!isCreatingAsset && 'Save and activate'}
        </Button>
        <Button
          disabled={isCreatingAsset || isCreatingCreativeSet}
          variant="secondary"
          className="ms-1"
          onClick={goToCreativesPage}
        >
          Cancel
        </Button>
      </Form>
    </FormProvider>
  );
}

export default CreateCreativeSetForm;
