import type { APITitleModel, TitlesAPIParams } from '../../../models/titles';
import { predikteevApiInstance } from '../../../api/index';

export const getAllTitles = (
  params: TitlesAPIParams
): Promise<APITitleModel[]> => {
  return predikteevApiInstance
    .get(`accounts/${params.accountId}/titles/`)
    .then((response) => response.data.titles);
};

export default getAllTitles;
