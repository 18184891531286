import React, { useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { DisplayPromotedApp } from '../../models/crosspromotion';
import {
  useCreateSourceApp,
  useLaunchOrStopPromotedApp,
  usePromotedApps,
  usePromotedFromApps,
  useSourceApps,
} from '../../hooks/api/crosspromotion';
import { getPlatform } from '../../utils/getPlatform';

import ActionsMenu from '../General/Menu/ActionsMenu';
import StatusBadge, { StatusBadgeVariant } from '../General/Badge/StatusBadge';
import Table from '../General/Table';
import OsBadge from '../General/Badge/OsBadge';
import Logo from '../General/Logo';
import ErrorAccount from './ErrorAccount';

function LaunchAndStopActionMenu({
  promotedApp,
}: {
  promotedApp: DisplayPromotedApp;
}) {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { data: appSourcePromoted, isError } = usePromotedFromApps(
    accountId,
    promotedApp.appId
  );

  const { mutateAsync: launchOrStop } = useLaunchOrStopPromotedApp(
    accountId,
    promotedApp.appId
  );

  const isAccessible =
    promotedApp.dataStream &&
    appSourcePromoted &&
    appSourcePromoted.length >= 1;

  const disabledMsg = promotedApp.dataStream
    ? 'You have to edit a cross promotion first'
    : 'You have to activate your data stream';

  if (isError) {
    return null;
  }

  if (promotedApp.promotionStatus) {
    return (
      <Dropdown.Item
        className="ps-4"
        onClick={() =>
          launchOrStop({
            os: getPlatform(promotedApp.os),
            app_store_id: promotedApp.appStoreId,
            bundle_id: promotedApp.bundleId,
            promotion_status: false,
            impression_template_tracking_link:
              promotedApp.impressionTrackingLink,
            click_template_tracking_link: promotedApp.clickTrackingLink,
            tracking_provider: promotedApp.trackingProvider,
            tracking_provider_token: promotedApp.trackingProviderToken,
          })
        }
      >
        Stop cross promotion
      </Dropdown.Item>
    );
  }
  return (
    <OverlayTrigger
      overlay={
        <Tooltip
          className={isAccessible ? 'd-none' : 'd-block'}
          id={`${promotedApp.appStoreId}-tooltip-row`}
        >
          {disabledMsg}
        </Tooltip>
      }
    >
      <div>
        <Dropdown.Item
          disabled={!isAccessible}
          className="ps-4"
          onClick={() =>
            launchOrStop({
              os: getPlatform(promotedApp.os),
              app_store_id: promotedApp.appStoreId,
              bundle_id: promotedApp.bundleId,
              promotion_status: true,
              impression_template_tracking_link:
                promotedApp.impressionTrackingLink,
              click_template_tracking_link: promotedApp.clickTrackingLink,
              tracking_provider_token: promotedApp.trackingProviderToken,
              tracking_provider: promotedApp.trackingProvider,
            })
          }
        >
          Launch cross promotion
        </Dropdown.Item>
      </div>
    </OverlayTrigger>
  );
}

const columnHelper = createColumnHelper<DisplayPromotedApp>();

function PromotedAppsTable() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const {
    data: apps,
    isLoading,
    isError,
    isSuccess,
  } = usePromotedApps(accountId);

  // TODO: sourceApps should have a type
  const { data: sourceApps, isSuccess: isSuccessSourceApps } =
    useSourceApps(accountId);

  const { mutateAsync: createSourceApp } = useCreateSourceApp(accountId);

  const isDone = isSuccess && isSuccessSourceApps;

  // is used to create apps for all available developers' application.
  useEffect(() => {
    if (isDone && apps) {
      apps.forEach((app) => {
        // source apps are equivalent to an app in crossdk
        const sourceApp = sourceApps.find(
          (sa) => sa.appStoreId === app.appStoreId && sa.os === app.os
        );
        if (sourceApp && !sourceApp.appId) {
          createSourceApp({
            name: app.name,
            app_store_id: app.appStoreId,
            bundle_id: app.bundleId,
            os: getPlatform(app.os),
          });
        }
      });
    }
  }, [apps, sourceApps, isDone, createSourceApp]);

  const getStatusColor = (statusText: string) => {
    switch (statusText) {
      case 'activated':
      case 'promoted':
        return StatusBadgeVariant.SUCCESS;
      case 'not promoted':
      case 'not activated':
        return StatusBadgeVariant.DANGER;
      default:
        return StatusBadgeVariant.DANGER;
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'App Name',
        cell: (info) => (
          <div className="d-flex align-items-center">
            <Logo title={info.getValue()} url={info.row.original.logoUrl} />

            <div className="d-flex flex-column">
              <OverlayTrigger
                delay={300}
                overlay={<Tooltip id="title-name">{info.getValue()}</Tooltip>}
              >
                <Link
                  className="text-underline-hover"
                  data-testid="edit-xpromo"
                  to={`/account/${accountId}/promotedAppSettings/${info.row.original.appStoreId}`}
                >
                  <h4 className="app-name">{info.getValue()}</h4>
                </Link>
              </OverlayTrigger>

              <span className="badge-container">
                <OsBadge os={info.row.original.os} bg="transparent" />
              </span>
            </div>
          </div>
        ),
      }),
      columnHelper.accessor(
        (row) => (row.dataStream ? 'activated' : 'not activated'),
        {
          id: 'dataStream',
          header: 'Data Stream',
          cell: (info) => (
            <StatusBadge
              text={info.getValue()}
              variant={getStatusColor(info.getValue())}
            />
          ),
          enableGlobalFilter: false,
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.promotionStatus && row.dataStream ? 'promoted' : 'not promoted',
        {
          id: 'promoted',
          header: 'Cross Promotion',
          cell: (info) => (
            <StatusBadge
              text={info.getValue()}
              variant={getStatusColor(info.getValue())}
            />
          ),
          enableGlobalFilter: false,
        }
      ),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <div className="text-end">
            <ActionsMenu title="actions">
              <LaunchAndStopActionMenu promotedApp={info.row.original} />
            </ActionsMenu>
          </div>
        ),
        enableGlobalFilter: false,
      }),
    ],
    [accountId]
  );

  const initialState = useMemo(
    () => ({
      sorting: [
        {
          id: 'promoted',
          desc: true,
        },
        {
          id: 'dataStream',
          desc: false,
        },
        {
          id: 'name',
          desc: false,
        },
      ],
    }),
    []
  );

  const table = useReactTable({
    data: apps ?? [],
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <Card className="promoted-apps">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <h6>Overview</h6>
          <h3>Promoted Apps</h3>
        </div>
        <div>
          {!isError && !(isSuccess && apps.length === 0) && (
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search an app name..."
                value={table.getState().globalFilter ?? ''}
                onChange={(e) => table.setGlobalFilter(e.target.value)}
              />
              <InputGroup.Text className="bg-primary text-white">
                <FontAwesomeIcon icon={solid('search')} />
              </InputGroup.Text>
            </InputGroup>
          )}
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        {isError || (isSuccess && apps.length === 0) ? (
          <ErrorAccount hasNoApplication={apps.length === 0} dataEntity="app" />
        ) : (
          <Table
            table={table}
            emptyDataText="There are no results that match your search"
            rowTestPrefix="app"
            rowTestKey="appStoreId"
            className="table-generic"
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default PromotedAppsTable;
