export const LOAD_LIST = 'title/loadList';
export const LOAD_LIST_SUCCESS = 'title/loadListSuccess';
export const LOAD_LIST_ERROR = 'title/loadListError';
export const ADD_REQUESTED = 'title/addRequested';
export const ADD_SUCCESS = 'title/addSuccess';
export const ADD_ERROR = 'title/addError';
export const DELETE_REQUESTED = 'title/deleteRequested';
export const DELETE_SUCCESS = 'title/deleteSuccess';
export const DELETE_ERROR = 'title/deleteError';
export const ADD_APPLICATION = 'title/addApplication';
export const ADD_APPLICATION_ERROR = 'title/addApplicationError';
export const ADD_APPLICATION_SUCCESS = 'title/addApplicationSuccess';
