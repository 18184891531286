import React, { useEffect, useMemo } from 'react';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { useEditUser } from '../../hooks/api/users';
import useUserSession from '../../hooks/useUserSession';

import { UserModelQuery, InputUser } from '../../models/users';
import { Roles } from '../../utils/types';
import Loader from '../General/Loader/Loader';
import UsersFields from './UsersFields';

type UserEditModalProps = {
  accountId: string;
  user: UserModelQuery;
};

const UserEditModal = NiceModal.create<UserEditModalProps>(
  ({ user, accountId }) => {
    const modal = useModal();

    const sessionUser = useUserSession();

    const disableRolePick = useMemo(
      () => user?.id === sessionUser.sub,
      [sessionUser, user]
    );

    const updateUser = useEditUser(accountId, user.id);

    const methods = useForm({
      defaultValues: user,
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

    const { handleSubmit, setValue } = methods;

    const handleValidation = (data: InputUser) =>
      updateUser
        .mutateAsync({
          ...data,
          email: user!.email,
          role: disableRolePick ? user!.role : data.role,
        })
        .then(modal.remove);

    useEffect(() => {
      if (user) {
        setValue('firstName', user.firstName);
        setValue('lastName', user.lastName);
        setValue('email', user.email);
        setValue('role', user.role as Roles);
      }
    }, [setValue, user]);

    return (
      <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
        {updateUser.isLoading && <Loader />}
        <Modal.Header closeButton>
          <h1>User information</h1>
        </Modal.Header>
        <Modal.Body>
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(handleValidation)} id="form-user">
              <Row>
                <Col md={6} className="mb-4">
                  <UsersFields.UserFirstName />
                </Col>
                <Col md={6} className="mb-4">
                  <UsersFields.UserLastName />
                </Col>
                <Col md={6} className="mb-3">
                  <UsersFields.UserEmail disabled />
                </Col>
                <Col md={6} className="mb-3">
                  <UsersFields.UserRole disabled={disableRolePick} />
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" form="form-user">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default UserEditModal;
