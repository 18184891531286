import type {
  APICreateTestingDeviceData,
  APITestingDevice,
} from '../../models/crosspromotion';
import type { APIMember } from '../../models/api';
import { crossDKApiInstance } from '../index';

export const createTestingDevice = (
  accountId: string,
  data: APICreateTestingDeviceData
): Promise<APIMember<APITestingDevice>> =>
  crossDKApiInstance
    .post(`/accounts/${accountId}/testing_devices`, data)
    .then((response) => response.data);

export default createTestingDevice;
