import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { memo, useCallback } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { APICreativeSet } from '../../../../../models/creativeSets';
import Table from '../../../../General/Table';
import Badge from '../../../../General/Badge/Badge';
import {
  APICreative,
  APICreativeStatusEnum,
} from '../../../../../models/creative';
import StatusBadge, {
  StatusBadgeVariant,
} from '../../../../General/Badge/StatusBadge';
import CreativesColumnsActionsMenu from './ActionsMenu';
import { assetFormatsLabels } from '../../assetFormatsLabels';
import { useEditCreative } from '../../../../../hooks/api/creative/useCreative.edit';

const ActivePopover = (
  <Popover>
    <Popover.Header as="h4">Active</Popover.Header>
    <Popover.Body className="pb-0">
      <p className="fw-light">This creative is active</p>
    </Popover.Body>
  </Popover>
);

const InActivePopover = (
  <Popover>
    <Popover.Header as="h4">Inactive</Popover.Header>
    <Popover.Body className="pb-0">
      <p className="fw-light p-0">This creative is inactive</p>
    </Popover.Body>
  </Popover>
);

const columnHelper = createColumnHelper<APICreative>();

type CreativesTableProps = {
  creativeSet: APICreativeSet;
  accountId: string;
};

function CreativesTable(props: CreativesTableProps) {
  const { creativeSet, accountId } = props;

  const { mutateAsync: updateCreativeMutation } = useEditCreative();

  const updateCreative = useCallback(
    (
      creative: APICreative,
      input: {
        name: string;
        status: APICreativeStatusEnum;
      }
    ) =>
      updateCreativeMutation({
        input,
        creativeId: creative.id,
        accountId,
        appId: creativeSet.app_id,
        creativeSetId: creativeSet.id,
      }),
    [accountId, creativeSet.app_id, creativeSet.id, updateCreativeMutation]
  );

  const table = useReactTable({
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false,
        },
        {
          id: 'name',
          desc: false,
        },
      ],
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: true,
    data: creativeSet.creatives,
    columns: [
      columnHelper.accessor('name', {
        header: 'Creative name',
        cell: (info) => <div className="text-start">{info.getValue()}</div>,
      }),
      columnHelper.accessor('id', {
        header: 'Creative id',
        meta: { hasFixedWidth: true },
        cell: (info) => (
          <Badge className="fw-light px-3">{info.getValue()}</Badge>
        ),
      }),

      columnHelper.accessor('orientation', {
        header: 'Orientation',
        cell: (info) => (
          <span className="text-capitalize">{info.getValue()}</span>
        ),
      }),

      columnHelper.accessor('format', {
        header: 'Format',
        cell: (info) => <span>{assetFormatsLabels[info.getValue()]}</span>,
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        meta: { hasFixedWidth: true },
        cell: (info) => {
          const isActive = info.getValue() === APICreativeStatusEnum.active;
          return (
            <OverlayTrigger
              placement="left"
              delay={200}
              overlay={isActive ? ActivePopover : InActivePopover}
            >
              <Badge>
                <StatusBadge
                  text={isActive ? 'active' : 'inactive'}
                  variant={
                    isActive
                      ? StatusBadgeVariant.SUCCESS
                      : StatusBadgeVariant.DANGER
                  }
                />
              </Badge>
            </OverlayTrigger>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const creative = info.row.original;
          return (
            <div className="text-end">
              <CreativesColumnsActionsMenu
                updateCreative={updateCreative}
                creative={creative}
              />
            </div>
          );
        },
        enableSorting: false,
        enableGlobalFilter: false,
      }),
    ],
  });

  return <Table table={table} className="table-generic" />;
}

export default memo(CreativesTable);
