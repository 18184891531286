import React, { useCallback, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import {
  Card,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NiceModal from '@ebay/nice-modal-react';
import { DisplaySourceApp } from '../../models/crosspromotion';
import {
  useCreateSourceApp,
  usePromotedApps,
  useSourceApps,
} from '../../hooks/api/crosspromotion';
import { getPlatform } from '../../utils/getPlatform';
import StatusBadge, { StatusBadgeVariant } from '../General/Badge/StatusBadge';
import Table from '../General/Table';
import OsBadge from '../General/Badge/OsBadge';
import Logo from '../General/Logo';
import ErrorAccount from '../CrossPromotion/ErrorAccount';
import useRestricted from '../../hooks/permission/useRestricted';
import { Roles } from '../../utils/types';
import Notif from '../../utils/notification';
import { useEditApiAccess } from '../../hooks/api/api-access/useApiAccess.edit';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import ActionsMenu from '../General/Menu/ActionsMenu';
import { useGetAllChurnScoresInfos } from '../../hooks/api/churn-score/churnScoreInfo.getAll';
import { ChurnScoreInfos } from '../../api/churn-score/churnScoreInfo.getAll';
import { DownloadChurnScoreCell } from './DownloadChurnScoreCell';

export type ChurnApiApp = DisplaySourceApp & {
  churnScoreInfos?: ChurnScoreInfos;
};

const getStatusColor = (statusText: 'activated' | 'not activated') => {
  switch (statusText) {
    case 'activated':
      return StatusBadgeVariant.SUCCESS;
    case 'not activated':
      return StatusBadgeVariant.DANGER;
    default:
      return StatusBadgeVariant.DANGER;
  }
};

const columnHelper = createColumnHelper<ChurnApiApp>();

const initialState = {
  sorting: [
    {
      id: 'dataStream',
      desc: false,
    },
    {
      id: 'name',
      desc: false,
    },
  ],
};

export function ChurnApiAppsTable() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const {
    data: apps,
    isLoading: isLoadingPromotedApps,
    isError,
    isSuccess,
  } = usePromotedApps(accountId);

  const { data: churnScoresInfos, isLoading: isLoadingChurnsScoresInfos } =
    useGetAllChurnScoresInfos();

  const {
    data: sourceApps,
    isSuccess: isSuccessSourceApps,
    isLoading: isLoadingSourceApps,
  } = useSourceApps(accountId);

  const data = useMemo(
    () =>
      sourceApps.map((sourceApp) => ({
        ...sourceApp,
        churnScoreInfos: churnScoresInfos?.find(
          ({ app_store_id }) => app_store_id === sourceApp.appStoreId
        ),
      })),
    [churnScoresInfos, sourceApps]
  );

  const { mutateAsync: createSourceApp } = useCreateSourceApp(accountId);

  const isDone = isSuccess && isSuccessSourceApps;

  // is used to create apps for all available developers' application.
  useEffect(() => {
    if (isDone && apps) {
      apps.forEach((app) => {
        // source apps are equivalent to an app in crossdk
        const sourceApp = sourceApps.find(
          (sa) => sa.appStoreId === app.appStoreId && sa.os === app.os
        );
        if (sourceApp && !sourceApp.appId) {
          createSourceApp({
            name: app.name,
            app_store_id: app.appStoreId,
            bundle_id: app.bundleId,
            os: getPlatform(app.os),
          });
        }
      });
    }
  }, [apps, sourceApps, isDone, createSourceApp]);

  const { mutateAsync: editApiAccess } = useEditApiAccess();

  const isAdikteevAdmin = useRestricted([Roles.ADIKTEEV_ADMIN]);

  const handleClickOnDeactivateChurnApi = useCallback(
    (appId: string) =>
      NiceModal.show(ConfirmationModal, {
        header: <h1>Deactivate Churn API</h1>,
        body: 'Are you sure you want to deactivate Churn API ?',
        confirmText: 'Deactivate',
        onConfirm: () =>
          editApiAccess({
            accountId,
            appId,
            input: { allow_api_access: false },
          })
            .then(() => {
              NiceModal.hide(ConfirmationModal);
              Notif(
                'Deactivate Churn API',
                'Churn API is deactivated',
                'success'
              );
            })
            .catch(() => {
              Notif(
                'Deactivate Churn API',
                'Error while deactivating Churn API',
                'danger'
              );
            }),
      }),
    [accountId, editApiAccess]
  );

  const handleClickOnActivateChurnApi = useCallback(
    (appId: string) =>
      NiceModal.show(ConfirmationModal, {
        header: <h1>Activate Churn API</h1>,
        body: 'Are you sure you want to activate Churn API ?',
        confirmText: 'Activate',
        onConfirm: () =>
          editApiAccess({
            accountId,
            appId,
            input: { allow_api_access: true },
          })
            .then(() => {
              NiceModal.hide(ConfirmationModal);
              Notif('Activate Churn API', 'Churn API is activated', 'success');
            })
            .catch(() => {
              Notif(
                'Activate Churn API',
                'Error while activating Churn API',
                'danger'
              );
            }),
      }),
    [accountId, editApiAccess]
  );

  const isAppsDataFetched = useMemo(
    () =>
      !isLoadingChurnsScoresInfos &&
      !isLoadingPromotedApps &&
      !isLoadingSourceApps &&
      churnScoresInfos &&
      sourceApps &&
      apps,
    [
      apps,
      churnScoresInfos,
      isLoadingChurnsScoresInfos,
      isLoadingPromotedApps,
      isLoadingSourceApps,
      sourceApps,
    ]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'App Name',
        cell: (info) => (
          <div className="d-flex align-items-center">
            <Logo title={info.getValue()} url={info.row.original.logoUrl} />

            <div className="d-flex flex-column">
              <OverlayTrigger
                delay={300}
                overlay={<Tooltip id="title-name">{info.getValue()}</Tooltip>}
              >
                <h4 className="app-name">{info.getValue()}</h4>
              </OverlayTrigger>

              <span className="badge-container">
                <OsBadge os={info.row.original.os} bg="transparent" />
              </span>
            </div>
          </div>
        ),
      }),
      columnHelper.accessor(
        (row) => (row.dataStream ? 'activated' : 'not activated'),
        {
          id: 'dataStream',
          header: 'Data Stream',
          cell: (info) => (
            <StatusBadge
              text={info.getValue()}
              variant={getStatusColor(info.getValue())}
            />
          ),
          enableGlobalFilter: false,
        }
      ),
      columnHelper.accessor(
        (row) => (row.allowApiAccess ? 'activated' : 'not activated'),
        {
          id: 'allowApiAccess',
          header: 'Churn api status',
          cell: (info) => (
            <StatusBadge
              text={info.getValue()}
              variant={getStatusColor(info.getValue())}
            />
          ),
          enableGlobalFilter: false,
        }
      ),
      columnHelper.display({
        id: 'download',
        cell: DownloadChurnScoreCell,
        header: 'Download',
      }),
      columnHelper.display({
        id: 'churnScoreInfos',
        cell: ({
          row: {
            original: { churnScoreInfos },
          },
        }) => {
          return churnScoreInfos?.last_update
            ? new Date(churnScoreInfos.last_update).toDateString()
            : 'No file';
        },
        header: 'Last file update',
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <div className="text-end">
            <ActionsMenu title="actions">
              {isAdikteevAdmin && info.row.original.allowApiAccess && (
                <Dropdown.Item
                  className="ps-4"
                  disabled={!info.row.original.dataStream}
                  onClick={() =>
                    handleClickOnDeactivateChurnApi(info.row.original.appId!)
                  }
                  alt="Deactivate Churn API"
                >
                  Deactivate Churn API
                </Dropdown.Item>
              )}
              {isAdikteevAdmin && !info.row.original.allowApiAccess && (
                <Dropdown.Item
                  className="ps-4"
                  disabled={!info.row.original.dataStream}
                  onClick={() =>
                    handleClickOnActivateChurnApi(info.row.original.appId!)
                  }
                  alt="Activate Churn API"
                >
                  Activate Churn API
                </Dropdown.Item>
              )}
            </ActionsMenu>
          </div>
        ),
      }),
    ],
    [
      handleClickOnActivateChurnApi,
      handleClickOnDeactivateChurnApi,
      isAdikteevAdmin,
    ]
  );

  const table = useReactTable({
    data: data ?? [],
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (!isAppsDataFetched) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <Card className="promoted-apps">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <h3>Apps</h3>
        </div>
        <div>
          {!isError && !(isAppsDataFetched && apps.length === 0) && (
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search an app name..."
                value={table.getState().globalFilter ?? ''}
                onChange={(e) => table.setGlobalFilter(e.target.value)}
              />
              <InputGroup.Text className="bg-primary text-white">
                <FontAwesomeIcon icon={solid('search')} />
              </InputGroup.Text>
            </InputGroup>
          )}
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        {isError || (isAppsDataFetched && apps.length === 0) ? (
          <ErrorAccount hasNoApplication={apps.length === 0} dataEntity="app" />
        ) : (
          <Table
            table={table}
            emptyDataText="There are no results that match your search"
            rowTestPrefix="app"
            rowTestKey="appStoreId"
            className="table-generic"
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default ChurnApiAppsTable;
