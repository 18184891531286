import { Orientation } from '../../../utils/types';
import type { APIAsset } from '../../../models/asset';
import { APICreative } from '../../../models/creative';

/**
 * A creative is created for each orientation.
 * So up to 4 creatives in all.
 * But only two assets (portrait and landscape).
 * This function returns only the two assets.
 * @param creatives
 */
export const getAssetsFromCreatives = (creatives: APICreative[]) =>
  [
    creatives.find(({ asset }) => asset.orientation === Orientation.landscape)
      ?.asset,
    creatives.find(({ asset }) => asset.orientation === Orientation.portrait)
      ?.asset,
  ].filter(Boolean) as APIAsset[];

/**
 * Retrieve the landscape_asset_id and portrait_asset_id fields sent for the creation of the lives
 * @param creatives
 */
export const getAssetIds = (creatives: APICreative[]) => {
  const firstLandscapeAsset =
    creatives.find(
      ({ asset }) => asset.orientation === Orientation.landscape
    ) ?? null;
  const firstPortraitAsset =
    creatives.find(({ asset }) => asset.orientation === Orientation.portrait) ??
    null;

  return {
    landscape_asset_id: firstLandscapeAsset?.id,
    portrait_asset_id: firstPortraitAsset?.id,
  };
};

/**
 * Retrieve the lives struct sent for the creation of the lives
 * @param creatives
 */
export const getLivesFromCreatives = (creatives: APICreative[]) => {
  return creatives
    .filter((creative) => creative.status === 'live')
    .map(({ asset, format }) => {
      const { type, orientation } = asset;
      return { type, orientation, format };
    });
};
