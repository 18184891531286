import type {
  APISourceAppResponse,
  InputSourceAppWithName,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

export const createSourceApp = async (
  account_id: string,
  input: InputSourceAppWithName
): Promise<APISourceAppResponse> => {
  const { data } = await crossDKApiInstance.post<APISourceAppResponse>(
    `/accounts/${account_id}/apps/`,
    input
  );
  return data;
};

export default createSourceApp;
