import React, { useState } from 'react';

import Contexts from '../../predikteev/contexts';

export type SidebarProviderProps = {
  children: React.ReactNode;
};

function SidebarProvider({ children }: SidebarProviderProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Contexts.SidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </Contexts.SidebarContext.Provider>
  );
}

export default SidebarProvider;
