import type { APICollection } from '../../models/api';
import type { APITestingDevice } from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

export const getAllTestingDevices = (
  accountId: string
): Promise<APICollection<APITestingDevice>> =>
  crossDKApiInstance
    .get(`/accounts/${accountId}/testing_devices`)
    .then((response) => response.data);

export default getAllTestingDevices;
