import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Page from '../../layout/Page';
import RequireFeature from '../../components/Permission/RequireFeature';
import { FeatureName } from '../../utils/types';
import UserNavigation from '../../layout/UserNavigation';
import UpdateCreativeSetForm from '../../components/CrossPromotion/CreativeSet/UpdateForm/EditForm';
import { getAllCreativeSets } from '../../api/creative-set/creativeSet.getAll';

function EditCreativeSetPage() {
  const { accountId, creativeSetId } = useParams<{
    accountId: string;
    creativeSetId: string;
  }>();

  const { data: creativeSetToUpdate, isLoading } = useQuery(
    [accountId, 'creatives-sets', creativeSetId],
    () => getAllCreativeSets(accountId),
    {
      select: (creativesSet) =>
        creativesSet.find((creativeSet) => creativeSet.id === creativeSetId),
    }
  );

  return (
    <Page
      title="Creatives Management - Edit a creative set"
      userNavigation={
        <UserNavigation
          title={<h2>CREATIVE MANAGEMENT - Edit a creative set</h2>}
          arrowBack
        />
      }
    >
      {isLoading && (
        <Container className="justify-content-center d-flex">
          <Spinner animation="border" />
        </Container>
      )}
      {!isLoading && !creativeSetToUpdate && (
        <p className="error">No creative set found for this id</p>
      )}
      {!isLoading && creativeSetToUpdate && (
        <UpdateCreativeSetForm
          creativeSetToUpdate={creativeSetToUpdate}
          accountId={accountId}
        />
      )}
    </Page>
  );
}

export default RequireFeature(
  EditCreativeSetPage,
  FeatureName.CROSS_PROMOTION_ACTIVATION
);
