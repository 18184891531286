import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import NiceModal from '@ebay/nice-modal-react';

import AccountsTable from '../../components/Account/AccountsTable';
import CreateAccount from '../../components/Account/CreateAccount';
import EditAccount from '../../components/Account/EditAccount';
import Sidebar from '../../components/NavBar/Sidebar';
import Page from '../../layout/Page';
import UserNavigation from '../../layout/UserNavigation';

function AccountsPage() {
  return (
    <Page
      title="Accounts Management"
      leftContent={<Sidebar />}
      userNavigation={
        <UserNavigation
          title={<h2>ACCOUNTS MANAGEMENT</h2>}
          hideUsersManagement
          actionButton={
            <Button
              variant="outline-secondary"
              onClick={() => NiceModal.show(CreateAccount)}
              title="add new account"
              name="add-new-account"
              aria-label="add new account"
            >
              <FontAwesomeIcon icon={solid('plus-circle')} className="pe-1" />
              Add a new account
            </Button>
          }
        />
      }
    >
      <AccountsTable
        onEdit={(selectedAccountId: string) => {
          NiceModal.show(EditAccount, { accountId: selectedAccountId });
        }}
      />
    </Page>
  );
}

export default AccountsPage;
