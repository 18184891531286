import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AccountModelQuery } from '../../models/accounts';
import Notif from '../../utils/notification';
import { RequestErrorType } from '../../utils/types';
import { getAllAccounts } from '../../api/account/account.getAll';
import {
  createAccount,
  CreateAccountParams,
} from '../../api/account/account.create';
import {
  getAccount,
  GetAccountParams,
} from '../../api/account/account.get.duplicate';
import { editAccount, EditAccountParams } from '../../api/account/account.edit';

export function useCreateAccount() {
  const queryClient = useQueryClient();

  return useMutation((params: CreateAccountParams) => createAccount(params), {
    onError: (requestError: RequestErrorType) => {
      if (requestError) {
        const message = requestError?.error?.message ?? requestError;
        Notif('Create account', message, 'danger');
        return;
      }
      Notif('Add account', 'Error while adding account (API)', 'danger');
    },
    onSuccess: () => {
      Notif('Adding account', 'You have a new account added !', 'success');
      return queryClient.invalidateQueries('accounts');
    },
  });
}

export function useEditAccount() {
  const queryClient = useQueryClient();

  return useMutation((params: EditAccountParams) => editAccount(params), {
    onError: (requestError: RequestErrorType) => {
      const message =
        requestError?.error?.message ?? 'Error while updating account (API)';
      Notif('Update account', message, 'danger');
    },
    onSuccess: () => {
      Notif('Update account', 'You have updated the account !', 'success');
      return queryClient.invalidateQueries('accounts');
    },
  });
}

export function useGetAccounts() {
  return useQuery<AccountModelQuery[], Error>(['accounts'], () =>
    getAllAccounts()
  );
}

export const useAccountById = ({ accountId }: GetAccountParams) => {
  return useQuery<AccountModelQuery, Error>(['account-nav', accountId], () =>
    getAccount({ accountId })
  );
};

export default {
  useAccountById,
  useGetAccounts,
  useCreateAccount,
  useEditAccount,
};
