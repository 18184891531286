import { predikteevApiInstance } from '../index';

export const deleteUser = async (
  accountId: string,
  userId: string
): Promise<string> => {
  return predikteevApiInstance.delete(`/accounts/${accountId}/users/${userId}`);
};

export default deleteUser;
