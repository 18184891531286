import type { APIAsset } from './asset';
import { APIAssetFormatEnum } from './asset';
import { Orientation } from '../utils/types';

export enum APICreativeStatusEnum {
  active = 'live',
  inactive = 'paused',
}

export type APICreative = {
  asset: APIAsset;
  created_at: string;
  format: APIAssetFormatEnum;
  id: string;
  name: string;
  status: APICreativeStatusEnum;
  updated_at: string;
  orientation: Orientation;
};
