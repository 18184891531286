import React from 'react';
import type { FieldValues } from 'react-hook-form';
import { useController, UseControllerProps } from 'react-hook-form';
import Select, { GetOptionLabel } from 'react-select';
import SelectPromotedAppCustomOption from './SelectPromotedAppCustomOption';
import { getSelectDefaultStyles } from '../getSelectDefaultStyle';
import { usePromotedApps } from '../../hooks/api/crosspromotion';
import type { DisplayPromotedApp } from '../../models/crosspromotion';

const components = {
  Option: SelectPromotedAppCustomOption,
};

const getOptionLabel: GetOptionLabel<DisplayPromotedApp> = (option) =>
  option.name;

type SelectPromotedAppProps<FormValues extends FieldValues> = {
  accountId: string;
} & UseControllerProps<FormValues>;

export function SelectPromotedApp<FormValues extends FieldValues>(
  props: SelectPromotedAppProps<FormValues>
) {
  const { accountId, ...controllerProps } = props;

  const { field, fieldState } = useController(controllerProps);

  const { data: promotedApps, isLoading: isPromotedAppsLoading } =
    usePromotedApps(accountId);

  const options = promotedApps?.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Select
      {...field}
      options={options}
      getOptionValue={(option) => option}
      getOptionLabel={getOptionLabel}
      components={components}
      isClearable
      placeholder={
        isPromotedAppsLoading
          ? 'Loading...'
          : 'Select the app you want to assign'
      }
      isSearchable
      styles={getSelectDefaultStyles(fieldState)}
    />
  );
}

export default SelectPromotedApp;
