import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';

import { useDeleteUser } from '../../hooks/api/users';
import Loader from '../General/Loader/Loader';

interface UserDeleteModalProps {
  userId: string;
  accountId: string;
}

const UserDeleteModal = NiceModal.create<UserDeleteModalProps>(
  ({ userId, accountId }: UserDeleteModalProps) => {
    const modal = useModal();

    const { mutateAsync: deleteUser, isLoading } = useDeleteUser(
      accountId,
      userId
    );

    const handleValidation = () => deleteUser().then(modal.remove);

    return (
      <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
        {isLoading && <Loader />}
        <Modal.Header closeButton>
          <h1>Delete User</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <p>Are you sure you want to delete this user ?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" onClick={handleValidation}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default UserDeleteModal;
