import { useMutation, useQueryClient } from 'react-query';

import {
  APIEditCreativeSetPayload,
  editCreativeSet,
} from '../../../api/creative-set/creativeSet.edit';

export const useEditCreativeSet = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: APIEditCreativeSetPayload) => editCreativeSet(payload),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['creatives-sets']);
      },
    }
  );
};

export default useEditCreativeSet;
