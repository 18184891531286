import { Popover } from 'react-bootstrap';
import React from 'react';

const GAIDPopover = (
  <Popover>
    <Popover.Header as="h4">GAID</Popover.Header>
    <Popover.Body>
      <p className="fw-light">
        GAID is a unique, user-resettable ID for advertising, provided by Google
        Play services. If you have trouble finding a device GAID, please contact
        your admin.
      </p>
      <p className="fs-5 mb-0">GAID example:</p>
      <p className="fw-light fs-5">ddb33ac4-4616-4aad-b514-d06619357bd8</p>
    </Popover.Body>
  </Popover>
);

export default GAIDPopover;
