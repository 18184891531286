import { crossDKApiInstance } from '../index';
import type { APICreativeSet } from '../../models/creativeSets';
import type { APICreateCreativeSetPayload } from './creativeSet.create';

export type APIEditCreativeSetPayload = APICreateCreativeSetPayload & {
  creativeSetId: APICreativeSet['id'];
};

type APIEditCreativeSetResponse = { creative_set: APICreativeSet };

export const editCreativeSet = async (payload: APIEditCreativeSetPayload) => {
  const { accountId, appId, creativeSetId, input } = payload;

  return crossDKApiInstance
    .put<APIEditCreativeSetResponse>(
      `/accounts/${accountId}/apps/${appId}/creatives_sets/${creativeSetId}`,
      input
    )
    .then((response) => response.data.creative_set);
};

export default editCreativeSet;
