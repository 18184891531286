import React, { memo, useCallback, useState } from 'react';
import { Button, Col, Form, Row, Stack } from 'react-bootstrap';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import urlPattern from '../../../../utils/patterns/url';
import adjustTokenPattern from '../../../../utils/patterns/adjustToken';
import { OS } from '../../../../utils/types';
import appsFlyerTrackingTemplates from './appsFlyer/appsFlyerTrackingTemplates';
import adjustTrackingTemplates from './adjust/adjustTrackingTemplates';
import type { APIPromotedApp } from '../../../../models/crosspromotion';
import { TrackingProviders } from '../../../../models/crosspromotion';

export type TrackerUrlsFormValues = {
  clickTemplateTrackingLink: string;
  impressionTemplateTrackingLink: string;
  trackingProvider: APIPromotedApp['tracking_provider'];
  trackingProviderToken: string;
};

type TrackerUrlsFormsProps = {
  os: OS;
};

function TrackerUrlsForm(props: TrackerUrlsFormsProps) {
  const { os } = props;

  const isIos = os === OS.IOS;

  const { register, setValue, control, getValues, trigger } =
    useFormContext<TrackerUrlsFormValues>();

  const { errors } = useFormState<TrackerUrlsFormValues>();

  const setTrackingLinksTemplates = useCallback(
    ({ click, impression }: { click: string; impression: string }) => {
      setValue('clickTemplateTrackingLink', click);
      setValue('impressionTemplateTrackingLink', impression);
    },
    [setValue]
  );

  const setAppsFlyerOrOtherTemplates = useCallback(() => {
    const trackingProvider = getValues('trackingProvider');

    const isAppsFlyer = trackingProvider === TrackingProviders.appsflyer;

    const { clickTemplate, impressionTemplate } =
      appsFlyerTrackingTemplates(isIos);

    setTrackingLinksTemplates({
      click: isAppsFlyer ? clickTemplate : '',
      impression: isAppsFlyer ? impressionTemplate : '',
    });
  }, [setTrackingLinksTemplates, isIos, getValues]);

  const [
    trackingLinksIsCreatedWithTrackingProviderToken,
    setTrackingLinksIsCreatedWithTrackingProviderToken,
  ] = useState(false);

  const setAdjustTemplates = useCallback(async () => {
    const isTrackingProviderTokenValid = await trigger('trackingProviderToken');

    if (!isTrackingProviderTokenValid) return;

    const trackingProviderToken = getValues('trackingProviderToken');
    const currentClickTemplate = getValues('clickTemplateTrackingLink');
    const currentImpressionTemplate = getValues(
      'impressionTemplateTrackingLink'
    );

    const { updateClickTemplate, updateImpressionTemplate } =
      adjustTrackingTemplates({ isIos, adjustToken: trackingProviderToken });

    setTrackingLinksTemplates({
      click: updateClickTemplate(currentClickTemplate),
      impression: updateImpressionTemplate(currentImpressionTemplate),
    });

    setTrackingLinksIsCreatedWithTrackingProviderToken(true);
  }, [trigger, getValues, isIos, setTrackingLinksTemplates]);

  const trackingProviderTokenWatched = useWatch<TrackerUrlsFormValues>({
    name: 'trackingProviderToken',
    control,
  });

  const trackingProviderWatched = useWatch({
    name: 'trackingProvider',
    control,
  });

  const isTrackingProviderAdjust =
    trackingProviderWatched === TrackingProviders.adjust;

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>MMP</Form.Label>
        {isTrackingProviderAdjust && (
          <p>
            To easily generate your attribution URLs, please add the tracker
            token provided by Adjust. If you need help finding it,{' '}
            <a
              className="fw-bold"
              rel="noreferrer"
              href="https://help.adjust.com/en/article/tracker-urls"
              target="_blank"
            >
              click here.
            </a>
          </p>
        )}
        <Row className="align-items-baseline">
          <Col xs={12} lg="auto" className="pe-0 mb-2 mb-xl-0">
            <Form.Select
              {...register('trackingProvider', {
                required: true,
                onChange: setAppsFlyerOrOtherTemplates,
              })}
              className="form-inputs-tracking-provider-width"
            >
              <option value={TrackingProviders.other}>Other</option>
              <option value={TrackingProviders.adjust}>Adjust</option>
              <option value={TrackingProviders.appsflyer}>Appsflyer</option>
            </Form.Select>
          </Col>
          <Col xs={12} lg="auto">
            <Stack
              gap={2}
              className={`align-items-baseline ${
                !isTrackingProviderAdjust ? 'd-none' : undefined
              }`}
              direction="horizontal"
            >
              <Form.Group className="form-inputs-tracking-provider-width">
                <Form.Control
                  type="text"
                  placeholder="Add tracker token"
                  isInvalid={!!errors.trackingProviderToken}
                  {...register('trackingProviderToken', {
                    pattern: {
                      value: adjustTokenPattern,
                      message:
                        'Invalid tracker id, please enter a 6+ alphanumeric character given in your Adjust dashboard',
                    },
                  })}
                />
                <Form.Control.Feedback
                  className="text-wrap mb-0"
                  as="p"
                  type="invalid"
                >
                  {errors?.trackingProviderToken?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                className="px-4"
                onClick={setAdjustTemplates}
                disabled={!trackingProviderTokenWatched?.length}
                type="button"
              >
                {trackingLinksIsCreatedWithTrackingProviderToken
                  ? 'Update URLs'
                  : 'Generate URLs'}
              </Button>
            </Stack>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Click URL</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          type="url"
          isInvalid={!!errors.clickTemplateTrackingLink}
          {...register('clickTemplateTrackingLink', {
            pattern: {
              value: urlPattern,
              message:
                'Your click tracking URL is not valid, please review reference parameters',
            },
          })}
        />
        <Form.Control.Feedback className="text-wrap mb-0" as="p" type="invalid">
          {errors?.clickTemplateTrackingLink?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Impression URL</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          type="url"
          isInvalid={!!errors.impressionTemplateTrackingLink}
          {...register('impressionTemplateTrackingLink', {
            pattern: {
              value: urlPattern,
              message:
                'Your click impression URL is not valid, please review reference parameters',
            },
          })}
        />
        <Form.Control.Feedback className="text-wrap mb-0" as="p" type="invalid">
          {errors?.impressionTemplateTrackingLink?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

export default memo(TrackerUrlsForm);
