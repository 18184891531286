import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Sidebar from '../components/NavBar/Sidebar';
import Page from '../layout/Page';
import UserNavigation from '../layout/UserNavigation';

function Page404() {
  return (
    <Page
      title="404"
      leftContent={<Sidebar />}
      userNavigation={
        <UserNavigation
          hideUsersManagement
          title={<h2>Page not found</h2>}
          variant="middle-header"
        />
      }
    >
      <Row>
        <Col>
          <h1 className="text-white mb-2">404</h1>
          <h3 className="text-white mb-2">
            Ooops, I think you lost yourself :/
          </h3>
          <p className="text-white small">The page you asked does not exist.</p>
        </Col>
      </Row>
    </Page>
  );
}

export default Page404;
