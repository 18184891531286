import type {
  APIKeySourceAppResponse,
  InputSourceApp,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

// Init an application with its settings and generate a token

export const createAppInit = async (
  account_id: string,
  input: InputSourceApp
): Promise<APIKeySourceAppResponse> => {
  const { data } = await crossDKApiInstance.post<APIKeySourceAppResponse>(
    `/accounts/${account_id}/app_init`,
    input
  );
  return data;
};

export default createAppInit;
