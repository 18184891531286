import { DateTime } from 'luxon';

export const sortDate = (aDate: string, bDate: string) => {
  const aCreatedAt = DateTime.fromISO(aDate);
  const bCreatedAt = DateTime.fromISO(bDate);

  if (aCreatedAt < bCreatedAt) {
    return -1;
  }

  if (aCreatedAt > bCreatedAt) {
    return 1;
  }

  return 0;
};

export const sortString = (a: string, b: string) => a.localeCompare(b);
