import React, { memo, useCallback } from 'react';
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { usePromotedApps } from '../../hooks/api/crosspromotion';
import { DisplayPromotedApp } from '../../models/crosspromotion';
import Table from '../General/Table';
import Logo from '../General/Logo';
import OsBadge from '../General/Badge/OsBadge';
import Loader from '../General/Loader/Loader';
import { getInputPromotedAppFromDisplayPromotedApp } from '../../api/promoted-app/getInputPromotedAppFromDisplayPromotedApp';
import { useEditPromotedApp } from '../../hooks/api/promoted-app/promotedApp.edit';
import Notif from '../../utils/notification';
import { getRowSelection } from '../../utils/getRowSelection';

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

const notifySuccess = () =>
  Notif(`All good !`, 'Your settings has been saved', 'success');

const columnHelper = createColumnHelper<DisplayPromotedApp>();

const columns = [
  columnHelper.accessor('name', {
    header: 'App Name',
    cell: (info) => (
      <div className="d-flex align-items-center">
        <Logo title={info.getValue()} url={info.row.original.logoUrl} />
        <OverlayTrigger
          delay={300}
          overlay={<Tooltip id="title-name">{info.getValue()}</Tooltip>}
        >
          <div className="d-flex flex-column">
            <h4 className="app-name">{info.getValue()}</h4>
            <span className="badge-container">
              <OsBadge os={info.row.original.os} bg="transparent" />
            </span>
          </div>
        </OverlayTrigger>
      </div>
    ),
  }),
  columnHelper.display({
    id: 'switch',
    header: (info) => (
      <div className="switch-header">
        <Form.Switch
          checked={info.table.getIsAllRowsSelected()}
          onChange={info.table.getToggleAllRowsSelectedHandler()}
        />
      </div>
    ),
    cell: (info) => {
      return (
        <Form.Switch
          checked={info.row.getIsSelected()}
          onChange={info.row.getToggleSelectedHandler()}
        />
      );
    },
  }),
];

type Props = {
  accountId: string;
};

function BannersModal({ accountId }: Props) {
  const modal = useModal();

  const { data: promotedApps, isLoading: isLoadingPromotedApps } =
    usePromotedApps(accountId);

  const { mutateAsync: updatePromotedApp, isLoading: isUpdatingPromotedApp } =
    useEditPromotedApp();

  const initialRowSelection = getRowSelection(
    promotedApps,
    (promotedApp) => promotedApp.nativeBannerActivated
  );

  const table = useReactTable({
    data: promotedApps ?? [],
    columns,
    initialState: {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
      rowSelection: initialRowSelection,
    },
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const changeNativeBannerStatus = useCallback(() => {
    const dataIndexesNativeBannerActivated = table
      .getSelectedRowModel()
      .flatRows.map(({ index }) => index);

    // the native banner activation value depends on the table react selection model
    // the service does not allow to update several applications at once
    return Promise.all(
      promotedApps.map((promotedApp, index) =>
        updatePromotedApp({
          accountId,
          appId: promotedApp.appId,
          input: {
            ...getInputPromotedAppFromDisplayPromotedApp(promotedApp),
            native_banner_activated:
              dataIndexesNativeBannerActivated.includes(index),
          },
        })
      )
    )
      .then(() => {
        modal.hide();
        notifySuccess();
      })
      .catch(notifyError);
  }, [accountId, modal, promotedApps, table, updatePromotedApp]);

  return (
    <Modal
      backdrop="static"
      {...bootstrapDialog(modal)}
      scrollable
      contentClassName="banners-modal"
      centered
    >
      {(isLoadingPromotedApps || isUpdatingPromotedApp) && <Loader />}
      <Modal.Header closeButton>
        <h1>Banners</h1>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Table table={table} className="table-generic" />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isLoadingPromotedApps || isUpdatingPromotedApp}
          onClick={changeNativeBannerStatus}
          form="form-adsettings"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default memo(NiceModal.create(BannersModal));
