import React, { useMemo } from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
  OverlayTrigger,
  Popover,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  DisplayPromotedApp,
  DisplaySelectedSourceApp,
} from '../../../models/crosspromotion';
import {
  useDisplaySelectedSourceApps,
  useEditSourceAppSelection,
} from '../../../hooks/api/crosspromotion';
import useRowSelection from '../../../hooks/useRowSelection';
import { getHasSelectedRowsChanged } from '../../../utils/tables';

import IndeterminateCheckbox from '../../General/IndeterminateCheckbox';
import Logo from '../../General/Logo';
import Badge from '../../General/Badge/Badge';
import StatusBadge, {
  StatusBadgeVariant,
} from '../../General/Badge/StatusBadge';
import Table from '../../General/Table';

interface SourceAppsListProps {
  app: DisplayPromotedApp;
}

enum Status {
  Ready = 'Ready to display',
  DataStreamDisable = 'Data Stream deactivated',
  ApiNotGenerated = 'API Key not generated',
}

const getPopoverTexts = (statusText: Status) => {
  switch (statusText) {
    case Status.DataStreamDisable:
      return {
        header: 'Data Stream deactivated',
        body: 'You don’t share this app data with us. Please activate the data stream in your tracking provider dashboard.',
      };
    case Status.ApiNotGenerated:
      return {
        header: 'API Key not generated',
        body: 'Please go to the Ad Delivery settings page to generate an API Key.',
      };
    case Status.Ready:
    default:
      return {
        header: 'Ready to display',
        body: 'This app can be used in cross promotion.',
      };
  }
};

const columnHelper = createColumnHelper<DisplaySelectedSourceApp>();

function SourceAppsList({ app }: SourceAppsListProps) {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { data, isLoading, isError } = useDisplaySelectedSourceApps(
    accountId,
    app.appId,
    app.os
  );

  const { mutateAsync: editSourceApp } = useEditSourceAppSelection(
    accountId,
    app.appId
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        meta: { hasFixedWidth: true },
        id: 'select',
        size: 55,
        enableGlobalFilter: false,
        header: (info) => (
          <IndeterminateCheckbox
            checked={info.table.getIsAllRowsSelected()}
            indeterminate={info.table.getIsSomeRowsSelected()}
            onChange={info.table.getToggleAllRowsSelectedHandler()}
            disabled={data.every((item) => item.grayed)}
          />
        ),
        cell: (info) => (
          <IndeterminateCheckbox
            checked={info.row.getIsSelected()}
            indeterminate={info.row.getIsSomeSelected()}
            onChange={info.row.getToggleSelectedHandler()}
            disabled={!info.row.getCanSelect()}
            checkboxTestId={`checkbox-${info.row.original.appStoreId}`}
          />
        ),
      }),
      columnHelper.accessor('name', {
        header: 'App Name',
        cell: (info) => {
          return (
            <div
              className={classNames('name-cell', {
                'disabled-element': info.row.original.grayed,
              })}
            >
              <Logo title={info.getValue()} url={info.row.original.logoUrl} />
              <OverlayTrigger
                delay={300}
                overlay={<Tooltip id="title-name">{info.getValue()}</Tooltip>}
              >
                <h4 className="text-truncate">
                  &emsp;
                  {info.getValue()}
                </h4>
              </OverlayTrigger>
            </div>
          );
        },
      }),
      columnHelper.accessor(
        (row) => {
          if (!row.dataStream) return Status.DataStreamDisable;
          if (!row.apiKeyToken) return Status.ApiNotGenerated;
          return Status.Ready;
        },
        {
          meta: { hasFixedWidth: true },
          size: 350,
          enableGlobalFilter: false,
          header: 'Status',
          cell: (info) => {
            return (
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="left"
                overlay={
                  <Popover>
                    <Popover.Header>
                      {getPopoverTexts(info.getValue()).header}
                    </Popover.Header>
                    <Popover.Body className="fw-light">
                      {getPopoverTexts(info.getValue()).body}
                    </Popover.Body>
                  </Popover>
                }
              >
                <Badge>
                  <StatusBadge
                    text={info.getValue()}
                    variant={
                      info.getValue() === Status.Ready
                        ? StatusBadgeVariant.SUCCESS
                        : StatusBadgeVariant.DANGER
                    }
                  />
                </Badge>
              </OverlayTrigger>
            );
          },
        }
      ),
      columnHelper.accessor((row) => (row.grayed ? 1 : -1), {
        id: 'grayed',
        enableGlobalFilter: false,
      }),
    ],
    [data]
  );

  const initialState = useMemo(
    () => ({
      sorting: [
        {
          id: 'grayed',
          desc: false,
        },
        { id: 'name', desc: false },
      ],
      columnVisibility: { grayed: false }, // used for sort only
    }),
    []
  );

  const table = useReactTable({
    data,
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: (row) => !row.original.grayed,
  });

  // When data changes, we set the "initial" rowSelection in React-table
  useRowSelection({
    data,
    setRowSelection: table.setRowSelection,
    selectKey: 'selected',
  });

  const handleSave = () => {
    const appsToUpdate = data.flatMap((appData, index) => {
      if (!appData.appId || appData.grayed) {
        return [];
      }
      return {
        id: appData.appId,
        promotion_activated: table.getState().rowSelection[index],
      };
    });

    editSourceApp({
      source_apps: appsToUpdate,
    });
  };

  // Disable Save Button if there is no changes on the selected rows
  const hasSelectedRowsChanged = getHasSelectedRowsChanged({
    rows: table.getRowModel().rows,
    originalKey: 'selected',
    rowSelection: table.getState().rowSelection,
  });

  return (
    <Card className="source-apps-list">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <h6>Source Apps</h6>
          <h3>Where this app can be promoted</h3>
        </div>
        <div className="d-flex gap-3">
          {!isError && (
            <>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Search an app name..."
                  value={table.getState().globalFilter ?? ''}
                  onChange={(e) => table.setGlobalFilter(e.target.value)}
                />
                <InputGroup.Text className="bg-primary text-white">
                  <FontAwesomeIcon icon={solid('search')} />
                </InputGroup.Text>
              </InputGroup>
              <Button
                data-testid="source-appslist-form-btn-save"
                variant="primary"
                type="submit"
                disabled={!hasSelectedRowsChanged}
                onClick={handleSave}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </Card.Header>
      <Card.Body className="pt-0 px-0">
        {isLoading && <Spinner animation="border" role="status" />}
        {isError ? (
          <p className="text-danger">Error on loading source apps</p>
        ) : (
          <div className="table-container">
            <Table
              table={table}
              emptyDataText="No result"
              rowTestPrefix="app"
              rowTestKey="appStoreId"
              className="table-generic"
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default SourceAppsList;
