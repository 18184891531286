import React from 'react';
import { Badge as BootstrapBadge } from 'react-bootstrap';
import { Color } from 'react-bootstrap/esm/types';

interface BadgeProps {
  text?: Color;
  children?: React.ReactNode;
  bg?: string;
  className?: string;
}

// This component is used inside an Overlaytrigger, which needs to pass refs
const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  ({ bg, text, className, children, ...props }: BadgeProps, forwardedRef) => (
    <BootstrapBadge
      pill
      ref={forwardedRef}
      bg={bg ?? 'body'}
      text={text ?? 'dark'}
      className={className}
      {...props}
    >
      {children}
    </BootstrapBadge>
  )
);

export default Badge;
