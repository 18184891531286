import { useQuery, UseQueryOptions } from 'react-query';

import { getAllLanguages } from '../../../api/languages/languages.getAll';
import type { APILanguage } from '../../../models/language';
import type { APIError } from '../../../models/api';

export const useGetAllLanguages = <T = APILanguage[]>(
  options?: UseQueryOptions<APILanguage[], APIError, T>
) =>
  useQuery({ queryKey: ['languages'], queryFn: getAllLanguages, ...options });

export default useGetAllLanguages;
