import { createReducer } from 'deox';
import uniq from 'lodash/uniq';
import {
  addApplicationOnTitleSuccess,
  addTitleSuccess,
  deleteTitleSuccess,
  loadTitlesSuccess,
} from '../../../actions/titles';

const initialState: string[] = [];

const allIds = createReducer(initialState, (handleAction) => [
  handleAction(loadTitlesSuccess, (_, { payload }) => [
    ...payload.result.applications,
  ]),

  handleAction(addTitleSuccess, (state, { payload }) => [
    ...state,
    ...payload.result.applications,
  ]),

  handleAction(addApplicationOnTitleSuccess, (state, { payload }) =>
    uniq([...state, ...Object.keys(payload.entities.applications)])
  ),

  handleAction(deleteTitleSuccess, (state, { payload }) =>
    state.filter(
      (application) => !payload.applicationsIds.includes(application)
    )
  ),
]);

export const getIds = (state: string[]) => state;

export default allIds;
