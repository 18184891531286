import { AccountFormModel, AccountModelQuery } from '../../models/accounts';
import { accountToFormData } from './account.create';
import { predikteevApiInstance } from '../index';
import { GetAccountParams } from './account.get.duplicate';

export type EditAccountParams = GetAccountParams & {
  data: AccountFormModel;
};
export const editAccount = ({
  data,
  accountId,
}: EditAccountParams): Promise<AccountModelQuery> => {
  return new Promise((resolve, reject) => {
    const formData = accountToFormData(data);
    return predikteevApiInstance
      .put(`accounts/${accountId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        }
      });
  });
};

export default editAccount;
