import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import Badge from './Badge';
import { Platform } from '../../../utils/types';

interface PlatformBadgeProps {
  platform: Platform;
  bg?: string;
  className?: string;
}

function PlatformBadge({ platform, bg, className }: PlatformBadgeProps) {
  const isIosPlatform = platform === Platform.IOS;
  const icon = isIosPlatform ? brands('apple') : brands('android');
  const label = isIosPlatform ? 'iOS' : 'Android';

  return (
    <Badge className={classNames('os-badge', className)} bg={bg}>
      <FontAwesomeIcon icon={icon} className="os-icon" />
      <span className="os-title">{label}</span>
    </Badge>
  );
}

export default memo(PlatformBadge);
