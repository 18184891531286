import type { AccountModelQuery } from '../../models/accounts';
import { predikteevApiInstance } from '../index';

export type AccountIdType = string | null;

export interface GetAccountParams {
  accountId: AccountIdType;
}

export const getAccount = ({
  accountId,
}: GetAccountParams): Promise<AccountModelQuery> =>
  predikteevApiInstance
    .get(`accounts/${accountId}`)
    .then((response) => response.data.account);

export default getAccount;
