import React from 'react';
import Page from '../../layout/Page';
import UserNavigation from '../../layout/UserNavigation';
import TestingDevicesTable from '../../components/CrossPromotion/TestingDevices/TestingDevicesTable';

function TestingPage() {
  return (
    <Page
      title="Testing integration"
      userNavigation={<UserNavigation title={<h2>TESTING INTEGRATION</h2>} />}
    >
      <TestingDevicesTable />
    </Page>
  );
}

export default TestingPage;
