import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * Loader : Adds a spinner over its container and blocks all interactions
 * The container needs to be in a relative position !
 */
function Loader() {
  return (
    <div className="overlay-loader">
      <Spinner animation="border" role="status" />
    </div>
  );
}

export default Loader;
