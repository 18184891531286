import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import {
  getCoreRowModel,
  RowSelectionState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import Table from '../../../../General/Table';
import {
  AllCreativesLive,
  allCreativesLives,
} from '../useCreativesLivesSelection';
import { columns } from './Columns';

type Props = {
  rowSelection: RowSelectionState;
  onRowSelectionChange: TableOptions<AllCreativesLive>['onRowSelectionChange'];
  enableRowSelection: TableOptions<AllCreativesLive>['enableRowSelection'];
};

function Step3({
  onRowSelectionChange,
  rowSelection,
  enableRowSelection,
}: Props) {
  const table = useReactTable({
    data: allCreativesLives,
    columns,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection,
    onRowSelectionChange,
    state: {
      rowSelection,
    },
  });

  return (
    <Card className="mb-4">
      <Card.Header>
        <h6>Step 3</h6>
        <h3>Select the creatives you wish to go live</h3>
      </Card.Header>
      <Card.Body className="p-0">
        <Table
          table={table}
          className="table-generic creative-management-creatives-list"
        />
      </Card.Body>
    </Card>
  );
}

export default memo(Step3);
