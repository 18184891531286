import React, { memo, useCallback } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import type { DisplayPromotedApp } from '../../../../models/crosspromotion';
import TrackerUrlsForm, { TrackerUrlsFormValues } from './TrackerUrlsForm';
import defaultValues from './form/defaultValues';
import { useEditPromotedAppTrackingLinks } from '../../../../hooks/api/crosspromotion';
import { getPlatform } from '../../../../utils/getPlatform';
import getErrorMessage from './form/getErrorMessage';
import getErrorFieldName from './form/getErrorFieldName';

type TrackerUrlsCardProps = {
  app: DisplayPromotedApp;
};

function TrackerUrlsCard(props: TrackerUrlsCardProps) {
  const { app } = props;

  const {
    clickTrackingLink,
    impressionTrackingLink,
    os,
    appStoreId,
    promotionStatus,
    bundleId,
    appId,
    trackingProvider,
  } = app;

  const { accountId } = useParams<{
    accountId: string;
  }>();

  const methods = useForm<TrackerUrlsFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      clickTemplateTrackingLink:
        clickTrackingLink ?? defaultValues.clickTemplateTrackingLink,
      impressionTemplateTrackingLink:
        impressionTrackingLink ?? defaultValues.impressionTemplateTrackingLink,
      trackingProvider,
    },
  });

  const { handleSubmit, setError } = methods;

  const { mutateAsync: updatePromotedAppTrackingLinks, isLoading } =
    useEditPromotedAppTrackingLinks(accountId, appId);

  const onValid = useCallback(
    (values: TrackerUrlsFormValues) => {
      return updatePromotedAppTrackingLinks({
        os: getPlatform(os),
        app_store_id: appStoreId,
        bundle_id: bundleId,
        promotion_status: promotionStatus,
        // Empty strings (default text input value) aren't accepted by the API
        // We use null value instead
        click_template_tracking_link: values.clickTemplateTrackingLink || null,
        tracking_provider: values.trackingProvider,
        tracking_provider_token: values.trackingProviderToken || null,
        impression_template_tracking_link:
          values.impressionTemplateTrackingLink || null,
      }).catch((apiError) => {
        apiError?.response?.data?.errors?.forEach((error) => {
          setError(getErrorFieldName(error), {
            type: 'manual',
            message: getErrorMessage(error),
          });
        });
      });
    },
    [
      appStoreId,
      bundleId,
      os,
      promotionStatus,
      setError,
      updatePromotedAppTrackingLinks,
    ]
  );

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center ">
        <h3>Attribution URLs</h3>
        <Button
          className="px-4"
          form="tracker-urls-form"
          variant="primary"
          type="submit"
          disabled={isLoading}
        >
          Save
        </Button>
      </Card.Header>
      <Card.Body className="pt-0">
        <h5>
          If you need to add or remove parameters, learn all about it{' '}
          <a
            className="fw-bold"
            target="_blank"
            rel="noreferrer"
            href="https://help.adikteev.com/hc/en-us/articles/8186579420562-Step-2-Launch-a-cross-promotion-campaign-for-your-app-to-promote"
          >
            here
          </a>
        </h5>
        <div className="mt-3">
          <FormProvider {...methods}>
            <Form
              noValidate
              id="tracker-urls-form"
              onSubmit={handleSubmit(onValid)}
              className="mt-3"
            >
              <TrackerUrlsForm os={os} />
            </Form>
          </FormProvider>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(TrackerUrlsCard);
