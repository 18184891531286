import { useMutation, useQueryClient } from 'react-query';
import {
  APIEditApiAccessPayload,
  editApiAccess,
} from '../../../api/api-access/apiAccess.edit';

export const useEditApiAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: APIEditApiAccessPayload) => editApiAccess(payload),
    {
      onSuccess: (response, { accountId, appId }) => {
        return Promise.all([
          queryClient.invalidateQueries([accountId, 'sourceApps']),
          queryClient.invalidateQueries([accountId, appId, 'api-access']),
        ]);
      },
    }
  );
};

export default useEditApiAccess;
