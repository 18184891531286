import type { AxiosError } from 'axios';

import getErrorName from './getErrorName';

const getErrorMessage = (error: AxiosError) => {
  switch (error.code) {
    case 'macro_invalid':
      return `Your ${getErrorName(
        error
      )} tracking URL is not valid, please review reference parameters`;
    case 'app_require_click_template_tracking_link':
    case 'app_require_impression_template_tracking_link':
      return 'Both fields are required';
    case 'app_impression_template_tracking_link_value_err':
    case 'app_click_template_tracking_link_value_err':
    default:
      return 'This is not a valid URL';
  }
};

export default getErrorMessage;
