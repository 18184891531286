import React from 'react';
import { Switch, Redirect, RouteComponentProps, Route } from 'react-router-dom';

import Logout from './Logout';
import LoginComponent from './Login';

function AuthPage({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route path={`${match.url}/login`} component={LoginComponent} />

      <Route
        path={`${match.url}/logout`}
        render={(props) => <Logout redirectTo="/" {...props} />}
      />

      <Redirect from="*" to="/page-not-found" />
    </Switch>
  );
}

export default AuthPage;
