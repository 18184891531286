import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useFormState } from 'react-hook-form';
import accountFormRules from '../../utils/form/accountFormRules';
import CustomForm from '../General/Forms';
import InputLogo from './InputLogo';
import { PlanModel } from '../../models/plans';

function AccountName() {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formAccountName">
      <Form.Label className="mb-2 fw-bold is-required">Account name</Form.Label>
      <Form.Control
        {...register('name', { ...accountFormRules.name })}
        placeholder="Account name"
        isInvalid={!!errors.name}
      />
      <CustomForm.Error field="name" />
    </Form.Group>
  );
}

function AccountLogo() {
  return (
    <Form.Group controlId="formAccountLogo">
      <Form.Label className="mb-2 fw-bold">Logo</Form.Label>
      <InputLogo />
    </Form.Group>
  );
}

function AccountEmail() {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formAccountEmail">
      <Form.Label className="mb-2 fw-bold is-required">
        Contact email
      </Form.Label>
      <Form.Control
        autoComplete="nope"
        {...register('email', { ...accountFormRules.email })}
        placeholder="Contact email"
        isInvalid={!!errors.email}
      />
      <CustomForm.Error field="email" />
    </Form.Group>
  );
}

function AccountAndroidDeveloper() {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formAccountAndroidDeveloper">
      <Form.Label className="mb-2 fw-bold is-required">
        Android Developer
      </Form.Label>
      <Form.Control
        autoComplete="nope"
        {...register('androidDeveloper', {
          ...accountFormRules.androidDeveloper,
        })}
        placeholder="Android Developer"
        isInvalid={!!errors.androidDeveloper}
      />
      <CustomForm.Error field="androidDeveloper" />
    </Form.Group>
  );
}

function AccountIosDeveloper() {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formAccountIosDeveloper">
      <Form.Label className="mb-2 fw-bold is-required">
        iOS Developer
      </Form.Label>
      <Form.Control
        autoComplete="nope"
        {...register('iosDeveloper', { ...accountFormRules.iosDeveloper })}
        placeholder="iOS Developer"
        isInvalid={!!errors.iosDeveloper}
      />
      <CustomForm.Error field="iosDeveloper" />
    </Form.Group>
  );
}

type AccountPlanProps = {
  plans?: PlanModel[];
};

function AccountPlan({ plans }: AccountPlanProps) {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formAccountPlan">
      <Form.Label className="mb-2 fw-bold is-required">Plan</Form.Label>
      <Form.Select
        {...register('planId', { ...accountFormRules.plan })}
        isInvalid={!!errors.planId}
      >
        <option value="">Choose a plan</option>
        {plans?.map(({ name, id }) => (
          <option key={`plan-${id}`} value={id}>
            {name}
          </option>
        ))}
      </Form.Select>
      <CustomForm.Error field="planId" />
    </Form.Group>
  );
}

export default {
  AccountName,
  AccountLogo,
  AccountEmail,
  AccountIosDeveloper,
  AccountAndroidDeveloper,
  AccountPlan,
};
