import type {
  APIPromotedApp,
  FormattedAPIPromotedApp,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';
import { OS } from '../../utils/types';

export const getAllPromotedApps = async (
  account_id: string
): Promise<FormattedAPIPromotedApp[]> => {
  const {
    data: { apps },
  } = await crossDKApiInstance.get<{ apps: APIPromotedApp[] }>(
    `/accounts/${account_id}/apps`
  );
  return apps.map((app) => ({
    ...app,
    os: app.os === 'android' ? OS.Android : OS.IOS,
  }));
};

export default getAllPromotedApps;
