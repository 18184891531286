import React, { useCallback } from 'react';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import NiceModal from '@ebay/nice-modal-react';
import { useParams } from 'react-router-dom';

import StatusBadge from '../General/Badge/StatusBadge';
import Badge from '../General/Badge/Badge';
import BannersModal from './BannersModal';

import bannerIllustration from '../../assets/img/banner-illustration.png';
import { usePromotedApps } from '../../hooks/api/crosspromotion';

function Banners() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const openBannerModal = useCallback(() => {
    NiceModal.show(BannersModal, { accountId });
  }, [accountId]);

  const { data: promotedApps } = usePromotedApps(accountId);

  const appsWithNativeBannerActivated = promotedApps.filter(
    ({ nativeBannerActivated }) => nativeBannerActivated
  ).length;

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div>
          <h6>Manage your creatives</h6>
          <h3>Banners</h3>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <Badge>
            <StatusBadge
              text={`active on ${appsWithNativeBannerActivated} out of ${promotedApps.length} apps`}
            />
          </Badge>
          <Button onClick={openBannerModal}>Activate/Deactivate</Button>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row className="align-items-center gap-3">
          <Col className="pe-0">
            <Image src={bannerIllustration} alt="Banner Example" fluid />
          </Col>
          <Col xs={12} sm={6} md={8} xl={9} className="ps-0">
            <p className="mb-0">
              Banners are native formats that don’t require any assets or
              further customisation. A banner will show the app icon, their name
              and a brief description as well as a CTA. If you want to activate
              or deactivate the banner format for one or all of your apps, you
              can use the button on the top right of this card.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Banners;
