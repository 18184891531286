import React from 'react';
import Page from '../../layout/Page';
import { FeatureName } from '../../utils/types';
import RequireFeature from '../../components/Permission/RequireFeature';
import SourceAppsTable from '../../components/CrossPromotion/SourceAppsTable';
import UserNavigation from '../../layout/UserNavigation';

function DeliverySettingsPage() {
  return (
    <Page
      title="Ad Delivery Settings"
      userNavigation={<UserNavigation title={<h2>AD DELIVERY SETTINGS</h2>} />}
    >
      <SourceAppsTable />
    </Page>
  );
}

export default RequireFeature(
  DeliverySettingsPage,
  FeatureName.CROSS_PROMOTION_ACTIVATION
);
