import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { GranularityPercentage, NbIntervals } from '../../../utils/types';

export const Granularity: {
  key: GranularityPercentage;
  value: NbIntervals;
}[] = [
  { key: GranularityPercentage.ONE, value: NbIntervals.HUNDRED },
  { key: GranularityPercentage.FIVE, value: NbIntervals.TWENTY },
  { key: GranularityPercentage.TEN, value: NbIntervals.TEN },
  { key: GranularityPercentage.TWENTY_FIVE, value: NbIntervals.FOUR },
  { key: GranularityPercentage.THIRTY_THREE, value: NbIntervals.THREE },
];

export type FormSelectGranularityProps = {
  defaultValue: number;
};

export default function FormSelectGranularity({
  defaultValue,
}: FormSelectGranularityProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name="granularity"
      rules={{
        required: true,
      }}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, ref, onChange } }) => {
        const convertValueInNumberBeforeOnChange = (
          event: React.ChangeEvent<HTMLSelectElement>
        ) =>
          onChange({
            ...event,
            target: { ...event.target, value: +event.target.value },
          });

        return (
          <Form.Select
            className="segmentation-select-large"
            ref={ref}
            onChange={convertValueInNumberBeforeOnChange}
            value={value}
          >
            {Granularity.map(({ key, value: v }) => (
              <option key={`granularity-${key}`} value={v}>
                {key}%
              </option>
            ))}
          </Form.Select>
        );
      }}
    />
  );
}
