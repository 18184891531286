import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import { OS } from '../../../utils/types';

interface FormSelectOsProps {
  defaultValue: OS;
  disableOs?: OS[];
}

export default function FormSelectOs({
  defaultValue,
  disableOs = [],
}: FormSelectOsProps) {
  const { control, setValue, trigger } = useFormContext();
  const isIosDisabled = disableOs.includes(OS.IOS);
  const isAndroidDisabled = disableOs.includes(OS.Android);

  const handleOSChange = (platform: OS) => {
    if (
      (platform === OS.IOS && !isIosDisabled) ||
      (platform === OS.Android && !isAndroidDisabled)
    ) {
      setValue('os', platform);
    }
  };

  useEffect(() => {
    trigger('os');
  }, [trigger]);

  return (
    <Controller
      name="os"
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value } }) => {
        return (
          <div className="app-platforms">
            <div
              className={classNames({
                'text-black-50 rounded-circle me-2 avatar': true,
                disabled: isIosDisabled,
                selected: value === OS.IOS && !isIosDisabled,
              })}
              data-os={OS.IOS}
              onClick={() => handleOSChange(OS.IOS)}
              aria-hidden="true"
            >
              <FontAwesomeIcon icon={brands('apple')} />
            </div>
            <div
              aria-hidden="true"
              className={classNames({
                'avatar rounded-circle me-2 text-success': true,
                disabled: isAndroidDisabled,
                selected: value === OS.Android && !isAndroidDisabled,
              })}
              onClick={() => handleOSChange(OS.Android)}
              data-os={OS.Android}
            >
              <FontAwesomeIcon icon={brands('android')} />
            </div>
          </div>
        );
      }}
    />
  );
}
