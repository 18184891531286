import React from 'react';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, Row, Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { useCreateUser } from '../../hooks/api/users';
import { InputUser } from '../../models/users';
import Loader from '../General/Loader/Loader';
import UsersFields from './UsersFields';

type Props = {
  accountId: string;
};

const UserCreateModal = NiceModal.create<Props>(({ accountId }) => {
  const modal = useModal();

  const { mutateAsync, isLoading } = useCreateUser(accountId);
  const methods = useForm<InputUser>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { handleSubmit } = methods;

  const handleValidation = (data: InputUser) =>
    mutateAsync(data).then(modal.remove);

  return (
    <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
      {isLoading && <Loader />}
      <Modal.Header closeButton>
        <h1>User information</h1>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(handleValidation)} id="form-user">
            <Row>
              <Col md={6} className="mb-4">
                <UsersFields.UserFirstName />
              </Col>
              <Col md={6} className="mb-4">
                <UsersFields.UserLastName />
              </Col>
              <Col md={6} className="mb-3">
                <UsersFields.UserEmail />
              </Col>
              <Col md={6} className="mb-3">
                <UsersFields.UserRole />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" form="form-user">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default UserCreateModal;
