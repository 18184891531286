import { useQuery, UseQueryOptions } from 'react-query';

import {
  ChurnScoreInfos,
  getAllChurnScoresInfos,
} from '../../../api/churn-score/churnScoreInfo.getAll';

const useGetAllChurnScoresInfosQueryKey = () => 'get-all-churn-scores-infos';

export const useGetAllChurnScoresInfos = <T = ChurnScoreInfos[]>(
  opt?: UseQueryOptions<
    ChurnScoreInfos[],
    any,
    T,
    ReturnType<typeof useGetAllChurnScoresInfosQueryKey>
  >
) => useQuery(useGetAllChurnScoresInfosQueryKey(), getAllChurnScoresInfos, opt);

export default useGetAllChurnScoresInfos;
