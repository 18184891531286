import React, { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

function ErrorFallbackWrapper({ children }: PropsWithChildren<{}>) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <>
          <div className="text-danger">An error has occured</div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
        </>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
export default ErrorFallbackWrapper;
