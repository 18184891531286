import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import NiceModal from '@ebay/nice-modal-react';
import {
  Card,
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { DisplaySourceApp } from '../../models/crosspromotion';
import { useSourceApps } from '../../hooks/api/crosspromotion';

import StatusBadge, { StatusBadgeVariant } from '../General/Badge/StatusBadge';
import ActionsMenu from '../General/Menu/ActionsMenu';
import ActionToggle from '../General/Menu/ActionToggle';
import OsBadge from '../General/Badge/OsBadge';
import Logo from '../General/Logo';
import Table from '../General/Table';
import AdSettingsModal from './AdSettingsModal';
import AdSettingsApiKeyModal from './AdSettingsApiKeyModal';
import ErrorAccount from './ErrorAccount';
import useRestricted from '../../hooks/permission/useRestricted';
import { Roles } from '../../utils/types';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import Notif from '../../utils/notification';
import { useEditApiAccess } from '../../hooks/api/api-access/useApiAccess.edit';
import { getSourceAppApiKeyStatus } from './getSourceAppApiKeyStatus';

const columnHelper = createColumnHelper<DisplaySourceApp>();

const initialState = {
  sorting: [
    {
      id: 'dataStream',
      desc: true,
    },
    {
      id: 'apiKeyStatus',
      desc: false,
    },
    {
      id: 'name',
      desc: false,
    },
  ],
  columnVisibility: { dataStream: false }, // used for sort only
};

function SourceAppsTable() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { data, isLoading, isError, isSuccess } = useSourceApps(accountId);

  const { mutateAsync: editApiAccess } = useEditApiAccess();

  const isAdikteevAdmin = useRestricted([Roles.ADIKTEEV_ADMIN]);

  const handleClickOnDeactivateApiKey = useCallback(
    (appId: string) =>
      NiceModal.show(ConfirmationModal, {
        header: <h1>Deactivate API Key</h1>,
        body: 'Are you sure you want to deactivate this API Key ?',
        confirmText: 'Deactivate',
        onConfirm: () =>
          editApiAccess({
            accountId,
            appId,
            input: { allow_api_access: false },
          })
            .then(() => {
              NiceModal.hide(ConfirmationModal);
              Notif('Deactivate API Key', 'API Key is deactivated', 'success');
            })
            .catch(() => {
              Notif(
                'Deactivate API Key',
                'Error while deactivating API Key',
                'danger'
              );
            }),
      }),
    [accountId, editApiAccess]
  );

  const handleClickOnActivateApiKey = useCallback(
    (appId: string) =>
      NiceModal.show(ConfirmationModal, {
        header: <h1>Reactivate API Key</h1>,
        body: 'Are you sure you want to reactivate this API Key ?',
        confirmText: 'Reactivate',
        onConfirm: () =>
          editApiAccess({
            accountId,
            appId,
            input: { allow_api_access: true },
          })
            .then(() => {
              NiceModal.hide(ConfirmationModal);
              Notif('Reactivate API Key', 'API Key is reactivated', 'success');
            })
            .catch(() => {
              Notif(
                'Reactivate API Key',
                'Error while reactivating API Key',
                'danger'
              );
            }),
      }),
    [accountId, editApiAccess]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'App Name',
        cell: (info) => (
          <div className="d-flex align-items-center">
            <Logo url={info.row.original.logoUrl} title={info.getValue()} />
            <OverlayTrigger
              delay={300}
              overlay={<Tooltip id="title-name">{info.getValue()}</Tooltip>}
            >
              <div>
                <h4 className="app-name">{info.getValue()}</h4>
                <span className="badge-container">
                  <OsBadge os={info.row.original.os} bg="transparent" />
                </span>
              </div>
            </OverlayTrigger>
          </div>
        ),
      }),
      columnHelper.accessor('churn', {
        header: () => (
          <div className="two-lines-header">
            <div>Churn Threshold</div>
            <div>(most likely to churn)</div>
          </div>
        ),
        cell: (info) => {
          const churnValue = info.getValue();
          if (churnValue == null) return null;

          return (
            <div className="fw-bold">{(churnValue * 100).toFixed(0)}%</div>
          );
        },
        enableGlobalFilter: false,
      }),
      columnHelper.accessor('controlGroup', {
        header: 'Control group',
        cell: (info) => {
          const controlGroupValue = info.getValue();
          if (controlGroupValue == null) return null;

          return (
            <Form.Switch checked={controlGroupValue > 0.01} readOnly disabled />
          );
        },
        enableGlobalFilter: false,
      }),
      columnHelper.accessor(getSourceAppApiKeyStatus, {
        id: 'apiKeyStatus',
        header: 'API Key Status',
        cell: (info) => (
          <StatusBadge
            text={info.getValue()}
            variant={
              info.getValue() === 'generated'
                ? StatusBadgeVariant.SUCCESS
                : StatusBadgeVariant.DANGER
            }
          />
        ),
        enableGlobalFilter: false,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <div className="text-end">
            {!info.row.original.dataStream ? (
              <ActionToggle disabled data-testid="actions-disabled" />
            ) : (
              <ActionsMenu title="actions">
                {info.row.original.apiKeyToken ? (
                  <>
                    <Dropdown.Item
                      className="ps-4"
                      onClick={() => {
                        NiceModal.show(AdSettingsModal, {
                          app: info.row.original,
                          accountId,
                        });
                      }}
                      to="#/"
                      alt="settings"
                    >
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="ps-4"
                      onClick={() => {
                        NiceModal.show(AdSettingsApiKeyModal, {
                          app: info.row.original,
                          accountId,
                        });
                      }}
                      alt="See API Key"
                    >
                      See API Key
                    </Dropdown.Item>
                    {isAdikteevAdmin && info.row.original.allowApiAccess && (
                      <Dropdown.Item
                        className="ps-4"
                        onClick={() =>
                          handleClickOnDeactivateApiKey(
                            info.row.original.appId!
                          )
                        }
                        alt="Deactivate API Key"
                      >
                        Deactivate API Key
                      </Dropdown.Item>
                    )}
                    {isAdikteevAdmin && !info.row.original.allowApiAccess && (
                      <Dropdown.Item
                        className="ps-4"
                        onClick={() =>
                          handleClickOnActivateApiKey(info.row.original.appId!)
                        }
                        alt="Reactivate API Key"
                      >
                        Reactivate API Key
                      </Dropdown.Item>
                    )}
                  </>
                ) : (
                  <Dropdown.Item
                    className="ps-4"
                    onClick={() => {
                      NiceModal.show(AdSettingsApiKeyModal, {
                        app: info.row.original,
                        accountId,
                      });
                    }}
                    alt="Generate API Key"
                  >
                    Generate API Key
                  </Dropdown.Item>
                )}
              </ActionsMenu>
            )}
          </div>
        ),
        enableGlobalFilter: false,
      }),
      columnHelper.accessor(
        (row) => (row.dataStream ? 1 : -1), // Sort boolean values (true first, false then)
        {
          id: 'dataStream',
          enableGlobalFilter: false,
        }
      ),
    ],
    [
      accountId,
      handleClickOnActivateApiKey,
      handleClickOnDeactivateApiKey,
      isAdikteevAdmin,
    ]
  );

  const table = useReactTable({
    data,
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <Card className="source-apps">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h3>Source Apps</h3>
        <div>
          {!isError && !(isSuccess && data.length === 0) && (
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search an app name..."
                value={table.getState().globalFilter ?? ''}
                onChange={(e) => table.setGlobalFilter(e.target.value)}
              />
              <InputGroup.Text className="bg-primary text-white">
                <FontAwesomeIcon icon={solid('search')} />
              </InputGroup.Text>
            </InputGroup>
          )}
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        {isError || (isSuccess && data.length === 0) ? (
          <ErrorAccount hasNoApplication={data.length === 0} dataEntity="app" />
        ) : (
          <Table
            table={table}
            emptyDataText="There are no results that match your search"
            disabledRowKey="dataStream"
            rowTestPrefix="app"
            rowTestKey="appStoreId"
            className="table-generic"
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default SourceAppsTable;
