import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import Dropzone from '../../../General/Dropzone';

import {
  getFileTypeError,
  getLandscapeError,
  getPortraitError,
  getSingleFileError,
  getSizeTooBigError,
  getVideoDimensions,
} from '../../../../utils/dropzone';

const acceptOnlyMp4 = { 'video/mp4': [] };

const validateFileTypeAndSize = (files: File[]) =>
  getSingleFileError(files) ||
  getFileTypeError(files[0], ['video/mp4', 'video/h264']) ||
  getSizeTooBigError(files[0], 50 * 1024 * 1024);

const validateLandscape = async (files: File[]) =>
  validateFileTypeAndSize(files) ||
  getLandscapeError(await getVideoDimensions(files[0]));

const validatePortrait = async (files: File[]) =>
  validateFileTypeAndSize(files) ||
  getPortraitError(await getVideoDimensions(files[0]));

type Props = {
  onLandscapeAssetChange?: (asset: File[]) => void;
  onPortraitAssetChange?: (asset: File[]) => void;
};

function Step2({ onLandscapeAssetChange, onPortraitAssetChange }: Props) {
  return (
    <Card className="mb-4">
      <Card.Header>
        <h6>Step 2</h6>
        <h3>Add assets to generate your creatives</h3>
      </Card.Header>
      <Card.Body className="creativeAssetsCard">
        <div>
          <div className="mb-4">
            <h4>Creative Assets</h4>
            Duration: up to 30 seconds / File Format: .mp4, H.264. Maximum video
            size: 50MB
            <br />
            Ratio for landscape: 16:9 / Ratio for portrait: 9:16
          </div>
          <div className="creativeAssets">
            <Dropzone
              onChange={onLandscapeAssetChange}
              accept={acceptOnlyMp4}
              fileType="video"
              validate={validateLandscape}
            >
              <p className="m-0">Upload</p>
              <p className="m-0 mt-n1">
                <strong className="fw-bold">16:9</strong> video
              </p>
            </Dropzone>
            <Dropzone
              onChange={onPortraitAssetChange}
              accept={acceptOnlyMp4}
              fileType="video"
              validate={validatePortrait}
            >
              <p className="m-0">Upload</p>
              <p className="m-0 mt-n1">
                <strong className="fw-bold">9:16</strong> video
              </p>
            </Dropzone>
          </div>
        </div>
        <div className="bg-secondary p-3">
          <h4 className="mb-2">Why add two orientations?</h4>
          By adding 2 assets in different orientations, you will be able to
          create 4 different creatives:
          <ul>
            <li>2 full screen videos (one portrait and one landscape)</li>
            <li>2 half screen videos (one portrait and one landscape)</li>
          </ul>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(Step2);
