import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { usePromotedApps } from '../crosspromotion';
import { combineQueriesStates } from '../../../utils/react-query';
import { getAllCreativeSets } from '../../../api/creative-set/creativeSet.getAll';

export const useGetAllCreativeSets = (accountId: string, appId?: string) => {
  const { data: promotedApps, ...promotedAppsQuery } =
    usePromotedApps(accountId);

  const { data: creativeSets, ...creativeSetsQuery } = useQuery(
    [accountId, 'creatives-sets'],
    () => getAllCreativeSets(accountId, appId)
  );

  const formattedCreativeSets = useMemo(() => {
    if (promotedAppsQuery.isSuccess) {
      return creativeSets?.flatMap((creativeSet) => {
        const correspondingApp = promotedApps?.find(
          (app) => app.appId === creativeSet.app_id
        );

        if (correspondingApp) {
          return {
            id: creativeSet.id,
            name: creativeSet.name,
            language: creativeSet.language,
            app: {
              appId: correspondingApp.appId,
              appStoreId: correspondingApp.appStoreId,
              name: correspondingApp.name,
              logoUrl: correspondingApp.logoUrl,
              os: correspondingApp.os,
            },
            updatedAt: creativeSet.updated_at,
            deletedAt: creativeSet.deleted_at,
            creatives: creativeSet.creatives,
            createdAt: creativeSet.created_at,
          };
        }

        return [];
      });
    }
    return [];
  }, [creativeSets, promotedApps, promotedAppsQuery.isSuccess]);

  return {
    data: formattedCreativeSets,
    ...combineQueriesStates([promotedAppsQuery, creativeSetsQuery]),
  };
};

export default useGetAllCreativeSets;
