import React, { memo } from 'react';

const Copyright = memo(() => {
  const copyright = new Date().getUTCFullYear();
  return (
    <div className="copyright text-center text-xl-start fs-5">
      © {copyright} Cross promotion platform
    </div>
  );
});

export default Copyright;
