import React from 'react';

import type { PlatformData } from '../models/platformData';

import * as CampaignModels from '../models/campaigns';
import * as SegmentationModels from '../models/segmentations';

import * as TitleModels from '../../models/titles';

import { ReportingTabs } from '../../utils/types';

type ReportingContext = {
  dataStream: {
    android: PlatformData | null;
    ios: PlatformData | null;
  };
  applications: TitleModels.NormalizedApplicationModel[];
};

const ReportingPages = React.createContext<ReportingContext>({
  dataStream: {
    android: null,
    ios: null,
  },
  applications: [],
});

type ReportingTabContext = {
  selectedTab: ReportingTabs;
  setSelectedTab: Function;
};

const ReportingTab = React.createContext<ReportingTabContext>({
  selectedTab: ReportingTabs.Acquisition,
  setSelectedTab: () => {},
});

type AcquisitionPageCtx = {
  isAdding: boolean;
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCampaign: CampaignModels.NormalizedCampaignModel | null;
  setSelectedCampaign: React.Dispatch<
    React.SetStateAction<CampaignModels.NormalizedCampaignModel | null>
  >;
};

const AcquisitionPage = React.createContext<AcquisitionPageCtx>({
  isAdding: false,
  setIsAdding: () => {},
  selectedCampaign: null,
  setSelectedCampaign: () => {},
});

type RetentionPageCtx = {
  isAdding: boolean;
  selectedSegmentation: SegmentationModels.NormalizedSegmentationModel | null;
  setSelectedSegmentation: React.Dispatch<
    React.SetStateAction<SegmentationModels.NormalizedSegmentationModel | null>
  >;
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
};

const RetentionPage = React.createContext<RetentionPageCtx>({
  isAdding: true,
  selectedSegmentation: null,
  setSelectedSegmentation: () => {},
  setIsAdding: () => {},
});

type SidebarContextType = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarContext = React.createContext<SidebarContextType>({
  isOpen: false,
  setIsOpen: () => {},
});

export default {
  ReportingPages,
  AcquisitionPage,
  RetentionPage,
  ReportingTab,
  SidebarContext,
};
