import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
  url: `${process.env.REACT_APP_IAM_URL}/auth`,
  realm: 'predikteev',
  clientId: 'ui',
});

export default keycloak;
