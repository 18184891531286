import type {
  APIPromotedAppResponse,
  InputPromotedApp,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

export type APIEditPromotedAppParams = {
  accountId: string;
  appId: string;
  input: InputPromotedApp;
};

export const editPromotedApp = async ({
  accountId,
  appId,
  input,
}: APIEditPromotedAppParams): Promise<APIPromotedAppResponse> => {
  const { data } = await crossDKApiInstance.put<APIPromotedAppResponse>(
    `/accounts/${accountId}/apps/${appId}`,
    input
  );
  return data;
};

export default editPromotedApp;
