import type { TrackerUrlsFormValues } from '../TrackerUrlsForm';
import { TrackingProviders } from '../../../../../models/crosspromotion';

const defaultValues: TrackerUrlsFormValues = {
  clickTemplateTrackingLink: '',
  impressionTemplateTrackingLink: '',
  trackingProvider: TrackingProviders.other,
  trackingProviderToken: '',
};

export default defaultValues;
