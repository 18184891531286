import { useQuery, UseQueryOptions } from 'react-query';
import {
  GetChurnScorePayload,
  getChurnScores,
  GetChurnScoresReturn,
} from '../../../api/churn-score/churnScore.get';

const useGetChurnScoresQueryKey = (payload: GetChurnScorePayload) =>
  `get-churn-scores-${Object.values(payload.params).join('')}`;

export const useGetChurnScores = <T = GetChurnScoresReturn>(
  payload: GetChurnScorePayload,
  opt?: UseQueryOptions<
    GetChurnScoresReturn,
    any,
    T,
    ReturnType<typeof useGetChurnScoresQueryKey>
  >
) =>
  useQuery(
    useGetChurnScoresQueryKey(payload),
    () => getChurnScores(payload),
    opt
  );

export default useGetChurnScores;
