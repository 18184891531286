import { Store as reactNofif } from 'react-notifications-component';

type KindNotif = 'danger' | 'success' | 'warning' | 'info' | 'default';

export default function Notif(
  title: string,
  message: string,
  kind: KindNotif = 'info'
) {
  return reactNofif.addNotification({
    title,
    message,
    type: kind,
    insert: 'bottom',
    container: 'bottom-right',
    dismiss: {
      duration: 3000,
      pauseOnHover: true,
    },
    animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
    animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  });
}
