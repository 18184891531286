import React, { useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { roundUpToTen } from '../../../utils/roundUpToTen';

type TooltipSlider = {
  format?: (value: number) => string;
  offset?: number;
};

type SliderProps = {
  name: string;
  min: number;
  max: number;
  step?: number;
  defaultValue?: number;
  tooltip?: TooltipSlider;
  minLabel?: string;
  maxLabel?: string;
};

function FormSlider({
  name,
  min,
  max,
  step,
  tooltip,
  defaultValue,
  minLabel,
  maxLabel,
}: SliderProps) {
  const { control } = useFormContext();

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [slideRef, setSlideRef] = useState<HTMLDivElement | null>(null);

  const getValueCappedToMin = useCallback(
    (value: number) => (value < min ? min : value),
    [min]
  );

  const getValueCappedToMax = useCallback(
    (value: number) => (value > max ? max : value),
    [max]
  );

  return (
    <Controller
      name={name}
      rules={{
        required: true,
      }}
      control={control}
      defaultValue={defaultValue || min}
      render={({ field: { value, onChange } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
          onChange({
            ...event,
            target: {
              ...event.target,
              value: roundUpToTen(
                getValueCappedToMax(getValueCappedToMin(+event.target.value))
              ),
            },
          });

        const sliderValue = roundUpToTen(
          getValueCappedToMax(getValueCappedToMin(value))
        );

        const factorMovement = slideRef
          ? sliderValue * slideRef.offsetWidth
          : 0;
        const tooltipOffset =
          (tooltipRef.current?.offsetWidth || 0) / 2 + (tooltip?.offset || 0);

        const progress = `${(sliderValue / max) * (max * 100)}%`;

        const styleInput = {
          background: `linear-gradient(90deg, var(--bs-primary) 0% ${progress}, var(--bs-light) ${progress} 100%)`,
        };

        return (
          <div className="slider">
            {tooltip && (
              <div
                className="input-slider-tooltip"
                style={{ left: `${factorMovement - tooltipOffset}px` }}
                ref={tooltipRef}
              >
                {(tooltip.format && tooltip.format(sliderValue)) || sliderValue}
              </div>
            )}
            <input
              ref={setSlideRef}
              type="range"
              aria-label={name}
              className="slider-range"
              max={1}
              min={0}
              step={step || 1}
              value={sliderValue}
              style={styleInput}
              onChange={handleChange}
            />
            {minLabel && <span className="min-label">{minLabel}</span>}
            {maxLabel && <span className="max-label">{maxLabel}</span>}
          </div>
        );
      }}
    />
  );
}

export default FormSlider;
