import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../layout/Page';
import UserNavigation from '../../layout/UserNavigation';
import AccountInformation from '../../components/Account/AccountInformation';
import { ChurnApiAppsTable } from '../../components/ChurnApi/ChurnApiAppsTable';

export function ChurnApiAppsPage() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <Page
      title="Apps Overview"
      userNavigation={
        <UserNavigation title={<h2>APPS OVERVIEW</h2>} variant="long-header">
          <AccountInformation accountId={accountId} />
        </UserNavigation>
      }
    >
      <ChurnApiAppsTable />
    </Page>
  );
}

export default ChurnApiAppsPage;
