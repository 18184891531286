import type {
  APISourceAppListResponse,
  FormatedSourceApp,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

export const getAllSourceApps = (
  account_id: string
): Promise<FormatedSourceApp[]> =>
  crossDKApiInstance
    .get<APISourceAppListResponse>(`accounts/${account_id}/xpromo_settings`)
    .then((response) => {
      const promoSettingsApps: FormatedSourceApp[] =
        response.data?.xpromo_settings?.map((app) => ({
          appId: app.app_id,
          appStoreId: app.app_store_id,
          churn: app.targeted_user_base_percentage,
          controlGroup: app.control_group_percentage,
          apiKeyToken: app.tokens?.length ? app.tokens[0].token : '',
          allowApiAccess: app.allow_api_access,
        }));

      return promoSettingsApps;
    });

export default getAllSourceApps;
