import React, { memo, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import NiceModal from '@ebay/nice-modal-react';
import {
  APICreative,
  APICreativeStatusEnum,
} from '../../../../../models/creative';
import ActionsMenu from '../../../../General/Menu/ActionsMenu';
import Notif from '../../../../../utils/notification';
import EditCreativeNameModal from './EditCreativeNameModal';

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

function Actions(props: {
  creative: APICreative;
  updateCreative: (
    creative: APICreative,
    input: {
      name: string;
      status: APICreativeStatusEnum;
    }
  ) => Promise<APICreative>;
}) {
  const { creative, updateCreative } = props;

  const { status: currentStatus, name } = creative;

  const isActive = currentStatus === APICreativeStatusEnum.active;

  const activate = useCallback(
    () =>
      updateCreative(creative, {
        status: APICreativeStatusEnum.active,
        name,
      })
        .then(() => Notif('Success', 'Your creative is active', 'success'))
        .catch(notifyError),
    [updateCreative, creative, name]
  );

  const deactivate = useCallback(
    () =>
      updateCreative(creative, {
        status: APICreativeStatusEnum.inactive,
        name,
      })
        .then(() => Notif('Success', 'Your creative is inactive', 'success'))
        .catch(notifyError),
    [updateCreative, creative, name]
  );

  const showUpdateNameModal = useCallback(
    () =>
      NiceModal.show(EditCreativeNameModal, {
        creative,
        updateCreative,
      }),
    [creative, updateCreative]
  );

  return (
    <ActionsMenu title="actions">
      <Dropdown.Item onClick={showUpdateNameModal} className="ps-4">
        Edit creative name
      </Dropdown.Item>
      <Dropdown.Item
        onClick={isActive ? deactivate : activate}
        className="ps-4"
      >
        {isActive ? 'Deactivate creative' : 'Activate creative'}
      </Dropdown.Item>
    </ActionsMenu>
  );
}

export default memo(Actions);
