import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';

type FieldErrorMessageProps = {
  field: string;
};

function Error({ field }: FieldErrorMessageProps) {
  const {
    formState: { errors },
  } = useFormContext<Record<typeof field, unknown>>();
  return (
    <Form.Control.Feedback className="position-absolute" as="p" type="invalid">
      {errors?.[field]?.message}
    </Form.Control.Feedback>
  );
}

export default Error;
