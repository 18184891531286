import React, { ButtonHTMLAttributes, forwardRef, Ref } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const ActionToggle = forwardRef(
  (
    props: ButtonHTMLAttributes<HTMLButtonElement>,
    ref: Ref<HTMLButtonElement>
  ) => (
    <button {...props} type="button" className="action-toggle" ref={ref}>
      <FontAwesomeIcon icon={solid('ellipsis-v')} size="sm" />
    </button>
  )
);

export default ActionToggle;
