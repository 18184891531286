import type { APIXDKApp, FormattedXDKApp } from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';
import { OS } from '../../utils/types';

export const getAllPromotedBy = async (
  account_id: string,
  app_id: string
): Promise<FormattedXDKApp[]> => {
  const {
    data: { apps },
  } = await crossDKApiInstance.get<{ apps: APIXDKApp[] }>(
    `accounts/${account_id}/apps/${app_id}/promoted_by`
  );

  return apps.map((app) => ({
    ...app,
    os: app.os === 'android' ? OS.Android : OS.IOS,
  }));
};

export default getAllPromotedBy;
