import { AppStoreQuery } from '../../models/crosspromotion';
import { OS } from '../../utils/types';
import { APIAppStoreIOS } from '../../models/appStores';
import { getAllAppsFromStore } from './appFromStore.getAll';

export const getAllAppsIOS = (
  developerName: string
): Promise<AppStoreQuery[]> => {
  return getAllAppsFromStore(OS.IOS, developerName).then((apps) =>
    apps.map((app: APIAppStoreIOS) => {
      return {
        bundleId: app.bundleId,
        appStoreId: app.app_store_id,
        logoUrl: app.artworkUrl100,
        name: app.trackCensoredName,
        os: OS.IOS,
      };
    })
  );
};

export default getAllAppsIOS;
