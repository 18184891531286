import React from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputSourceAppSettings,
  SourceAppQuery,
} from '../../models/crosspromotion';
import * as AdSettingsFields from './AdSettingsFields';
import { useEditSourceAppSettings } from '../../hooks/api/crosspromotion';
import OsBadge from '../General/Badge/OsBadge';
import Loader from '../General/Loader/Loader';
import Logo from '../General/Logo';

type AdSettingsModalProps = {
  app: SourceAppQuery;
  accountId: string;
};

export type AdSettingsFormValues = InputSourceAppSettings & {
  hasControlGroup: boolean;
};

const AdSettingsModal = NiceModal.create<AdSettingsModalProps>(
  ({ app, accountId }) => {
    const modal = useModal();
    const methods = useForm<AdSettingsFormValues>({
      defaultValues: {
        targeted_user_base_percentage: app.churn || 0.5,
        control_group_percentage: app.controlGroup || 0,
        hasControlGroup: app.controlGroup !== 0,
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

    const { mutateAsync: updateSourceApp, isLoading } =
      useEditSourceAppSettings(accountId, app.appId!);

    const { handleSubmit } = methods;
    const handleValidation = (data: AdSettingsFormValues) => {
      const newData: InputSourceAppSettings = {
        ...data,
        control_group_percentage: data.hasControlGroup
          ? data.control_group_percentage
          : 0,
      };
      updateSourceApp(newData).then(() => modal.remove());
    };

    return (
      <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
        {isLoading && <Loader />}
        <Modal.Header closeButton className="justify-content-start">
          <Logo height={60} url={app.logoUrl} title={app.name} />
          <OverlayTrigger delay={300} overlay={<Tooltip>{app.name}</Tooltip>}>
            <h1 className="text-truncate mx-3">{app.name}</h1>
          </OverlayTrigger>
          <OsBadge os={app.os} className="me-5" />
        </Modal.Header>
        <Modal.Body>
          <h4 className="adsettings-title">Source App Settings</h4>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(handleValidation)}
              id="form-adsettings"
              className="mt-3"
            >
              <Row>
                <Col className="mb-4">
                  <AdSettingsFields.ChurnThreshold />
                </Col>
              </Row>
              <hr className="mt-0" />
              <Row>
                <Col className="text-center border-right">
                  <AdSettingsFields.ControlGroup />
                </Col>
              </Row>
            </form>
          </FormProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            name="cancel"
            onClick={() => modal.hide()}
          >
            Cancel
          </Button>
          <Button type="submit" form="form-adsettings">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default AdSettingsModal;
