import React, { ReactNode, useCallback, useState } from 'react';
import type { match, NavLinkProps } from 'react-router-dom';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitlesNavigation from '../predikteev/components/TitlesNavigation';
import useAllowFeature from '../hooks/permission/useIsAllowed';
import { FeatureName, Roles } from '../utils/types';
import Sidebar from './NavBar/Sidebar';
import crossPromotionIcon from '../assets/img/icon-cross-promotion.svg';
import predictionIcon from '../assets/img/icon-prediction.svg';
import useRestricted from '../hooks/permission/useRestricted';

const isCrossPromotionLinkActive: NavLinkProps['isActive'] = (
  match,
  location
) =>
  !!match ||
  location.pathname.includes('deliverySettings') ||
  location.pathname.includes('creatives') ||
  location.pathname.includes('promotedAppSettings') ||
  location.pathname.includes('testing');

function CrossPromoLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      className="fs-3 fw-bold"
      exact
      title="Cross promotion"
      to={`/account/${accountId}/crosspromotion`}
      isActive={isCrossPromotionLinkActive}
      activeClassName="text-primary"
    >
      <img src={crossPromotionIcon} alt="Cross Promotion Icon" />
      <span className="ps-3">Cross promotion</span>
    </NavLink>
  );
}

function PromotedAppsLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      exact
      title="Promoted apps"
      to={`/account/${accountId}/crosspromotion`}
      isActive={(match, location) =>
        !!match || location.pathname.includes('promotedAppSettings')
      }
      activeClassName="text-dark fw-bold"
    >
      Promoted apps
    </NavLink>
  );
}

function DeliverySettingsLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      exact
      title="Ad Delivery Settings"
      to={`/account/${accountId}/deliverySettings`}
      activeClassName="text-dark fw-bold"
    >
      Ad Delivery Settings
    </NavLink>
  );
}

function CreativesLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      exact
      title="Creatives"
      to={`/account/${accountId}/creatives`}
      isActive={(match, location) =>
        !!match || location.pathname.includes('creatives')
      }
      activeClassName="text-dark fw-bold"
    >
      Creatives
    </NavLink>
  );
}

function PredictionLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      className="fs-3 fw-bold"
      exact
      title="Predictions"
      to={`/account/${accountId}/dashboard`}
      activeClassName="text-primary"
      isActive={(match, location) =>
        !!match ||
        location.pathname.includes('retention') ||
        location.pathname.includes('acquisition')
      }
    >
      <img src={predictionIcon} alt="Prediction Icon" />
      <span className="ps-3">Predictions</span>
    </NavLink>
  );
}

function DashBoardLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      exact
      title="Dashboard"
      to={`/account/${accountId}/dashboard`}
      activeClassName="text-dark fw-bold"
    >
      Dashboard
    </NavLink>
  );
}

function TestingLink(): ReturnType<NavLink> {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  return (
    <NavLink
      exact
      title="Testing"
      to={`/account/${accountId}/testing`}
      activeClassName="text-dark fw-bold"
    >
      Testing
    </NavLink>
  );
}

type MenuCollapseProps = {
  isInitiallyOpen?: boolean;
  children: ReactNode;
  title: string;
};

function MenuCollapse({
  isInitiallyOpen = false,
  children,
  title,
}: MenuCollapseProps) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  const onOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div
        className={`pb-3 pt-3 accordion-title ${
          isOpen ? 'text-dark fw-bold' : 'fw-light'
        }`}
        aria-hidden="true"
        onClick={onOpen}
      >
        <span>{title}</span>
        <span
          className={`accordion-icon offset-8 ${
            isOpen ? 'rotate' : 'opacity-50'
          }`}
        >
          <FontAwesomeIcon icon={solid('angle-right')} />
        </span>
      </div>
      {isOpen && children}
    </>
  );
}

function ChurnApiNavigation() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const location = useLocation();

  const isChurnApiLinkActive = useCallback(
    <T extends { [K in keyof T]?: string | undefined }>(
      match: match<T> | null
    ) => !!match || location.pathname.includes('churn-api'),
    [location]
  );

  return (
    <>
      <NavLink
        className="fs-3 fw-bold"
        exact
        title="Churn API"
        to={`/account/${accountId}/churn-api`}
        isActive={isChurnApiLinkActive}
        activeClassName="text-primary"
      >
        <img src={crossPromotionIcon} alt="Churn api" />
        <span className="ps-3">Churn API</span>
      </NavLink>
      <ul className="list-unstyled">
        <li className="pt-3">
          <NavLink
            exact
            title="Overview apps"
            to={`/account/${accountId}/churn-api`}
            isActive={isChurnApiLinkActive}
            activeClassName="text-dark fw-bold"
          >
            Apps
          </NavLink>
        </li>
      </ul>
    </>
  );
}

function SideNavigation() {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const location = useLocation();

  const {
    isAllowed: hasCrossPromoActivation,
    isLoading: isLoadingHasCrossPromoActivation,
  } = useAllowFeature(accountId, FeatureName.CROSS_PROMOTION_ACTIVATION);

  const { isAllowed: hasChurnApi, isLoading: isLoadingHasChurnApi } =
    useAllowFeature(accountId, FeatureName.CHURN_API);

  const isAdikteevAdmin = useRestricted([Roles.ADIKTEEV_ADMIN]);

  if (isLoadingHasCrossPromoActivation || isLoadingHasChurnApi) {
    return <div data-testid="loading side navigation" />;
  }

  const isAcquisitionLink = location.pathname.includes('acquisition');
  const isRetentionLink = location.pathname.includes('retention');
  const displayPredikteev = isAdikteevAdmin && !hasChurnApi;

  return (
    <Sidebar>
      <nav className="ps-3">
        {hasChurnApi && <ChurnApiNavigation />}
        {hasCrossPromoActivation && (
          <>
            <CrossPromoLink />
            <ul className="list-unstyled">
              <li className="pt-3">
                <PromotedAppsLink />
              </li>
              <li className="pt-3">
                <CreativesLink />
              </li>
              <li className="pt-3">
                <DeliverySettingsLink />
              </li>
              <li className="pt-3">
                <TestingLink />
              </li>
            </ul>
          </>
        )}
        {displayPredikteev && (
          <>
            <PredictionLink />
            <ul className="list-unstyled">
              <li className="pt-3">
                <DashBoardLink />
                <MenuCollapse
                  isInitiallyOpen={isAcquisitionLink || isRetentionLink}
                  title="Apps"
                >
                  <TitlesNavigation />
                </MenuCollapse>
              </li>
            </ul>
          </>
        )}
      </nav>
    </Sidebar>
  );
}

export default SideNavigation;
