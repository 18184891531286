import React from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import IndeterminateCheckbox from '../../../../General/IndeterminateCheckbox';
import type { AllCreativesLive } from '../useCreativesLivesSelection';
import { getAtLeastOneRowIsSelectable } from '../../../../../utils/getAtLeastOneRowIsSelectable';
import { assetFormatsLabels } from '../../assetFormatsLabels';

const columnHelper = createColumnHelper<AllCreativesLive>();

const getDisabledClassName = (row: Row<AllCreativesLive>) =>
  !row.getCanSelect() && 'disabled-element';

export const columns = [
  columnHelper.display({
    meta: { hasFixedWidth: true },
    id: 'select',
    size: 55,
    header: ({ table }) => {
      const atLeastOneRowIsSelectable = getAtLeastOneRowIsSelectable(table);
      return (
        <IndeterminateCheckbox
          {...{
            disabled: !atLeastOneRowIsSelectable,
            checked: atLeastOneRowIsSelectable && table.getIsAllRowsSelected(),
            indeterminate:
              atLeastOneRowIsSelectable && table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      );
    },
    cell: ({ row }) => (
      <IndeterminateCheckbox
        {...{
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
          disabled: !row.getCanSelect(),
        }}
      />
    ),
  }),

  columnHelper.accessor('creative', {
    header: 'Creative',
    cell: (info) => (
      <div className={`text-start ${getDisabledClassName(info.row)}`}>
        {info.getValue()}
      </div>
    ),
  }),
  columnHelper.accessor('format', {
    header: 'Format',
    cell: (info) => (
      <span className={`${getDisabledClassName(info.row)}`}>
        {assetFormatsLabels[info.getValue()]}
      </span>
    ),
  }),

  columnHelper.accessor('type', {
    header: 'Type',
    cell: (info) => (
      <span className={`text-capitalize ${getDisabledClassName(info.row)}`}>
        {info.getValue()}
      </span>
    ),
  }),

  columnHelper.accessor('orientation', {
    header: 'Orientation',
    cell: (info) => (
      <span className={`text-capitalize ${getDisabledClassName(info.row)}`}>
        {info.getValue()}
      </span>
    ),
  }),
];

export default columns;
