const appsFlyerTrackingTemplates = (isIos: boolean) => {
  const deviceIdentifierParam = isIos ? 'idfv' : 'advertising_id';
  const deviceIdentifierValue = isIos ? 'idfv' : 'gaid';
  const prefixPromotedAppId = isIos ? 'id' : '';

  return {
    clickTemplate: `https://app.appsflyer.com/${prefixPromotedAppId}{promoted_app_id}?pid=Adikteev_XPromo&c={source_app_name}&af_siteid={source_app_id}&af_ad_id={creative_id}&af_ad={creative_name}&af_ad_format={creative_format}&af_ad_or={creative_orientation}&${deviceIdentifierParam}={${deviceIdentifierValue}}&country={country_code}&af_click_lookback=30d&af_viewthrough_lookback=24h&clickid={attribution_id}&redirect=false&af_ip={device_ip_address}&af_ua={user_agent}&af_model={device_model}&af_os_version={device_os_version}`,
    impressionTemplate: `https://impression.appsflyer.com/${prefixPromotedAppId}{promoted_app_id}?pid=Adikteev_XPromo&c={source_app_name}&af_siteid={source_app_id}&af_ad_id={creative_id}&af_ad={creative_name}&af_ad_format={creative_format}&af_ad_or={creative_orientation}&${deviceIdentifierParam}={${deviceIdentifierValue}}&country={country_code}&af_click_lookback=30d&af_viewthrough_lookback=24h&clickid={attribution_id}&af_ip={device_ip_address}&af_ua={user_agent}&af_model={device_model}&af_os_version={device_os_version}`,
  };
};

export default appsFlyerTrackingTemplates;
