import React, { memo, PropsWithChildren } from 'react';
import { Orientation } from '../../../utils/types';

function AssetItem({
  children,
  orientation,
}: PropsWithChildren<{ orientation: Orientation }>) {
  return (
    <div className={`asset-item asset-item-${orientation}`}>
      <div className="d-flex position-absolute top-0 h-100 w-100">
        {children}
      </div>
    </div>
  );
}

export default memo(AssetItem);
