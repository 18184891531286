import React from 'react';
import classNames from 'classnames';

export enum StatusBadgeVariant {
  SUCCESS = 'success',
  DANGER = 'danger',
  NEUTRAL = 'akgreylight',
}
interface StatusBadgeProps {
  text?: string;
  variant?: StatusBadgeVariant;
}

function StatusBadge({
  text,
  variant = StatusBadgeVariant.NEUTRAL,
}: StatusBadgeProps) {
  return (
    <div className="status-badge">
      <span
        className={classNames('status-round', {
          success: variant === StatusBadgeVariant.SUCCESS,
          danger: variant === StatusBadgeVariant.DANGER,
          neutral: variant === StatusBadgeVariant.NEUTRAL,
        })}
      >
        &nbsp;
      </span>
      <span className="fw-bold text-primary">{text}</span>
    </div>
  );
}

export default StatusBadge;
