import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import Page from '../../layout/Page';
import { useGetAllCreativeSets } from '../../hooks/api/creative-set/useCreativeSet.getAll';
import RequireFeature from '../../components/Permission/RequireFeature';
import { FeatureName } from '../../utils/types';
import UserNavigation from '../../layout/UserNavigation';
import CreativeSetsTable from '../../components/CrossPromotion/CreativeSetsTable';
import BannersSection from '../../components/CrossPromotion/BannersSection';

function CreativeSetsPage() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { data, isLoading, isError } = useGetAllCreativeSets(accountId);

  return (
    <Page
      title="Creatives Management"
      userNavigation={<UserNavigation title={<h2>CREATIVE MANAGEMENT</h2>} />}
    >
      <div className="mb-4">
        <BannersSection />
      </div>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div>
            <h6>Manage your creatives</h6>
            <h3>Creative Sets</h3>
          </div>
          <Link to={`/account/${accountId}/creatives/add`}>
            <Button>Add a new creative set</Button>
          </Link>
        </Card.Header>
        <Card.Body className="p-0">
          <CreativeSetsTable
            accountId={accountId}
            data={data ?? []}
            isLoading={isLoading}
            isError={isError}
          />
        </Card.Body>
      </Card>
    </Page>
  );
}

export default RequireFeature(
  CreativeSetsPage,
  FeatureName.CROSS_PROMOTION_ACTIVATION
);
