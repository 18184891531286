import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';

import { useResetPasswordUser } from '../../hooks/api/users';

import Loader from '../General/Loader/Loader';

interface UserResetPasswordModalProps {
  userId: string;
  accountId: string;
}

const UserResetPasswordModal = NiceModal.create<UserResetPasswordModalProps>(
  ({ userId, accountId }) => {
    const modal = useModal();

    const { mutateAsync: postResetPasswordUser, isLoading } =
      useResetPasswordUser(accountId, userId);

    const handleValidation = () => postResetPasswordUser().then(modal.remove);

    return (
      <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
        {isLoading && <Loader />}
        <Modal.Header closeButton>
          <h1>Reset Password</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>Are you sure you want to reset this password ?</p>
            <p>
              The user will receive an email with a link to reset his
              credentials.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleValidation}>Reset</Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
export default UserResetPasswordModal;
