import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type ArrowBackProps = {
  variant?: ButtonVariant;
};

function ArrowBack({ variant = 'primary' }: ArrowBackProps) {
  const history = useHistory();

  return (
    <Button
      variant={`outline-${variant}`}
      className="btn-arrow-back"
      onClick={history.goBack}
    >
      <FontAwesomeIcon icon={solid('chevron-left')} />
    </Button>
  );
}

export default ArrowBack;
