import React from 'react';

interface Props {
  fileType: 'video' | 'image';
  preview: string;
}

function Preview({ fileType, preview }: Props) {
  if (fileType === 'video') {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        controls
        data-testid="dropzone-video-preview"
        src={preview}
        className="tag"
        onLoad={() => {
          URL.revokeObjectURL(preview);
        }}
      />
    );
  }

  if (fileType === 'image') {
    return (
      <img
        data-testid="dropzone-image-preview"
        src={preview}
        alt="preview"
        className="tag"
        onLoad={() => {
          URL.revokeObjectURL(preview);
        }}
      />
    );
  }

  return null;
}

export default Preview;
