import React, { memo, useCallback } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import type { DisplayPromotedApp } from '../../../../models/crosspromotion';
import type { APILanguage } from '../../../../models/language';
import Notif from '../../../../utils/notification';

import type { APICreativeSet } from '../../../../models/creativeSets';
import { usePromotedApps } from '../../../../hooks/api/crosspromotion';
import Logo from '../../../General/Logo';
import OsBadge from '../../../General/Badge/OsBadge';
import CustomForm from '../../../General/Forms';
import AssetsGroup from '../../../Assets/AssetsGroup';
import AssetItem from '../../../Assets/AssetItem/AssetItem';
import AssetImage from '../../../Assets/AssetItem/AssetImage';
import AssetVideo from '../../../Assets/AssetItem/AssetVideo';
import { isStaticAsset } from '../isStaticAsset';
import { isVideoAsset } from '../isVideoAsset';
import { getAssetUrl } from '../../../../utils/getAssetUrl';
import {
  getAssetIds,
  getAssetsFromCreatives,
  getLivesFromCreatives,
} from '../getAssetsFromCreatives';
import { Orientation } from '../../../../utils/types';
import { useEditCreativeSet } from '../../../../hooks/api/creative-set/useCreativeSet.edit';
import CreativesTable from './CreativesColumns/CreativesTable';
import { SelectLanguage } from '../../../SelectLanguage/SelectLanguage';

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

const notifySuccess = () =>
  Notif(`All good !`, 'Your creative Set has been updated', 'success');

export type EditCreativeSetFormValues = {
  name: string;
  language: APILanguage;
  app: DisplayPromotedApp;
};

const defaultValues: Partial<EditCreativeSetFormValues> = {
  name: '',
  language: undefined,
  app: undefined,
};

type Props = {
  accountId: string;
  creativeSetToUpdate: APICreativeSet;
};

function EditCreativeSetForm({ accountId, creativeSetToUpdate }: Props) {
  const { data: promotedApps = [], isLoading: isLoadingPromotedApps } =
    usePromotedApps(accountId);

  const promotedAppForCreativeSet = promotedApps.find(
    ({ appId }) => appId === creativeSetToUpdate.app_id
  );

  const assets = getAssetsFromCreatives(creativeSetToUpdate.creatives);

  const hasOnlyPortraitAsset =
    assets.length === 1 && assets[0].orientation === Orientation.portrait;

  const previousValues: EditCreativeSetFormValues = {
    name: creativeSetToUpdate.name,
    language: creativeSetToUpdate.language,
    app: promotedAppForCreativeSet!,
  };

  const methods = useForm<EditCreativeSetFormValues>({
    defaultValues: { ...defaultValues, ...previousValues },
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const { push: historyPush } = useHistory();

  const goToCreativesPage = useCallback(
    () => historyPush(`/account/${accountId}/creatives`),
    [historyPush, accountId]
  );

  const { mutateAsync: updateCreativeSetMutation } = useEditCreativeSet();

  const updateCreativeSet = useCallback(
    async ({ language, name }: EditCreativeSetFormValues) => {
      return updateCreativeSetMutation({
        accountId,
        creativeSetId: creativeSetToUpdate.id,
        appId: creativeSetToUpdate.app_id,
        input: {
          language,
          name,
          landscape_asset_id: getAssetIds(creativeSetToUpdate.creatives)
            .landscape_asset_id,
          portrait_asset_id: getAssetIds(creativeSetToUpdate.creatives)
            .portrait_asset_id,
          lives: getLivesFromCreatives(creativeSetToUpdate.creatives),
        },
      })
        .then(() => {
          goToCreativesPage();
          notifySuccess();
        })
        .catch(notifyError);
    },
    [
      updateCreativeSetMutation,
      accountId,
      creativeSetToUpdate.id,
      creativeSetToUpdate.app_id,
      creativeSetToUpdate.creatives,
      goToCreativesPage,
    ]
  );

  if (isLoadingPromotedApps) {
    return (
      <Container className="justify-content-center d-flex">
        <Spinner animation="border" />
      </Container>
    );
  }

  if (!promotedAppForCreativeSet) {
    return (
      <p className="error">
        You cannot edit a creative group whose application is not promoted
      </p>
    );
  }

  return (
    <div className="creativeManagement">
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(updateCreativeSet)}
          id="creative-management-update-form"
        >
          <Card className="mb-4">
            <Card.Header className="d-flex justify-content-between align-items-center py-4 pb-2">
              <h3>Creative Set</h3>
              <Button
                variant="primary"
                className="creative-set-update-form-save-btn"
                type="submit"
              >
                Save
              </Button>
            </Card.Header>
            <Card.Body className="pt-0 mt-0">
              <Row>
                <Form.Group className="mb-4">
                  <Form.Label className="mb-3">App</Form.Label>
                  <div className="d-flex gap-1 align-items-center">
                    <Logo
                      title="logo"
                      url={promotedAppForCreativeSet!.logoUrl}
                    />
                    <div className="d-flex flex-column">
                      <h4 className="ms-1">
                        {promotedAppForCreativeSet?.name}
                      </h4>
                      <div className="badge-container ">
                        <OsBadge
                          os={promotedAppForCreativeSet!.os}
                          bg="transparent"
                          className="ps-0"
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className="mb-4"
                  as={Col}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={8}
                  xl={5}
                >
                  <Form.Label>Creative Set Name</Form.Label>
                  <Form.Control
                    placeholder="Give a name to your Creative Set"
                    {...register('name', {
                      required:
                        'Missing Creative Set name. This field is required',
                    })}
                    isInvalid={!!errors.name}
                  />
                  <CustomForm.Error field="name" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xs={12} sm={12} md={9} lg={8} xl={5}>
                  <Form.Label>Creative Set Language</Form.Label>
                  <SelectLanguage
                    name="language"
                    control={control}
                    rules={{
                      required:
                        'Missing language name. This field is required.',
                    }}
                  />
                  <p className="error m-0 mt-1">{errors?.language?.message}</p>
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Form>
      </FormProvider>
      <Card className="mb-4">
        <Card.Header>
          <h3>Assets</h3>
        </Card.Header>
        <Card.Body
          className={`creativeAssetsCard ${
            hasOnlyPortraitAsset ? 'onlyPortraitAsset' : ''
          }`}
        >
          <AssetsGroup>
            {assets.map(({ id, type, orientation, url }) => (
              <AssetItem key={id} orientation={orientation}>
                {isStaticAsset(type) && (
                  <AssetImage
                    alt="landscape asset preview"
                    src={getAssetUrl(url)}
                  />
                )}
                {isVideoAsset(type) && (
                  <AssetVideo controls src={getAssetUrl(url)} />
                )}
              </AssetItem>
            ))}
          </AssetsGroup>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header>
          <h3>Creatives</h3>
        </Card.Header>
        <Card.Body className="p-0 overflow-auto">
          <CreativesTable
            creativeSet={creativeSetToUpdate}
            accountId={accountId}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default memo(EditCreativeSetForm);
