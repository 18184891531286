import { KeycloakInstance } from 'keycloak-js';
import { OS, Roles } from './types';
import { AccountDeveloper } from '../models/accounts';

const hasRoles = (keycloakInstance: KeycloakInstance, roles: Roles[]) => {
  return (
    roles?.some?.((accountRole) => {
      return keycloakInstance?.hasRealmRole?.(accountRole);
    }) ?? false
  );
};

const getDevelopersName = (developers?: AccountDeveloper[]) => {
  const android = developers?.find((dev) => dev.os === OS.Android);
  const ios = developers?.find((dev) => dev.os === OS.IOS);
  return {
    android: android?.name,
    ios: ios?.name,
  };
};

export default { hasRoles, getDevelopersName };
