import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import UsersTable from '../../components/Users/UsersTable';
import { useAccountById } from '../../hooks/api/accounts';
import UserCreateModal from '../../components/Users/UserCreateModal';
import Page from '../../layout/Page';
import UserNavigation from '../../layout/UserNavigation';

function UsersPage() {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { data: account } = useAccountById({ accountId });

  return (
    <Page
      title="Users Management"
      userNavigation={
        <UserNavigation
          arrowBack
          title={<h2>USERS MANAGEMENT - {account?.name}</h2>}
          actionButton={
            <Button
              variant="outline-secondary"
              className="me-5 d-flex align-items-center"
              onClick={() => NiceModal.show(UserCreateModal, { accountId })}
              title="add new user"
              name="add-new-user"
              aria-label="add-new-user"
            >
              <FontAwesomeIcon icon={solid('plus-circle')} className="pe-1" />
              Add a new user
            </Button>
          }
        />
      }
    >
      <div className="pt-2">
        <UsersTable accountId={accountId} />
      </div>
    </Page>
  );
}

export default UsersPage;
