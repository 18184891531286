import React, { useRef, useState, memo, useEffect } from 'react';
import { Row, Col, Form, Overlay } from 'react-bootstrap';
import { FixedSizeGrid as Grid } from 'react-window';
import type { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import CountryBtn from './button';
import CountryFlag from './flags';
import CountryCell from './cell';
import { CountryModel } from '../../../../models/countries';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import FlagIcon from '../../FlagIcon';
import { StateProps } from '../../../../utils/types';

interface CountryPopupProps extends StateProps<CountryModel[]> {
  availableCountries: CountryModel[];
}

const GRID_WIDTH = 3;

const searchCountry = (countries: CountryModel[], input: string) => {
  const searchString = input.toUpperCase();
  return countries.filter(
    ({ iso, iso2, name }) =>
      iso.toUpperCase().includes(searchString) ||
      iso2.toUpperCase().includes(searchString) ||
      name.toUpperCase().includes(searchString)
  );
};

export default memo(
  ({
    availableCountries,
    defaultValue: selectedCountries,
    setDefaultValue: setSelectedCountries,
  }: CountryPopupProps) => {
    const totalCountries = availableCountries.length;
    const [isModalDisplayed, setIsModalDisplayed] = useState(false);
    const [searchCountries, setSearchCountries] =
      useState<CountryModel[]>(availableCountries);

    const searchBar = useRef<HTMLInputElement>(null);
    const row = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);
    const refContainer = useRef<HTMLDivElement>(null);

    const onSearchCountry = () => {
      const input = searchBar.current!.value;
      const newList = searchCountry(availableCountries, input);
      setSearchCountries(newList);
    };

    const nbCols =
      searchCountries.length < GRID_WIDTH ? searchCountries.length : GRID_WIDTH;

    const nbRows = Math.ceil(searchCountries.length / GRID_WIDTH);

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;
      setSelectedCountries(isChecked ? searchCountries : []);
    };

    useOutsideClick(refContainer, () => setIsModalDisplayed(false));

    useEffect(() => {
      setSearchCountries(availableCountries);
    }, [availableCountries]);

    const [target, setTarget] = useState(null);

    const handleClick = (event) => {
      setTarget(event.target);
    };

    return (
      <div className="input-country">
        <div
          ref={refContainer}
          onClick={handleClick}
          onKeyDown={handleClick}
          tabIndex={0}
          role="button"
        >
          <CountryBtn
            countries={selectedCountries}
            setIsModalDisplayed={setIsModalDisplayed}
            isVisible
          />
        </div>
        <Overlay
          container={refContainer}
          target={target}
          show={isModalDisplayed}
          placement="bottom-start"
          flip
        >
          {(props: OverlayInjectedProps) => (
            <div
              ref={props.ref}
              style={{
                ...props.style,
                zIndex: 1,
              }}
            >
              <div className="select-form-popup" ref={popupRef}>
                {/* Country and searchbox */}
                <Row className="align-items-center mb-2 mt-2">
                  <Col
                    lg={5}
                    className="flagbtn"
                    onClick={() => setIsModalDisplayed((state) => !state)}
                    aria-hidden="true"
                    role="button"
                  >
                    <CountryFlag
                      isModalDisplayed={isModalDisplayed}
                      countries={selectedCountries}
                    />
                  </Col>
                  <Col lg={7} className="searchbar">
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      ref={searchBar}
                      onKeyUp={onSearchCountry}
                    />
                  </Col>
                </Row>

                {/* List of countries selected */}
                {!!selectedCountries.length && (
                  <ul className="countries-selected-labels ps-3">
                    {selectedCountries.map(({ iso2: isocode, name }) => {
                      return (
                        <li className="label-country" key={isocode}>
                          <FlagIcon
                            code={isocode.toLowerCase()}
                            className="bordered-flag me-1"
                          />
                          <span>{name}</span>
                          <span
                            aria-hidden="true"
                            role="button"
                            className="ms-1 delete-country"
                            onClick={() => {
                              const nextState = selectedCountries.filter(
                                ({ iso2 }) => iso2 !== isocode
                              );
                              setSelectedCountries([...nextState]);
                            }}
                          >
                            <FontAwesomeIcon icon={solid('times')} />
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                )}

                <hr />

                {/* List of countries available */}
                <div className="list-countries mb-1" ref={row}>
                  {/* Select all */}
                  <Col lg={4} className="mb-2 ps-2">
                    <Form.Check
                      type="checkbox"
                      label="Select all"
                      checked={selectedCountries.length === totalCountries}
                      onChange={handleSelectAll}
                    />
                  </Col>

                  <Grid
                    itemData={searchCountries}
                    columnWidth={390 / GRID_WIDTH}
                    rowHeight={40}
                    columnCount={nbCols}
                    rowCount={nbRows}
                    width={390}
                    height={160}
                  >
                    {({ rowIndex, data, columnIndex, style }) => {
                      const country = data[GRID_WIDTH * rowIndex + columnIndex];
                      if (!country) {
                        return <div />;
                      }

                      return (
                        <CountryCell
                          selectedCountries={selectedCountries}
                          setSelectedCountries={setSelectedCountries}
                          currentCountry={country}
                          cssWindow={style}
                        />
                      );
                    }}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </Overlay>
      </div>
    );
  }
);
