import { Orientation } from '../utils/types';

export enum APIAssetTypeEnum {
  video = 'video',
  static = 'static',
}

export enum APIAssetFormatEnum {
  fullScreen = 'interstitial',
  halfScreen = 'midsize',
  nativeBanner = 'native_banner',
}

export type APIAsset = {
  id: string;
  url: string;
  type: APIAssetTypeEnum;
  orientation: Orientation;
  created_at: string;
  updated_at: string;
};
