import { crossDKApiInstance } from '../index';
import type {
  APICreateCreativeSetInput,
  APICreativeSet,
} from '../../models/creativeSets';

export type APICreateCreativeSetPayload = {
  accountId: string;
  appId: string;
  input: APICreateCreativeSetInput;
};

type APICreateCreativeSetResponse = {
  creative_set: APICreativeSet;
};

export const createCreativeSet = async (
  payload: APICreateCreativeSetPayload
) => {
  const { accountId, appId, input } = payload;

  return crossDKApiInstance
    .post<APICreateCreativeSetResponse>(
      `/accounts/${accountId}/apps/${appId}/creatives_sets`,
      input
    )
    .then((response) => response.data.creative_set);
};

export default createCreativeSet;
