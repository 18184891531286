import React from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function Logo({
  url,
  title,
  height,
}: {
  url?: string | null;
  title?: string;
  height?: number;
}) {
  return url ? (
    <Image
      className="logo rounded"
      src={url}
      title={title}
      height={height || 32}
    />
  ) : (
    <FontAwesomeIcon icon={solid('image')} size="2x" title={title} />
  );
}

export default Logo;
