import React from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import useAllowFeature from '../hooks/permission/useIsAllowed';
import { FeatureName, Roles } from '../utils/types';
import accountUtils from '../utils/account';

function RedirectToMainPage() {
  const location = useLocation<{ [key: string]: unknown }>();
  const { keycloak } = useKeycloak();
  const { accountId } = useParams<{
    accountId: string;
  }>();

  // By default we want the user to be redirected on the crosspromotion
  let currentLocationState = location.state || {
    from: { pathname: `/account/${accountId}/crosspromotion` },
  };

  // However, we still have accounts on staging that don't have the crosspromotion plan
  const { isLoading, isAllowed } = useAllowFeature(
    accountId,
    FeatureName.CROSS_PROMOTION_ACTIVATION
  );

  // For accounts without the Cross_promotion plan, Adikteev_admin can still access the dashboard
  if (!isAllowed && accountUtils.hasRoles(keycloak, [Roles.ADIKTEEV_ADMIN])) {
    currentLocationState = location.state || {
      from: { pathname: `/account/${accountId}/dashboard` },
    };
  }

  return isLoading ? null : (
    <Redirect to={currentLocationState?.from as string} />
  );
}

export default RedirectToMainPage;
