import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext, useFormState } from 'react-hook-form';

import CustomForm from '../../../General/Forms';

import type { CreateCreativeSetFormValues } from './CreateForm';
import { SelectLanguage } from '../../../SelectLanguage/SelectLanguage';
import { SelectPromotedApp } from '../../../SelectPromotedApp/SelectPromotedApp';

function Step1() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { register, control } = useFormContext<CreateCreativeSetFormValues>();

  const { errors } = useFormState<CreateCreativeSetFormValues>();

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <h6>Step 1</h6>
          <h3>Define your new Creative Set</h3>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row>
          <Form.Group
            className="mb-4"
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={8}
            xl={5}
          >
            <Form.Label>App</Form.Label>
            <SelectPromotedApp
              name="app"
              control={control}
              rules={{
                required: 'Missing an app. This field is required.',
              }}
              accountId={accountId}
            />

            <div className="text-danger position-absolute mt-1">
              {errors.app?.message}
            </div>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group
            className="mb-4"
            as={Col}
            xs={12}
            sm={12}
            md={9}
            lg={8}
            xl={5}
          >
            <Form.Label>Creative Set Name</Form.Label>
            <Form.Text className="d-block mt-n2 mb-2 fs-5">
              The name of your set will be used as a prefix for all of the
              creatives inside this set
            </Form.Text>
            <Form.Control
              placeholder="Give a name to your Creative Set"
              {...register('name', {
                required: 'Missing Creative Set name. This field is required',
              })}
              isInvalid={!!errors.name}
            />
            <CustomForm.Error field="name" />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs={12} sm={12} md={9} lg={8} xl={5}>
            <Form.Label>Creative Set Language</Form.Label>
            <SelectLanguage
              name="language"
              control={control}
              rules={{
                required: 'Missing language name. This field is required.',
              }}
            />
            <p className="error m-0 mt-1">{errors?.language?.message}</p>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Step1;
