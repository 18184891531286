import { FeatureName } from '../../utils/types';
import { useAccountById } from '../api/accounts';

const useAllowFeature = (
  accountId: string,
  featureName: FeatureName
): { isLoading: boolean; isAllowed?: boolean } => {
  const { data, isLoading } = useAccountById({ accountId });
  if (isLoading) {
    return { isLoading, isAllowed: undefined };
  }

  const isAllowed = data?.plan?.features?.find(
    (feature) => feature.name === featureName
  );

  return { isLoading, isAllowed: isAllowed !== undefined };
};

export default useAllowFeature;
