import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Dropdown, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useGetAccounts } from '../../hooks/api/accounts';
import { AccountModelQuery } from '../../models/accounts';

import ActionsMenu from '../General/Menu/ActionsMenu';
import Badge from '../General/Badge/Badge';
import Logo from '../General/Logo';
import Table from '../General/Table';
import { FeatureName } from '../../utils/types';

const getLinkToProductPage = (account: AccountModelQuery) => {
  const isChurnApi = account.plan.features?.find(
    ({ name }) => name === FeatureName.CHURN_API
  );

  if (isChurnApi) {
    return `/account/${account.id}/churn-api`;
  }

  return `/account/${account.id}/crosspromotion`;
};

type AccountsTableProps = {
  onEdit?: (accountId: string) => void;
};

const columnHelper = createColumnHelper<AccountModelQuery>();

function AccountsTable({ onEdit }: AccountsTableProps) {
  const { data, isLoading, isError } = useGetAccounts();

  // Hide all accounts except those with churn api plan (other plans are deprecated)
  const filteredData =
    data && data.filter((account) => account.plan?.name === 'churn-api');

  const columns = useMemo(
    () => [
      columnHelper.accessor('logo_url', {
        header: 'Logo',
        cell: (info) => (
          <Link to={getLinkToProductPage(info.row.original)}>
            <Logo url={info.getValue()} title={info.row.original.name} />
          </Link>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('name', {
        header: 'Account Name',
        cell: (info) => (
          <Link
            className="text-underline-hover fw-bold"
            to={getLinkToProductPage(info.row.original)}
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor('email', {
        header: 'Contact Email',
      }),
      columnHelper.accessor('plan.name', {
        header: 'Plan',
        cell: (info) => (
          <Badge text="primary" className="badge-plan">
            {info.getValue()}
          </Badge>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <div className="text-end">
            <ActionsMenu title="actions">
              <Dropdown.Item
                onClick={() => onEdit && onEdit(info.row.original.id)}
                to="#/"
              >
                <FontAwesomeIcon
                  icon={solid('pencil-alt')}
                  size="sm"
                  className="me-1"
                />
                Edit account
              </Dropdown.Item>
              <Dropdown.Item
                to={`/admin/account/${info.row.original.id}/users`}
                as={Link}
              >
                <FontAwesomeIcon
                  icon={solid('users')}
                  size="sm"
                  className="me-1"
                />
                View users list
              </Dropdown.Item>
            </ActionsMenu>
          </div>
        ),
      }),
    ],
    [onEdit]
  );

  const initialState = useMemo(
    () => ({
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    }),
    []
  );

  const table = useReactTable({
    data: filteredData ?? [],
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isError) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <p className="text-danger">Error on loading accounts</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <Table
      table={table}
      emptyDataText="No result"
      rowTestPrefix="account"
      rowTestKey="id"
      className="table-admin"
    />
  );
}

export default AccountsTable;
