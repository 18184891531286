import type {
  APIEditTestingDeviceData,
  APITestingDevice,
} from '../../models/crosspromotion';
import type { APIMember } from '../../models/api';
import { crossDKApiInstance } from '../index';

export const editTestingDevice = (
  accountId: string,
  data: APIEditTestingDeviceData
): Promise<APIMember<APITestingDevice>> =>
  crossDKApiInstance
    .put(`/accounts/${accountId}/testing_devices/${data.id}`, data)
    .then((response) => response.data);

export default editTestingDevice;
