import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Form, Modal } from 'react-bootstrap';
import React, { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CustomForm from '../../../../General/Forms';
import Notif from '../../../../../utils/notification';
import type { APICreative } from '../../../../../models/creative';
import { APICreativeStatusEnum } from '../../../../../models/creative';

const notifyError = () => Notif(`Error`, 'Something went wrong', 'danger');

type EditNameFormValues = {
  name: string;
};

type EditCreativeNameModalProps = {
  updateCreative: (
    creative: APICreative,
    input: {
      name: string;
      status: APICreativeStatusEnum;
    }
  ) => Promise<APICreative>;
  creative: APICreative;
};

function EditCreativeNameModal(props: EditCreativeNameModalProps) {
  const { updateCreative, creative } = props;

  const { name, status } = creative;

  const modal = useModal();

  const methods = useForm({
    defaultValues: { name },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = methods;

  const updateName = useCallback(
    ({ name: newName }: EditNameFormValues) => {
      return updateCreative(creative, { name: newName, status })
        .then(() => modal.hide())
        .catch(notifyError);
    },
    [creative, modal, status, updateCreative]
  );

  return (
    <Modal backdrop="static" centered {...bootstrapDialog(modal)}>
      <Modal.Header closeButton>
        <h1>Edit creative name</h1>
      </Modal.Header>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(updateName)}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Creative name</Form.Label>
              <Form.Text className="d-block mb-2" />
              <Form.Control
                disabled={isSubmitting}
                placeholder="Give a name to your creative"
                isInvalid={!!errors.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                  setValueAs: (value: string) => value.trim(),
                })}
              />
              <CustomForm.Error field="name" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
}

export default memo(NiceModal.create(EditCreativeNameModal));
