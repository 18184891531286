import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CountryPopup from './CountryPopup';
import { CountryModel } from '../../../models/countries';

type FormSelectCountriesProps = {
  defaultValue: CountryModel[];
  countries: CountryModel[];
};

const REQUIRED = 'You need to select at least one country';

export default function FormSelectCountries({
  defaultValue,
  countries,
}: FormSelectCountriesProps) {
  const { control, clearErrors, setError, setValue } = useFormContext();

  return (
    <Controller
      rules={{
        validate: (values: string) => {
          return values.length !== 0 || REQUIRED;
        },
      }}
      name="countries"
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value } }) => {
        return (
          <CountryPopup
            availableCountries={countries}
            defaultValue={value}
            setDefaultValue={(values) => {
              clearErrors('countries');
              if (values.length === 0) {
                setError('countries', {
                  type: 'manual',
                  message: REQUIRED,
                });
              }
              setValue('countries', values);
            }}
          />
        );
      }}
    />
  );
}
