import { useMutation, useQueryClient } from 'react-query';

import {
  APIEditPromotedAppParams,
  editPromotedApp,
} from '../../../api/promoted-app/promotedApp.edit';

export const useEditPromotedApp = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: APIEditPromotedAppParams) => editPromotedApp(params),
    {
      onSuccess: (_response, params) => {
        return queryClient.invalidateQueries([
          params.accountId,
          'promotedApps',
        ]);
      },
    }
  );
};

export default useEditPromotedApp;
