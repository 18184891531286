import React, { Suspense } from 'react';

import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import SentryTracing, { isSentryEnabled } from '../sentry-tracing';

import PrivateRoute from './PrivateRoute';

import AuthPage from '../pages/authentication/AuthPage';
import Page404 from '../pages/404';
import PromotedAppsPage from '../pages/crosspromotion/PromotedAppsPage';
import { Roles } from '../utils/types';
import AccountsPage from '../pages/account/AccountsPage';
import UsersPage from '../pages/users/UsersPage';
import DeliverySettingsPage from '../pages/crosspromotion/DeliverySettingsPage';
import PromotedAppSettingsPage from '../pages/crosspromotion/PromotedAppSettingsPage';
import ScrollToTop from '../components/General/ScrollToTop';
import CreativesPage from '../pages/crosspromotion/CreativeSetsPage';
import CreateCreativeSetPage from '../pages/crosspromotion/CreateCreativeSetPage';
import EditCreativeSetPage from '../pages/crosspromotion/EditCreativeSetPage';
import TestingPage from '../pages/crosspromotion/TestingPage';
import Loader from '../components/General/Loader/Loader';
import { ChurnApiAppsPage } from '../pages/churn-api/ChurnApiAppsPage';

const PredikteevRoutes = React.lazy(() => import('../predikteev/pages/Root'));

function Routes() {
  const history = createBrowserHistory();

  if (isSentryEnabled) {
    SentryTracing(history);
  }

  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Redirect from="*" to="/auth/login" />}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/crosspromotion"
          component={PromotedAppsPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/promotedAppSettings/:appStoreId"
          component={PromotedAppSettingsPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/deliverySettings"
          component={DeliverySettingsPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/creatives"
          component={CreativesPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/creatives/add"
          component={CreateCreativeSetPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/creatives/edit/:creativeSetId"
          component={EditCreativeSetPage}
        />
        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN]}
          exact
          path="/admin/accounts"
          component={AccountsPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN]}
          exact
          path="/admin/account/:accountId/users"
          component={UsersPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/testing"
          component={TestingPage}
        />

        <PrivateRoute
          roles={[Roles.ADIKTEEV_ADMIN, Roles.ACCOUNT_USER]}
          exact
          path="/account/:accountId/churn-api"
          component={ChurnApiAppsPage}
        />

        <Route path="/auth" component={AuthPage} />

        <Suspense fallback={<Loader />}>
          <PredikteevRoutes />
        </Suspense>

        <Route
          path="/page-not-found"
          component={Page404}
          key="page-not-found"
          exact
        />

        <Route component={Page404} />
      </Switch>
    </Router>
  );
}

export default Routes;
