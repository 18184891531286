import { crossDKApiInstance } from '../index';
import { Orientation } from '../../utils/types';
import { APIAsset, APIAssetTypeEnum } from '../../models/asset';

export type APICreateAssetPayload = {
  accountId: string;
  appId: string;
  type: APIAssetTypeEnum;
  orientation: Orientation;
  input: {
    asset: File;
  };
};

type APICreateAssetResponse = {
  asset: APIAsset;
};

export const createAsset = async (payload: APICreateAssetPayload) => {
  const { appId, orientation, accountId, type, input } = payload;

  const formData = new FormData();
  formData.append('asset', input.asset);

  return crossDKApiInstance
    .post<APICreateAssetResponse>(
      `/accounts/${accountId}/apps/${appId}/assets/${type}/${orientation}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response) => response.data.asset);
};

export default createAsset;
