import type { AxiosError } from 'axios';

const getErrorName = (error: AxiosError) => {
  switch (error.name) {
    case 'click_template_tracking_link':
      return 'click';
    case 'impression_template_tracking_link':
      return 'impression';
    default:
      throw Error(`This error name is not handle ${error.name}`);
  }
};

export default getErrorName;
