import React, { useCallback } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Dropdown, Row, Spinner } from 'react-bootstrap';

import { DateTime } from 'luxon';
import NiceModal from '@ebay/nice-modal-react';
import type { APITestingDevice } from '../../../models/crosspromotion';
import { useGetAllTestingDevices } from '../../../hooks/api/crosspromotion';
import Table from '../../General/Table';
import PlatformBadge from '../../General/Badge/PlatformBadge';
import Badge from '../../General/Badge/Badge';
import CreateTestingDevice from './CreateTestingDeviceModal';
import ActionsMenu from '../../General/Menu/ActionsMenu';
import UpdateTestingDevice from './EditTestingDeviceModal';
import getItems from '../../../utils/getItems';
import DeleteTestingDeviceModal from './DeleteTestingDeviceModal';

const initialState = {
  sorting: [
    {
      id: 'updated_at',
      desc: true,
    },
  ],
};

const columnHelper = createColumnHelper<APITestingDevice>();

const columns = [
  columnHelper.accessor('name', {
    header: 'device name',
    cell: ({ getValue }) => <h5 className="text-start">{getValue()}</h5>,
  }),
  columnHelper.accessor('device_identifier', {
    header: 'device id',
    cell: ({ getValue }) => (
      <Badge>
        <span className="fw-light">{getValue()}</span>
      </Badge>
    ),
  }),
  columnHelper.accessor('os', {
    header: 'platform',
    cell: ({ getValue }) => (
      <div className="d-flex justify-content-center">
        <PlatformBadge platform={getValue()} />
      </div>
    ),
  }),
  columnHelper.accessor('updated_at', {
    header: 'last updated',
    cell: ({ getValue }) => (
      <h5>{DateTime.fromISO(getValue()).toFormat('yyyy MM dd')}</h5>
    ),
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => (
      <div className="text-end">
        <ActionsMenu title="actions">
          <Dropdown.Item
            onClick={() =>
              NiceModal.show(UpdateTestingDevice, {
                initialValues: info.row.original,
              })
            }
          >
            Edit device
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              NiceModal.show(DeleteTestingDeviceModal, {
                testingDeviceId: info.row.original.id,
                accountId: info.row.original.account_id,
              })
            }
          >
            Delete device
          </Dropdown.Item>
        </ActionsMenu>
      </div>
    ),
  }),
];

function TestingDevicesTable() {
  const { accountId } = useParams<{
    accountId: string;
  }>();

  const {
    data: testingDevices,
    isLoading,
    isError,
  } = useGetAllTestingDevices({ accountId }, { select: getItems });

  const table = useReactTable({
    data: testingDevices ?? [],
    columns,
    initialState,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const showCreateModal = useCallback(
    () =>
      NiceModal.show(CreateTestingDevice, {
        accountId,
      }),
    [accountId]
  );

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="d-flex justify-content-center pt-4">
        <p className="text-danger">Error on loading list of test devices</p>
      </div>
    );
  }

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h6>Manage your devices</h6>
            <h3>List of test devices</h3>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button onClick={showCreateModal}>Add a new device</Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Table
          table={table}
          emptyDataText="There are no devices yet"
          className="table-generic"
        />
      </Card.Body>
    </Card>
  );
}

export default TestingDevicesTable;
