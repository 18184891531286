const accountFormRules = {
  name: {
    required: {
      value: true,
      message: 'account name is required',
    },
  },
  email: {
    required: {
      value: true,
      message: 'contact email is required',
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'this email is not valid',
    },
  },
  plan: {
    required: {
      value: true,
      message: 'account plan is required',
    },
    valueAsNumber: true,
  },
  androidDeveloper: {
    required: {
      value: true,
      message: 'android developer name is required',
    },
  },
  iosDeveloper: {
    required: {
      value: true,
      message: 'Ios developer name is required',
    },
  },
};

export default accountFormRules;
