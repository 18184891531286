import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../layout/Page';
import RequireFeature from '../../components/Permission/RequireFeature';
import { FeatureName } from '../../utils/types';
import UserNavigation from '../../layout/UserNavigation';
import CreateCreativeSetForm from '../../components/CrossPromotion/CreativeSet/CreateForm/CreateForm';

function CreateCreativeSetPage() {
  const { accountId } = useParams<{ accountId: string }>();

  return (
    <Page
      title="Creatives Management - Add a new creative set"
      userNavigation={
        <UserNavigation
          title={<h2>CREATIVE MANAGEMENT - Add a new creative set</h2>}
          arrowBack
        />
      }
    >
      <CreateCreativeSetForm accountId={accountId} />
    </Page>
  );
}

export default RequireFeature(
  CreateCreativeSetPage,
  FeatureName.CROSS_PROMOTION_ACTIVATION
);
