import { useMutation, useQueryClient } from 'react-query';
import {
  APIEditCreativePayload,
  editCreative,
} from '../../../api/creative/creative.edit';

export const useEditCreative = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: APIEditCreativePayload) => editCreative(payload),
    {
      onSuccess: (response, payload) => {
        return queryClient.invalidateQueries([
          payload.accountId,
          'creatives-sets',
          payload.creativeSetId,
        ]);
      },
    }
  );
};

export default useEditCreative;
