import * as React from 'react';
import { ReactNotifications } from 'react-notifications-component';
import NiceModal from '@ebay/nice-modal-react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import type Keycloak from 'keycloak-js';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Router from '../router/Router';
import SidebarProvider from '../components/SidebarProvider/SidebarProvider';
import ErrorFallbackWrapper from '../components/ErrorFallbackWrapper';
import { queryClient } from './queryClient';

function Root({
  keycloakInstance,
}: {
  keycloakInstance: Keycloak.KeycloakInstance;
}) {
  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      LoadingComponent={<div />}
      initOptions={{
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      }}
    >
      <React.StrictMode>
        <SidebarProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
            <NiceModal.Provider>
              <ErrorFallbackWrapper>
                <ReactNotifications />
                <Router />
              </ErrorFallbackWrapper>
            </NiceModal.Provider>
          </QueryClientProvider>
        </SidebarProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default Root;
