import { crossDKApiInstance } from '../index';

export type APIEditApiAccessPayload = {
  input: {
    allow_api_access: boolean;
  };
  accountId: string;
  appId: string;
};

export type APIEditApiAccessResponse = { allow_api_access: boolean };

export const editApiAccess = async (payload: APIEditApiAccessPayload) => {
  const { accountId, appId, input } = payload;

  return crossDKApiInstance
    .put<APIEditApiAccessResponse>(
      `/accounts/${accountId}/apps/${appId}/api_access`,
      input
    )
    .then((response) => response.data);
};

export default editApiAccess;
