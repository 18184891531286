import React, { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext, useController } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// in Mo
const LOGO_SIZE_LIMIT: number = 1;

const FILE_SIZE_LIMIT: number = LOGO_SIZE_LIMIT * 1024 * 1024;

const FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

const KB = 1024;
const MB = 1024 * KB;

const getFileStringSize = (value: number): string => {
  // eslint-disable-next-line default-case
  switch (true) {
    case value >= KB && value < MB: {
      return `${(value / 1024).toFixed(0)} Kb`;
    }
    case value >= MB: {
      return `${(value / MB).toFixed(0)} Mb`;
    }
    case value < KB: {
      return `${value} bytes`;
    }
    // we dont nee to manage more than MB
    default:
      return '';
  }
};

const isValidFileSize = (file: File) => {
  return file && file.size < FILE_SIZE_LIMIT;
};

const isValidFileType = (file: File) => {
  return file && FILE_TYPES.includes(file.type);
};

const truncateText = (text: string, size: number) =>
  text?.length > size ? `${text.substring(0, size)}...` : text;

function InputLogo() {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: 'logo',
    control,
    rules: {
      validate: (v) =>
        v === null || (isValidFileSize(v) && isValidFileType(v)) || '',
    },
    defaultValue: null,
  });

  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleChange = async () => {
    const file: File | null = fileInput?.current?.files?.[0] ?? null;
    setValue('logo', file, { shouldValidate: true });
  };

  const maxFileSizeFormatted = useMemo(
    () => getFileStringSize(FILE_SIZE_LIMIT),
    []
  );

  const buttonLabel = useMemo<string>(() => {
    const defaultValue = 'Upload a file';
    if (inputProps.value) {
      return truncateText(inputProps.value?.name, defaultValue.length);
    }
    return defaultValue;
  }, [inputProps.value]);

  return (
    <div className="d-flex justify-content-center">
      <div className="d-flex flex-column">
        <input
          ref={fileInput}
          className="input-upload-file"
          type="file"
          onChange={handleChange}
          id="formAccountLogo"
          accept=".png, .jpg, .jpeg"
        />
        <Button
          aria-label="upload file"
          onClick={() => fileInput?.current?.click()}
        >
          <FontAwesomeIcon icon={solid('file-upload')} /> {buttonLabel}
        </Button>
        <div className="mt1 mb-n5">
          {!errors?.logo?.type && (
            <p className="fw-bold text-center fs-5">
              Supported file format: png, jpeg, jpg
              <br />
              Maximum upload file size: {maxFileSizeFormatted}
            </p>
          )}

          {errors?.logo?.type && (
            <p className="text-danger fw-bold text-center fs-5">
              <FontAwesomeIcon icon={solid('exclamation-triangle')} /> Supported
              file format: png, jpeg, jpg
              <br />
              Maximum upload file size: {maxFileSizeFormatted}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default InputLogo;
