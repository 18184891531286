import type {
  APISourceAppSelection,
  InputSourceAppsSelection,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

export const editPromotedBy = async (
  account_id: string,
  app_id: string,
  input: InputSourceAppsSelection
): Promise<APISourceAppSelection> => {
  const {
    data: { sourceApps },
  } = await crossDKApiInstance.put<{ sourceApps: APISourceAppSelection }>(
    `/accounts/${account_id}/apps/${app_id}/promoted_by`,
    input
  );
  return sourceApps;
};

export default editPromotedBy;
