// Monitoring Exceptions
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { API_URL, PROXY_SENTRY_URL, REPORTING_URL } from './api';

function sentryRates(): number {
  const rates = parseFloat(process.env.REACT_APP_RATES_SENTRY || '0.0');
  if (Number.isNaN(rates)) {
    return 1.0;
  }
  return rates;
}

// URL to send event to sentry
const SENTRY_DSN: string | null = process.env.REACT_APP_DSN_SENTRY || null;
const SENTRY_REDUX = process.env.REACT_APP_REDUX_SENTRY || 'false';

// Environement used to build the project, currently different from NODE_ENV
// NODE_ENV does not make a difference between production and demo.
const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || 'development';

const isDevMode = BUILD_ENV === 'development';

// Enable or not observing over redux
export const isSentryReduxEnabled = SENTRY_REDUX === 'true' || false;

export const isSentryEnabled =
  SENTRY_DSN && (SENTRY_DSN.length > 0 || sentryRates() === 0.0);

const tunnel = PROXY_SENTRY_URL ? { tunnel: PROXY_SENTRY_URL } : {};

export function SentryInitializer(history: any) {
  if (isSentryEnabled) {
    Sentry.init({
      dsn: SENTRY_DSN!,
      environment: BUILD_ENV,
      debug: isDevMode,
      maxBreadcrumbs: isDevMode ? 10 : 30,
      integrations: [
        new Integrations.BrowserTracing({
          // https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/automatic-instrumentation/#tracingorigins
          tracingOrigins: [API_URL, REPORTING_URL],
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: sentryRates(),
      beforeSend: (event) => {
        if (event.user) {
          //  remove ip addr
          const { email, username, id } = event.user;
          return { ...event, user: { email, username, id } };
        }

        return event;
      },

      beforeBreadcrumb: (breadcrumbs) => {
        // TODO: Disable warning StrictMode only and not warning text
        return breadcrumbs.category === 'console' &&
          breadcrumbs.message?.startsWith('Warning')
          ? null
          : breadcrumbs;
      },

      ...tunnel,
    });
  }
}

export default SentryInitializer;
