import axios, { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { InputUser, UserModelQuery } from '../../models/users';
import { APIResponseError } from '../../utils/types';

import handleAPIErrors from '../../utils/handleAPIErrors';
import Notif from '../../utils/notification';

import {
  CreateQuery,
  UpdateQuery,
  DeleteQuery,
  ReadAllQuery,
} from '../queryhooks';
import { getAllUsers } from '../../api/user/user.getAll';
import { createUser } from '../../api/user/user.create';
import { editUser } from '../../api/user/user.edit';
import { deleteUser } from '../../api/user/user.delete';
import { createUserPassword } from '../../api/user/userPassword.create';

export function useGetUsers(accountId: string): ReadAllQuery<UserModelQuery> {
  return useQuery<UserModelQuery[], Error>(['users', accountId], () =>
    getAllUsers(accountId)
  );
}

export function useResetPasswordUser(
  accountId: string,
  userId: string
): CreateQuery<UserModelQuery, void> {
  return useMutation(() => createUserPassword(accountId, userId), {
    onError: (error: Error) => {
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<APIResponseError>;
        const message = handleAPIErrors(response!.data);
        Notif("Reset User's Password", message, 'danger');
        return;
      }
      Notif("Reset User's Password", 'Something went wrong !', 'danger');
    },
    onSuccess: () => {
      Notif(
        "Reset User's Password",
        "An email has been sent to reset user's password",
        'success'
      );
    },
  });
}

export function useEditUser(
  accountId: string,
  userId: string
): UpdateQuery<UserModelQuery, InputUser> {
  const queryClient = useQueryClient();
  return useMutation(
    (params: InputUser) => editUser(accountId, userId, params),
    {
      onError: (error: Error) => {
        if (axios.isAxiosError(error)) {
          const { response } = error as AxiosError<APIResponseError>;
          const message = handleAPIErrors(response!.data);
          Notif('Update User', message, 'danger');
          return;
        }
        Notif('Update User', 'Something went wrong !', 'danger');
      },
      onSuccess: (data) => {
        Notif('Update User', `You have updated ${data.email} !`, 'success');
        return queryClient.invalidateQueries(['users', accountId]); // Force refetching ?
      },
    }
  );
}

export function useDeleteUser(
  accountId: string,
  userId: string
): DeleteQuery<string> {
  const queryClient = useQueryClient();

  return useMutation(() => deleteUser(accountId, userId), {
    onError: (error: Error) => {
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<APIResponseError>;
        const message = handleAPIErrors(response!.data);
        Notif('Deleted User', message, 'danger');
        return;
      }
      Notif('Deleted User', 'Something went wrong !', 'danger');
    },
    onSuccess: () => {
      Notif('Deleted User', 'You have deleted user !', 'success');
      return queryClient.invalidateQueries(['users', accountId]); // Force refetching ?
    },
  });
}

export function useCreateUser(
  accountId: string
): CreateQuery<UserModelQuery, InputUser> {
  const queryClient = useQueryClient();

  return useMutation((params: InputUser) => createUser(accountId, params), {
    onError: (error: Error) => {
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<APIResponseError>;
        const message = handleAPIErrors(response!.data);
        Notif('Create User', message, 'danger');
        return;
      }
      Notif('Create User', 'Something went wrong !', 'danger');
    },
    onSuccess: () => {
      Notif('Create User', 'You have added a new user !', 'success');
      return queryClient.invalidateQueries(['users', accountId]); // Force refetching ?
    },
  });
}

export default {
  useGetUsers,
  useEditUser,
  useDeleteUser,
  useCreateUser,
  useResetPasswordUser,
};
