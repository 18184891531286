import React, { ReactNode } from 'react';
import ArrowBack from '../components/General/ArrowBack/ArrowBack';
import { ToggleSidebar } from '../components/NavBar/Sidebar';
import UserProfileMenu from './UserProfileMenu';

function UserNavigation({
  title,
  arrowBack,
  actionButton,
  hideUsersManagement,
  variant,
  children,
}: {
  title?: ReactNode;
  arrowBack?: boolean;
  actionButton?: ReactNode;
  hideUsersManagement?: boolean;
  variant?: string;
  children?: ReactNode;
}) {
  return (
    <div className={`header-section pt-3 ${variant || ''}`}>
      <div className="navigation-bar d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {arrowBack && <ArrowBack variant="secondary" />}
          {title}
        </div>
        <div className="d-flex align-items-center">
          {actionButton!}
          <ToggleSidebar />
          <UserProfileMenu hideUsersManagement={hideUsersManagement} />
        </div>
      </div>
      {children}
    </div>
  );
}

export default UserNavigation;
