import { useCallback, useMemo, useState } from 'react';
import type { RowSelectionState } from '@tanstack/react-table';
import type { APICreativeLive } from '../../../../models/creativeSets';
import { APIAssetFormatEnum, APIAssetTypeEnum } from '../../../../models/asset';
import { Orientation } from '../../../../utils/types';

export type AllCreativesLive = APICreativeLive & {
  creative: string;
};

export const allCreativesLives: AllCreativesLive[] = [
  {
    creative: 'FullScreenVideo_Landscape',
    format: APIAssetFormatEnum.fullScreen,
    type: APIAssetTypeEnum.video,
    orientation: Orientation.landscape,
  },
  {
    creative: 'FullScreenVideo_Portrait',
    format: APIAssetFormatEnum.fullScreen,
    type: APIAssetTypeEnum.video,
    orientation: Orientation.portrait,
  },
  {
    creative: 'HalfScreenVideo_Landscape',
    format: APIAssetFormatEnum.halfScreen,
    type: APIAssetTypeEnum.video,
    orientation: Orientation.landscape,
  },
  {
    creative: 'HalfScreenVideo_Portrait',
    format: APIAssetFormatEnum.halfScreen,
    type: APIAssetTypeEnum.video,
    orientation: Orientation.portrait,
  },
];

export const useCreativesLivesSelection = () => {
  const [creativesLivesSelectionState, setCreativesLivesSelectionState] =
    useState<RowSelectionState>({});

  const updateCreativesLivesSelectionState = useCallback(
    (update: RowSelectionState) =>
      setCreativesLivesSelectionState({
        ...creativesLivesSelectionState,
        ...update,
      }),
    [creativesLivesSelectionState]
  );

  const creativesLivesSelected = useMemo(
    () =>
      allCreativesLives.filter(
        (_creativeLiveData, index) => creativesLivesSelectionState[index]
      ),
    [creativesLivesSelectionState]
  );

  return useMemo(
    () => ({
      creativesLivesSelectionState,
      setCreativesLivesSelectionState,
      updateCreativesLivesSelectionState,
      creativesLivesSelected,
    }),
    [
      creativesLivesSelected,
      creativesLivesSelectionState,
      updateCreativesLivesSelectionState,
    ]
  );
};

export default useCreativesLivesSelection;
