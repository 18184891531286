import { APIResponseError } from './types';

function handleAPIErrors(error: APIResponseError): string {
  if (error.message) {
    return error.message!;
  }

  if (error.errors) {
    return error.errors[0].message;
  }

  return 'Something went wrong, contact an Admin';
}

export default handleAPIErrors;
