import React from 'react';
import Keycloak from 'keycloak-js';

// Todo: it is necessary to align os and platform enum
export enum OS {
  Android = 'a',
  IOS = 'i',
}

export enum Platform {
  Android = 'android',
  IOS = 'ios',
}

export enum ARPUWindow {
  WEEK = 7,
  MONTH = 30,
  THREE_MONTH = 90,
}

export enum DaysInstall {
  THREE = 3,
  SEVEN = 7,
  FOURTEEN = 14,
  THIRTY = 30,
}

export enum NbIntervals {
  THREE = 3,
  FOUR = 4,
  TEN = 10,
  TWENTY = 20,
  HUNDRED = 100,
}

export enum GranularityPercentage {
  ONE = 1,
  FIVE = 5,
  TEN = 10,
  FIFTEEN = 15,
  TWENTY_FIVE = 25,
  THIRTY_THREE = 33,
}

export enum NbCohorts {
  THREE = 3,
  SEVEN = 7,
  FOURTEEN = 14,
  THIRTY = 30,
}

export enum Roles {
  ADIKTEEV_ADMIN = 'adikteev_admin',
  ACCOUNT_USER = 'account_user',
}

export enum ReportingTabs {
  Acquisition = 'acquisition',
  Retention = 'retention',
}

export enum FeatureName {
  USER_ACQUISITION = 'user-acquisition',
  USER_RETENTION = 'user-retention',
  CROSS_PROMOTION = 'cross-promotion',
  CROSS_PROMOTION_ACTIVATION = 'cross-promotion-activation',
  CHURN_API = 'churn-api',
}

// Models

export type ErrorApi = {
  message: string;
};

export type VerticalModel = {
  id: number;
  name: string;
};

export type TrackingPartnerModel = {
  id: number;
  name: string;
};

export interface UserSessionWithAttributes
  extends Keycloak.KeycloakTokenParsed {
  account_id: string;
  email: string;
  account_roles: Roles[];
}

export interface NormalizedObjects<T> {
  byId: { [id: string]: T };
  allIds: string[];
}

// Interface for useState react
export interface StateProps<T> {
  defaultValue: T;
  setDefaultValue: React.Dispatch<React.SetStateAction<T>>;
}

export type FieldsError = {
  name: string;
  code: string;
  message: string;
};

export type APIResponseError = {
  message?: string;
  code?: string;
  errors?: FieldsError[];
};

export type RequestErrorType = {
  error: {
    message: string;
  };
};

export enum Orientation {
  landscape = 'landscape',
  portrait = 'portrait',
}
