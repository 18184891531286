import { RefObject, useEffect } from 'react';

const useOutsideClick = (ref: RefObject<HTMLElement>, fn: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const bodyClass = document.body.getAttribute('class');
      if (ref.current && !ref.current.contains(event.target)) {
        // if we are in a modal context then we dont count
        if (!bodyClass || (bodyClass && !bodyClass.includes('modal-open'))) {
          fn();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, fn]);
};

export default useOutsideClick;
