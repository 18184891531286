import { Popover } from 'react-bootstrap';
import React from 'react';

const IDFVPopover = (
  <Popover>
    <Popover.Header as="h4">IDFV</Popover.Header>
    <Popover.Body>
      <p className="fw-light">
        IDFV is an alphanumeric string that uniquely identifies a device to the
        app’s vendor. If you have trouble finding a device IDFV, please contact
        your admin.
      </p>
      <p className="fs-5 mb-0">IDFV example:</p>
      <p className="fw-light fs-5">3D25A252-71EC-4D25-95EA-4AF85B1A4C37</p>
    </Popover.Body>
  </Popover>
);

export default IDFVPopover;
