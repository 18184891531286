import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import byId, * as fromById from './byId';
import allIds, * as fromAllIds from './allIds';

import type { NormalizedApplicationModel } from '../../../../../models/titles';

import type { NormalizedObjects } from '../../../../../utils/types';

export type NormalizedApplicationState =
  NormalizedObjects<NormalizedApplicationModel>;

const application = combineReducers({
  byId,
  allIds,
});

export default application;

export const getAllApplications = (state: NormalizedApplicationState) => {
  const ids = fromAllIds.getIds(state.allIds);
  return ids.map((id) => fromById.getApplication(id, state.byId));
};

export const getAllIds = (state: NormalizedApplicationState) => {
  return fromAllIds.getIds(state.allIds);
};

export const getApplicationById = (
  state: NormalizedApplicationState,
  id: string
) => fromById.getApplication(id, state.byId);

export const getApplicationsByIds = createSelector(
  (state: NormalizedApplicationState) => state,
  (_: NormalizedApplicationState, applicationIds: string[]) => applicationIds,
  (state, applicationIds) =>
    applicationIds.map((id) => fromById.getApplication(id, state.byId))
);
