import { churnApiInstance } from '../index';
import { Platform } from '../../utils/types';

export type GetChurnScoresReturn = { url: string };

export type GetChurnScorePayload = {
  params: {
    bundle: string;
    // format yyyy-mm-dd
    date?: string;
    os: Platform;
  };
};

export const getChurnScores = async ({ params }: GetChurnScorePayload) => {
  const { data } = await churnApiInstance.get<GetChurnScoresReturn>(
    `churn_scores/url`,
    {
      params,
    }
  );

  return data;
};

export default getChurnScores;
