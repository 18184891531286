import type { AccountFormModel } from '../../models/accounts';
import { predikteevApiInstance } from '../index';

export type CreateAccountParams = {
  data: AccountFormModel;
};
export const accountToFormData = (data: AccountFormModel) => {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('plan_id', data.planId.toString());
  if (data?.logo) {
    formData.append('logo', data.logo);
  }
  formData.append('developer_name_android', data.androidDeveloper);
  formData.append('developer_name_ios', data.iosDeveloper);
  return formData;
};
export const createAccount = ({ data }: CreateAccountParams) => {
  return new Promise((resolve, reject) => {
    const formData = accountToFormData(data);
    return predikteevApiInstance
      .post(`accounts/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (error.response) {
          reject(error.response.data);
        }
      });
  });
};

export default createAccount;
