import * as React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { withSentryRouting } from '@sentry/react';

import { Roles } from '../utils/types';
import useRestricted from '../hooks/permission/useRestricted';
import RedirectToMainPage from './RedirectToMainPage';

interface PrivateRouteParams extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  roles: Roles[];
  enableSentry?: boolean;
}

function PrivateRoute({
  component: Component,
  roles,
  enableSentry = true,
  ...rest
}: PrivateRouteParams) {
  const isAllowed = useRestricted(roles);
  const { keycloak } = useKeycloak();

  const ObservableRoute = enableSentry ? withSentryRouting(Route) : Route;

  return (
    <ObservableRoute
      {...rest}
      render={(props) => {
        switch (true) {
          case !keycloak?.authenticated: {
            return (
              <Redirect
                to={{
                  pathname: '/auth/login',
                  state: { from: props.location },
                }}
              />
            );
          }
          case isAllowed: {
            return <Component {...props} />;
          }
          case !isAllowed:
            return <RedirectToMainPage />;
          default:
            return null;
        }
      }}
    />
  );
}

export default PrivateRoute;
