import React, { useMemo, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { OverlayTriggerRenderProps } from 'react-bootstrap/OverlayTrigger';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getTitleIcon } from '../utils/getImageBy';
import useTitles from '../hooks/titles/useTitles';
import { sortDate } from '../../utils/sort';
import Contexts from '../contexts';

function TitlesNavigation() {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { data: titles } = useTitles({ accountId });

  const titlesSorted = useMemo(
    () => titles?.sort((a, b) => sortDate(a.created_at, b.created_at)),
    [titles]
  );

  const { selectedTab } = useContext(Contexts.ReportingTab);

  return (
    <ul className="list-unstyled" aria-label="titles navigation">
      {titlesSorted?.map((title) => {
        const iconUrl = getTitleIcon(title);
        const titleUrl = `/account/${accountId}/reporting/${title.id}/${selectedTab}`;

        return (
          <li className="title-link-wrapper" key={title.id}>
            <OverlayTrigger
              key={title.id}
              overlay={<Tooltip id="title-name">{title.name}</Tooltip>}
            >
              {(props: OverlayTriggerRenderProps) => (
                <NavLink
                  {...props}
                  exact
                  to={titleUrl}
                  title={title.name}
                  className="d-flex align-items-center"
                  activeClassName="fw-bold text-dark"
                >
                  <img src={iconUrl} alt={title.name} />
                  <span className="text-truncate ps-3">{title.name}</span>
                </NavLink>
              )}
            </OverlayTrigger>
          </li>
        );
      })}
    </ul>
  );
}

export default TitlesNavigation;
