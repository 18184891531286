import React, { InputHTMLAttributes, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'ref' | 'onChange'
>;

interface FormUploadProps {
  name: string;
  helper: React.ReactElement;
  inputProps?: InputProps;
}

export default function FormUpload({
  name,
  helper,
  inputProps,
}: FormUploadProps) {
  const { control, setValue } = useFormContext();

  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleChange = () => {
    const file: File | null = fileInput?.current?.files?.[0] ?? null;
    setValue(name, file);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={() => {
        return (
          <div className="d-flex justify-content-center">
            <div className="d-flex input-upload-container align-items-center">
              <input
                ref={fileInput}
                className="input-upload-file"
                type="file"
                onChange={handleChange}
                {...(inputProps ?? {})}
              />
              <Button
                aria-label="upload file"
                disabled={inputProps?.disabled}
                onClick={() => fileInput?.current?.click()}
              >
                <FontAwesomeIcon icon={solid('file-upload')} /> Upload a file
              </Button>
              <div className="mt1 mb-n5">{helper ?? null}</div>
            </div>
          </div>
        );
      }}
    />
  );
}
