// This call is a POST because it uses a payload containing all apps(bundle, os) but it works as a GET
import {
  DataStreamQuery,
  FormattedAPIDataStreamSourceApp,
  InputDataStream,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';
import { OS } from '../../utils/types';

export const getAllDataStreamStatus = async (
  input: InputDataStream
): Promise<FormattedAPIDataStreamSourceApp[]> => {
  const {
    data: { data_stream_activation_apps: apps },
  } = await crossDKApiInstance.post<DataStreamQuery>(
    `/data-stream/status`,
    input
  );
  return apps?.map((app) => ({
    ...app,
    os: app.os === 'android' ? OS.Android : OS.IOS,
  }));
};

export default getAllDataStreamStatus;
