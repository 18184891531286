import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Page from '../../layout/Page';
import { FeatureName } from '../../utils/types';
import RequireFeature from '../../components/Permission/RequireFeature';
import PromotedAppSettings from '../../components/CrossPromotion/PromotedAppSettings/PromotedAppSettings';
import { usePromotedApps } from '../../hooks/api/crosspromotion';
import UserNavigation from '../../layout/UserNavigation';

type URLParams = {
  accountId: string;
  appStoreId: string;
};

function PromotedAppSettingsPage() {
  const { accountId, appStoreId } = useParams<URLParams>();

  const { data } = usePromotedApps(accountId);

  const app = data?.find((pa) => pa.appStoreId === appStoreId);

  return (
    <Page
      title="Promoted App Settings"
      userNavigation={
        <UserNavigation arrowBack title={<h2>PROMOTED APP SETTINGS</h2>} />
      }
    >
      {app ? (
        <PromotedAppSettings app={app} />
      ) : (
        <div className="d-flex justify-content-center pt-4">
          <Spinner animation="border" role="status" />
        </div>
      )}
    </Page>
  );
}

export default RequireFeature(
  PromotedAppSettingsPage,
  FeatureName.CROSS_PROMOTION_ACTIVATION
);
