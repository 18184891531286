import { CellContext } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import React, { memo } from 'react';
import { getPlatform } from '../../utils/getPlatform';
import { useGetChurnScores } from '../../hooks/api/churn-score/churnScore.get';
import type { ChurnApiApp } from './ChurnApiAppsTable';

export const DownloadChurnScoreCell = memo(
  (props: CellContext<ChurnApiApp, unknown>) => {
    const {
      row: {
        original: { churnScoreInfos, os, bundleId },
      },
    } = props;

    const { data: churnScoresFile } = useGetChurnScores(
      {
        params: {
          os: getPlatform(os),
          bundle: bundleId,
        },
      },
      {
        enabled: Boolean(churnScoreInfos),
      }
    );

    if (!churnScoreInfos || !churnScoresFile) {
      return (
        <FontAwesomeIcon
          className="text-black-50"
          icon={solid('download')}
          size="1x"
        />
      );
    }

    return (
      <a href={churnScoresFile.url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={solid('download')} size="1x" />
      </a>
    );
  }
);

export default DownloadChurnScoreCell;
