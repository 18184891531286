import React, { forwardRef, useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';

interface Props {
  indeterminate?: boolean;
  id?: string;
  label?: string;
  checked: boolean;
  onChange(e: unknown): void;
  disabled?: boolean;
  checkboxTestId?: string;
}

// This is the checkbox Component used to select a row into a Table
const useCombinedRefs = (...refs): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(
  (
    {
      indeterminate,
      id,
      label,
      checked,
      onChange,
      disabled,
      checkboxTestId,
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const defaultRef = React.useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current && typeof indeterminate === 'boolean') {
        combinedRef.current.indeterminate = !checked && indeterminate;
      }
    }, [checked, combinedRef, indeterminate]);

    return (
      <FormCheck
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        type="checkbox"
        ref={combinedRef}
        label={label}
        data-testid={checkboxTestId}
      />
    );
  }
);
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export default IndeterminateCheckbox;
