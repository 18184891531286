import type { AppStoreQuery } from '../../models/crosspromotion';
import { OS } from '../../utils/types';
import type { APIAppStoreAndroid } from '../../models/appStores';
import { getAllAppsFromStore } from './appFromStore.getAll';

export const getAllAppsAndroid = (
  developerName: string
): Promise<AppStoreQuery[]> => {
  return getAllAppsFromStore(OS.Android, developerName).then((apps) =>
    apps.map((app: APIAppStoreAndroid) => {
      return {
        bundleId: app.app_store_id,
        appStoreId: app.app_store_id,
        name: app.title,
        logoUrl: app.icon,
        os: OS.Android,
      };
    })
  );
};

export default getAllAppsAndroid;
