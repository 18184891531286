import React from 'react';
import {
  Table as BootstrapTable,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { flexRender, Table as TableType } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props<T> {
  table: TableType<T>;
  emptyDataText?: string;
  disabledRowKey?: string;
  rowTestPrefix?: string;
  rowTestKey?: string;
  className?: string;
}

function Table<T>({
  table,
  emptyDataText,
  disabledRowKey,
  rowTestPrefix,
  rowTestKey,
  className,
}: Props<T>) {
  return (
    <BootstrapTable borderless className={className}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.column.columnDef.meta?.hasFixedWidth
                      ? header.getSize()
                      : 'auto',
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      className="d-flex align-items-center gap-2 th-inner-div"
                      onClick={header.column.getToggleSortingHandler()}
                      aria-hidden="true"
                      role={header.column.getCanSort() ? 'button' : undefined}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}{' '}
                      {header.column.getCanSort() && (
                        <FontAwesomeIcon icon={solid('sort')} />
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.length === 0 && emptyDataText ? (
          <tr>
            <td
              colSpan={table.getVisibleFlatColumns().length}
              className="text-center"
            >
              {emptyDataText}
            </td>
          </tr>
        ) : (
          table.getRowModel().rows.map((row) => {
            return disabledRowKey ? (
              <OverlayTrigger
                key={`${row.id}-tooltip-row`}
                overlay={
                  <Tooltip
                    className={row.original[disabledRowKey] ? 'd-none' : ''}
                    id={`${row.id}-tooltip-row`}
                  >
                    You do not share this app data with us. Please activate the
                    data stream in your tracking provider dashboard
                  </Tooltip>
                }
              >
                <tr
                  className={
                    disabledRowKey && !row.original[disabledRowKey]
                      ? 'disabled-element'
                      : ''
                  }
                  data-testid={
                    rowTestPrefix &&
                    rowTestKey &&
                    `${rowTestPrefix}-${row.original[rowTestKey]}`
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          width: cell.column.columnDef.meta?.hasFixedWidth
                            ? cell.column.getSize()
                            : 'auto',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              </OverlayTrigger>
            ) : (
              <tr
                key={row.id}
                data-testid={
                  rowTestPrefix &&
                  rowTestKey &&
                  `${rowTestPrefix}-${row.original[rowTestKey]}`
                }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        width: cell.column.columnDef.meta?.hasFixedWidth
                          ? cell.column.getSize()
                          : 'auto',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </BootstrapTable>
  );
}

export default Table;
