import type { AxiosResponse } from 'axios';
import type { UserModelQuery } from '../../models/users';
import { predikteevApiInstance } from '../index';

export const createUserPassword = async (
  accountId: string,
  userId: string
): Promise<any> => {
  const response: AxiosResponse<UserModelQuery> =
    await predikteevApiInstance.post(
      `/accounts/${accountId}/users/${userId}/reset_password`
    );
  return response.data;
};

export default createUserPassword;
