import React, { memo, ReactNode } from 'react';
import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Button, Modal } from 'react-bootstrap';
import { ModalProps } from 'react-bootstrap/Modal';
import { useIsMutating } from 'react-query';

type ConfirmationModalProps = {
  onConfirm: <T = void>() => Promise<T>;
  header?: ReactNode;
  body?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  modalProps?: Omit<ModalProps, 'onHide' | 'onShow' | 'show'>;
};
export const ConfirmationModal = NiceModal.create<ConfirmationModalProps>(
  memo((props) => {
    const {
      header,
      body,
      modalProps,
      cancelText = 'Cancel',
      confirmText = 'Yes',
      onConfirm,
    } = props;

    const isMutating = useIsMutating() > 0;

    const modal = useModal();

    return (
      <Modal centered {...modalProps} {...bootstrapDialog(modal)}>
        {header && <Modal.Header closeButton>{header}</Modal.Header>}
        {body && <Modal.Body className="text-center">{body}</Modal.Body>}
        <Modal.Footer>
          <Button
            disabled={isMutating}
            className="btn-secondary"
            onClick={modal.hide}
          >
            {cancelText}
          </Button>
          <Button disabled={isMutating} onClick={onConfirm}>
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  })
);

export default ConfirmationModal;
