import type { TestingDeviceFormValues } from './TestingDeviceForm';
import { Platform } from '../../../../utils/types';

const defaultValues: TestingDeviceFormValues = {
  os: Platform.IOS,
  device_identifier: '',
  name: '',
};

export default defaultValues;
