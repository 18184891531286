import React from 'react';

interface Props {
  error: string;
}

function DropZone({ error }: Props) {
  return (
    <div className="error">
      <div>Your asset can’t be uploaded.</div>
      {error}
    </div>
  );
}

export default DropZone;
