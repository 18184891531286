import { createActionCreator } from 'deox';

import {
  JSONTitleInput,
  NormalizedTitlesModel,
  NormalizedTitleModel,
  NormalizedSingleTitleModel,
} from '../../../models/titles';

import {
  LOAD_LIST,
  LOAD_LIST_SUCCESS,
  LOAD_LIST_ERROR,
  ADD_REQUESTED,
  ADD_SUCCESS,
  ADD_ERROR,
  DELETE_REQUESTED,
  DELETE_SUCCESS,
  DELETE_ERROR,
  ADD_APPLICATION,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_ERROR,
} from '../../constants/titles';
import { OS } from '../../../utils/types';

export const loadTitlesByAccountId = createActionCreator(
  LOAD_LIST,
  (resolve) => (accountID: string) => resolve({ accountID })
);

export const loadTitlesSuccess = createActionCreator(
  LOAD_LIST_SUCCESS,
  (resolve) => (titles: NormalizedTitlesModel) => resolve(titles)
);

export const loadTitlesError = createActionCreator(
  LOAD_LIST_ERROR,
  (resolve) => (error: string) => resolve(error)
);

export const addTitleRequested = createActionCreator(
  ADD_REQUESTED,
  (resolve) =>
    ({
      titleInput,
      accountId,
    }: {
      titleInput: JSONTitleInput;
      accountId: string;
    }) =>
      resolve({ titleInput, accountId })
);

export const addTitleSuccess = createActionCreator(
  ADD_SUCCESS,
  (resolve) => (title: NormalizedTitlesModel) => resolve(title)
);

export const addTitleError = createActionCreator(
  ADD_ERROR,
  (resolve) => (error: string) => resolve(error)
);

export const deleteTitleRequested = createActionCreator(
  DELETE_REQUESTED,
  (resolve) => (title: NormalizedTitleModel) => resolve(title)
);

export const deleteTitleSuccess = createActionCreator(
  DELETE_SUCCESS,
  (resolve) =>
    ({
      titleId,
      applicationsIds,
    }: {
      titleId: string;
      applicationsIds: string[];
    }) =>
      resolve({ titleId, applicationsIds })
);

export const deleteTitleError = createActionCreator(
  DELETE_ERROR,
  (resolve) => (error: string) => resolve(error)
);

export const addApplicationOnTitle = createActionCreator(
  ADD_APPLICATION,
  (resolve) =>
    ({
      titleId,
      accountId,
      bundleId,
      os,
      icon,
    }: {
      titleId: string;
      accountId: string;
      bundleId: string;
      os: OS;
      icon: string;
    }) =>
      resolve({ titleId, accountId, bundleId, os, icon })
);

export const addApplicationOnTitleSuccess = createActionCreator(
  ADD_APPLICATION_SUCCESS,
  (resolve) => (payload: NormalizedSingleTitleModel) => resolve(payload)
);

export const addApplicationOnTitleError = createActionCreator(
  ADD_APPLICATION_ERROR,
  (resolve) => (error: string) => resolve(error)
);
