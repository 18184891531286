import type { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import type { StylesConfig } from 'react-select';
import type { FieldValues, PathValue } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types';

export const getSelectDefaultStyles = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  fieldState: ControllerFieldState
): StylesConfig<PathValue<TFieldValues, TName>, false, never> => ({
  control: (styles, { isFocused }) => {
    let borderColor = isFocused ? '#8e9faf' : '#E6ECEF';
    if (fieldState.error !== undefined) {
      borderColor = 'red';
    }

    return {
      ...styles,
      borderColor,
      boxShadow: '0',
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      color: '#333a3d',
    };
  },
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#333a3d',
    '&:hover': {
      color: '#333a3d',
    },
    svg: {
      height: '18px',
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused || isSelected ? '#f6f9fc' : undefined,
      '&:active': {
        backgroundColor: '#f6f9fc',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: '0.25rem',
    };
  },
});

export default getSelectDefaultStyles;
