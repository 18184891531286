import { crossDKApiInstance } from '../index';
import { APICreative, APICreativeStatusEnum } from '../../models/creative';

export type APIEditCreativePayload = {
  input: {
    name: string;
    status: APICreativeStatusEnum;
  };
  accountId: string;
  appId: string;
  creativeSetId: string;
  creativeId: string;
};

export type APIEditCreativeResponse = { creative: APICreative };

export const editCreative = async (payload: APIEditCreativePayload) => {
  const { accountId, appId, creativeSetId, creativeId, input } = payload;

  return crossDKApiInstance
    .put<APIEditCreativeResponse>(
      `/accounts/${accountId}/apps/${appId}/creatives_sets/${creativeSetId}/creatives/${creativeId}`,
      input
    )
    .then((response) => response.data.creative);
};

export default editCreative;
