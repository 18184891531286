import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Preview from './Preview';
import Error from './Error';

interface Props {
  validate?: (files: File[]) => Promise<string | undefined>;
  fileType: 'video' | 'image';
  children: ReactNode;
  isDisabled?: boolean;
  accept?: Accept;
  onChange?: (file: File[]) => void;
}

function DropZone({
  fileType,
  validate,
  isDisabled,
  accept,
  children,
  onChange,
}: Props) {
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>();

  const deleteFiles = useCallback(() => {
    setFiles([]);
    return onChange && onChange([]);
  }, [onChange]);

  const onDrop = useCallback(
    async (newFiles: File[]) => {
      setError(undefined);
      let errorFromValidation: string | undefined;

      if (validate) {
        errorFromValidation = await validate(newFiles);
      }

      if (errorFromValidation) {
        return setError(errorFromValidation);
      }

      setFiles(newFiles);
      return onChange && onChange(newFiles);
    },
    [onChange, validate]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  const preview = useMemo(
    () => (files[0] ? URL.createObjectURL(files[0]) : undefined),
    [files]
  );

  if (preview) {
    return (
      <div className="dropzone-container">
        <div className="dropzone">
          <div className="thumbsContainer">
            <div className="thumb">
              <div className="thumbInner">
                <Preview fileType={fileType} preview={preview} />
              </div>
            </div>
            {!isDisabled && (
              <FontAwesomeIcon
                icon={solid('circle-xmark')}
                size="2x"
                className="delete"
                onClick={deleteFiles}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dropzone-container">
      <div
        {...getRootProps({
          className: 'dropzone',
        })}
      >
        <input data-testid="dropzone-input" {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <div>
            <FontAwesomeIcon icon={solid('cloud-arrow-up')} size="lg" />
            {children}
          </div>
        )}
      </div>
      {error && <Error error={error} />}
    </div>
  );
}

export default memo(DropZone);
