import React from 'react';
import { useParams } from 'react-router-dom';
import RedirectToMainPage from '../../router/RedirectToMainPage';
import { FeatureName } from '../../utils/types';
import useAllowFeature from '../../hooks/permission/useIsAllowed';

export default function RequireFeature(
  Component: React.ComponentType<any>,
  featureName: FeatureName
) {
  return function WrappedComponent(props: any) {
    const { accountId } = useParams<{ accountId: string }>();

    const { isLoading, isAllowed } = useAllowFeature(accountId, featureName);
    // waiting for plans on first loads
    if (isLoading) {
      return <div aria-label="require feature loading" />;
    }
    if (!isLoading && isAllowed) {
      return <Component {...props} />;
    }
    return <RedirectToMainPage />;
  };
}
