import React, { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react';

function AssetImage(
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > & { alt: string }
) {
  // eslint-disable-next-line react/prop-types
  const { className, ...otherProps } = props;
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...otherProps} className={`${className} tag`} />;
}

export default memo(AssetImage);
