import type {
  APISourceAppResponse,
  InputSourceAppSettings,
} from '../../models/crosspromotion';
import { crossDKApiInstance } from '../index';

export const editXpromoSettings = async (
  account_id: string,
  app_id: string,
  input: InputSourceAppSettings
): Promise<APISourceAppResponse> => {
  const { data } = await crossDKApiInstance.put<APISourceAppResponse>(
    `/accounts/${account_id}/apps/${app_id}/xpromo_settings`,
    input
  );
  return data;
};

export default editXpromoSettings;
