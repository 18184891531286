import { Row, RowSelectionState } from '@tanstack/react-table';

interface Params<T> {
  rows: Row<T>[];
  originalKey: string;
  rowSelection: RowSelectionState;
}

// remove comment below when we have more than 1 function in this file
// eslint-disable-next-line import/prefer-default-export
export const getHasSelectedRowsChanged = <T>({
  rows,
  originalKey,
  rowSelection,
}: Params<T>): boolean => {
  return rows.some((row) => {
    return (
      (!row.original[originalKey] && rowSelection[row.id]) ||
      (row.original[originalKey] && !rowSelection[row.id])
    );
  });
};
