import { createReducer } from 'deox';
import omit from 'lodash/omit';
import {
  addApplicationOnTitleSuccess,
  addTitleSuccess,
  deleteTitleSuccess,
  loadTitlesSuccess,
} from '../../../actions/titles';

import { NormalizedApplicationModel } from '../../../../../models/titles';

type ByIdState = {
  [id: string]: NormalizedApplicationModel;
};
const initialState: ByIdState = {};

const byId = createReducer(initialState, (handleAction) => [
  handleAction(loadTitlesSuccess, (_, { payload }) => ({
    ...payload.entities.applications,
  })),

  handleAction(addTitleSuccess, (state, { payload }) => ({
    ...state,
    ...payload.entities.applications,
  })),

  handleAction(addApplicationOnTitleSuccess, (state, { payload }) => ({
    ...state,
    ...payload.entities.applications,
  })),

  handleAction(deleteTitleSuccess, (state, { payload }) =>
    omit(state, payload.applicationsIds)
  ),
]);

export const getApplication = (id: string, state: ByIdState) => state[id];

export default byId;
