import { useKeycloak } from '@react-keycloak/web';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

type Props = PropsWithChildren<{
  redirectTo: string;
}>;

function Logout({ redirectTo, children }: Props) {
  const [loading, setLoading] = useState(true);

  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.logout();
      return;
    }

    setLoading(false);
  }, [keycloak]);

  if (loading) {
    // display default loading or the one provided as children
    if (children) {
      return <>{children}</>;
    }

    return <div> Disconnecting...</div>;
  }

  return <Redirect to={redirectTo} />;
}

export default Logout;
