import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useFormState } from 'react-hook-form';
import useRestricted from '../../hooks/permission/useRestricted';
import { Roles } from '../../utils/types';

import CustomForm from '../General/Forms';

export const userFormRules = {
  firstname: {},
  lastname: {},
  email: {
    required: {
      value: true,
      message: 'email is required',
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'this email is not valid',
    },
  },
  role: {
    required: {
      value: true,
      message: 'role is required',
    },
  },
};

export function UserFirstName() {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formFirstName">
      <Form.Label className="mb-2 fw-bold">First name</Form.Label>
      <Form.Control
        {...register('firstName', { ...userFormRules.firstname })}
        placeholder="First name"
        isInvalid={!!errors.firstName}
      />
      <CustomForm.Error field="firstname" />
    </Form.Group>
  );
}

export function UserLastName() {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formLastName">
      <Form.Label className="mb-2 fw-bold">Last name</Form.Label>
      <Form.Control
        {...register('lastName', { ...userFormRules.lastname })}
        placeholder="Last name"
        isInvalid={!!errors.lastName}
      />
      <CustomForm.Error field="lastname" />
    </Form.Group>
  );
}

export function UserEmail({ disabled }: { disabled?: boolean }) {
  const { register } = useFormContext();
  const { errors } = useFormState();

  return (
    <Form.Group controlId="formEmail">
      <Form.Label className="mb-2 fw-bold is-required">Email</Form.Label>
      <Form.Control
        disabled={disabled}
        {...register('email', { ...userFormRules.email })}
        placeholder="email"
        isInvalid={!!errors.email}
      />
      <CustomForm.Error field="email" />
    </Form.Group>
  );
}

export function UserRole({ disabled }: { disabled?: boolean }) {
  const { register } = useFormContext();
  const { errors } = useFormState();

  const isAdikteevAdmin = useRestricted([Roles.ADIKTEEV_ADMIN]);

  const basicRoleList = [Roles.ACCOUNT_USER];
  const roleListAllowed = isAdikteevAdmin
    ? basicRoleList.concat([Roles.ADIKTEEV_ADMIN])
    : basicRoleList;

  const formatRoleName = (name: string) =>
    name
      .split('_')
      .map((nm) => nm.slice(0, 1).toUpperCase().concat(nm.slice(1)))
      .reduce((prev, cur) => `${prev} ${cur}`);

  return (
    <Form.Group controlId="formRole">
      <Form.Label className="mb-2 fw-bold is-required">Role</Form.Label>
      <Form.Select
        {...register('role', { ...userFormRules.role })}
        disabled={disabled}
        isInvalid={!!errors.role}
      >
        {roleListAllowed.map((name) => (
          <option key={`role-${name}`} value={name}>
            {formatRoleName(name)}
          </option>
        ))}
      </Form.Select>
      <CustomForm.Error field="role" />
    </Form.Group>
  );
}

export default {
  UserFirstName,
  UserLastName,
  UserEmail,
  UserRole,
};
